import React, { useEffect, useState } from 'react'
import "./refferal.scss"
import HeaderNew from '../LandingNew/HeaderNew/HeaderNew'
import { toast } from 'react-toastify'
import Loader from '../../hooks/loader'
import { API_URL } from '../../ApiUrl'
import axios from 'axios'
import Navbar from '../landing/header/Navbar'


const Settings = () => {
    const [nickName, setNickName] = useState()
    const [loader, setLoader] = useState(false);
    const user = localStorage.getItem('userName') || localStorage.getItem('user')
    const [error, setError] = useState(false)
    const userObj = JSON.parse(user)
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const checkAvail = async (e) => {
        setNickName(e.target.value)
        var data = {
            nickName: e.target.value
        }
        var config = {
            method: "get",
            url: `${API_URL}/customers/nickname/is-available?nickName=${e.target.value}`,
            data: data,
        };
        axios(config)
            .then(function (response) {
                setError(false)
                setNickName(e.target.value)
            }).catch(function (error) {
                toast.error()
                if (error?.response?.data?.statusCode === 409) {
                    //   setNickName('')
                    setError(true)
                }
                // console.log('sdfasdf',error?.response?.data?.statusCode)/ 


            });
    }
    let nameUpdate = async () => {
        if (nickName) {
            setLoader(true)
            let tok = localStorage.getItem("accessToken");
            let data = {
                nickName: nickName
            }
            var config = {
                method: "patch",
                url: `${API_URL}/customers/profile`,
                data: data,
                headers: {
                    authorization: `Bearer ` + tok
                }
            };
            axios(config)
                .then(function (response) {
                    setLoader(false)
                    toast.success(response?.data?.message, toastDesign);
                    localStorage.setItem('userName', JSON.stringify(response?.data?.data))
                }).catch(function (error) {
                    setLoader(false)
                    //   if (error.code == 401) {
                    //     refreshToken()
                    //   }
                    toast.error(error?.response?.data?.message, toastDesign)
                });
        } else {
            toast.info('Name Input is Empty', toastDesign)
        }
    }
    useEffect(() => {
        setNickName(userObj?.nickName)
    }, [userObj?.nickName])

    return (
        <>
            {loader && <Loader />}
            <Navbar tNav={'tNav'} />
            <img src="\assets\pinkbg.png" alt="img" className='img-fluid banner-bg55' />
            <section className="refferal-section setting-secti">
                {/* <img src="\assets\bg-refferal.png" alt="img" className='img-fluid bg-refferal' /> */}
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h2>Settings</h2>
                        <p>Edit your nickname</p>
                    </div>
                    <div className="inner-box1">
                        <div className="left">
                            <div className="bottom-box">
                                <h6>Your display name</h6>
                                <div className='button__input_main'>
                                <div className="option-field setting_option">

<svg className='imgpen' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14 14.6666H2C1.72667 14.6666 1.5 14.44 1.5 14.1666C1.5 13.8933 1.72667 13.6666 2 13.6666H14C14.2733 13.6666 14.5 13.8933 14.5 14.1666C14.5 14.44 14.2733 14.6666 14 14.6666Z" fill="#0E1120" />
    <path d="M12.6804 2.31994C11.387 1.02661 10.1204 0.993275 8.79371 2.31994L7.98704 3.12661C7.92037 3.19328 7.89371 3.29994 7.92038 3.39328C8.42704 5.15994 9.84038 6.57328 11.607 7.07994C11.6337 7.08661 11.6604 7.09328 11.687 7.09328C11.7604 7.09328 11.827 7.06661 11.8804 7.01328L12.6804 6.20661C13.3404 5.55328 13.6604 4.91994 13.6604 4.27994C13.667 3.61994 13.347 2.97994 12.6804 2.31994Z" fill="#0E1120" />
    <path d="M10.4056 7.68666C10.2122 7.59332 10.0256 7.49999 9.84558 7.39333C9.69891 7.30666 9.55891 7.21333 9.41891 7.11333C9.30558 7.03999 9.17224 6.93333 9.04558 6.82666C9.03224 6.81999 8.98558 6.77999 8.93224 6.72666C8.71224 6.53999 8.46558 6.29999 8.24558 6.03333C8.22558 6.01999 8.19224 5.97333 8.14558 5.91333C8.07891 5.83333 7.96558 5.69999 7.86558 5.54666C7.78558 5.44666 7.69224 5.29999 7.60558 5.15333C7.49891 4.97333 7.40558 4.79333 7.31224 4.60666C7.18987 4.34443 6.8457 4.26653 6.64108 4.47115L2.89224 8.21999C2.80558 8.30666 2.72558 8.47333 2.70558 8.58666L2.34558 11.14C2.27891 11.5933 2.40558 12.02 2.68558 12.3067C2.92558 12.54 3.25891 12.6667 3.61891 12.6667C3.69891 12.6667 3.77891 12.66 3.85891 12.6467L6.41891 12.2867C6.53891 12.2667 6.70558 12.1867 6.78558 12.1L10.5406 8.34497C10.7411 8.14444 10.6658 7.79942 10.4056 7.68666Z" fill="#0E1120" />
</svg>
<input className={error && "inpuDisable"} value={nickName} onChange={checkAvail} type="text" placeholder='JohnDoe77' />
{error && <p className="errorTxt">This nickname is already taken. Try a different one</p>}
</div>
<button disabled={error} onClick={nameUpdate} className={error ? "btn-save btnDisable" : "btn-save"}>Save Changes</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Settings
