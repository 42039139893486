import React, { useState, useEffect } from "react";
import "./market.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../../hooks/loader";
import CheckExpire from "../../hooks/dataFetchers/checkExpiry";
import CheckHigh from "../../hooks/dataFetchers/getHighestBid";
import ClaimNft from "../../hooks/dataFetchers/checkClaim";
import Countdown from "react-countdown";
import Navbar from "../landing/header/Navbar";
import Environment from "../../utils/Environment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useWeb3React } from "@web3-react/core";
import { useRef } from "react";
import MarketSold from "./MarketSold";
import MarketUnclaimed from "./MarketUnclaimed";
const Market = ({ setAuctionLength, disLanding }) => {
  const { account } = useWeb3React();
  const [allNFTS, setAllNFTS] = useState([]);
  const [allNFTSSold, setAllNFTSSold] = useState([]);
  const [allNFTSUnclaim, setAllNFTSUnclaim] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [metaDataSearch, setMetaDataSearch] = useState([]);
  const [realPrice, setRealPrice] = useState(0);
  const [heightState, setHeightState] = useState(0);
  const [error, setError] = useState();
  const inputRef = useRef(null);
  const [start, setStart] = useState(0);
  const [tabM, setTabM] = useState("all");
  const [search, setSearch] = useState(null);
  const [search2, setSearch2] = useState(null);
  const [filter, setFilter] = useState(false);
  const [sortByy, setSortByy] = useState("all");
  const { CheckExpiry } = CheckExpire();
  const { HighestBid } = CheckHigh();
  const { CheckClaimNft } = ClaimNft();

  const getMinted = async () => {
    setLoader3(true);
    setAllNFTS([]);
    try {
      setLoader(true);
      const data = JSON.stringify({
        query: `query MyQuery {
          auctionCreateds(orderBy: startTime, orderDirection: desc, first: 1000) {
            expiryTime
            id
            isClaimed
            label
            labelhash
            mintAmount
            minter
            startTime
            tld
            tokenId
          }
        }`,
        variables: {},
      });

      const config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      const minted = response?.data?.data?.auctionCreateds;
      // console.log('first', minted)
      if (minted) {
        setAllNFTS(minted);
        setLoader3(false);
      }
    } catch (error) {
      setLoader3(false);
      // Handle error here
    } finally {
      setLoader3(false);
    }
  };

  const getSearchData = async () => {
    if (search2) {
      try {
        setLoader3(true);
        const data = JSON.stringify({
          query: `query MyQuery {
            auctionCreateds(orderBy: startTime, orderDirection: desc, where: {label_contains: "${search2}"}) {
              expiryTime
              id
              isClaimed
              label
              labelhash
              mintAmount
              minter
              startTime
              tld
              tokenId
            }
          }`,
          variables: {},
        });

        const config = {
          method: "post",
          url: Environment.marketplaceGraph,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);
        const minted = response?.data?.data?.auctionCreateds || [];
        // console.log('dlsafjlasldf', minted);

        if (minted.length > 0) {
          const metaDataPromises = minted.map(async (item) => {
            const res1 = await CheckExpiry(item.tokenId);
            const dat = new Date(0);
            dat.setUTCSeconds(parseFloat(res1?.expiryTime));
            const data = {
              tokenId: item.tokenId,
              HighestBid: "",
              Auction: "",
              Timeline: "",
              name: item.label,
              mintTime: item.mintTime,
              initialExpiryTime: dat,
              tld: item.tld,
            };

            const res0 = await HighestBid(item.tokenId);
            data.HighestBid = await res0?.amount;

            const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
            res1.expiryTime = dateVal;
            const date0 = new Date();
            data.Auction = dat > date0;

            data.Timeline = await res1;
            return data;
          });

          const metaDataSearch = await Promise.all(metaDataPromises);
          setMetaDataSearch(metaDataSearch);
        } else {
          setError(true);
          setMetaDataSearch([]);
        }
      } catch (error) {
        // Handle error here
      } finally {
        setLoader3(false);
      }
    } else {
      if (search2 === "") {
        setMetaData([]);
        setAllNFTS([]);
        setitemlength(heightState);
        setTimeout(() => {
          getMinted();
        }, 2000);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getSearchData();
    }
  };

  const Price = async () => {
    axios
      .get(
        "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
      )
      .then((res) => {
        setRealPrice(res?.data?.tominet?.usd);
        // setEthUsd(res.data.USD);
      });
  };
  const OnAuction = () => {
    setFilter(true);
    setAllNFTS([]);
    setLoader(true);
    setLoader2(true);
    // setMyFilterData('On Auction')

    var data = JSON.stringify({
      query: `query MyQuery {
  auctionCreateds(orderBy: mintAmount, orderDirection: desc ) {
    expiryTime
    id
    isClaimed
    label
    labelhash
    mintAmount
    minter
    startTime
    tld
    tokenId
  }
}`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        setLoader(true);
        setLoader2(true);
        var dumAuction = [];
        let minted = response?.data?.data?.auctionCreateds;

        // minted.sort(function (a, b) {
        //   return parseInt(b.tokenId) - parseInt(a.tokenId);
        // });
        for (let nft of minted) {
          let resauctioned;
          try {
            resauctioned = await CheckExpiry(nft?.tokenId);
          } catch (error) { }

          let dat = new Date(0);
          dat.setUTCSeconds(parseFloat(resauctioned?.expiryTime));
          let dateVal = null;
          if (dat) {
            const date0 = new Date();
            if (dat > date0) {
              dumAuction.push(nft);
            }
          }
        }
        if (disLanding === "d-none") {
          setAuctionLength(dumAuction);
        }
        // console.log('hhhhhhhhhhggggggggg', dumAuction)
        setAllNFTS(dumAuction);
        setLoader(false);
        setLoader2(false);
      })
      .catch((err) => {
        // console.log('hhhhhhhhhhggggggggg', err)
        setLoader(false);
        setLoader2(false);
      });
    setLoader(true);
    setLoader2(true);
  };
  // old function
  //   const OnSold = async () => {
  //     // setAllNFTS([])
  //     // setFilter(true)
  //     // setLoader(true);
  //     setLoader3(true)
  //     // setMyFilterData('Sold')
  //     var data = JSON.stringify({
  //       query: `query MyQuery {
  //         auctionCreateds(where: {isClaimed: true}) {
  //           isClaimed
  //           mintAmount
  //           labelhash
  //           label
  //           tokenId
  //           tld
  //           startTime
  //           partnerId
  //           minter,
  //         }
  // }`,
  //       variables: {},
  //     });

  //     var config = {
  //       method: "post",
  //       url: Environment.marketplaceGraph,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(async (response) => {
  //         // setLoader(true);
  //         // setLoader2(true)
  //         let minted = response?.data?.data?.auctionCreateds;
  //         minted.sort(function (a, b) {
  //           return parseInt(b.tokenId) - parseInt(a.tokenId);
  //         });
  //         var dumSold = [];
  //         for (let nft of minted) {
  //           const res = await CheckClaimNft(nft?.tokenId);
  //           var sold = res?.isClaimed;
  //           if (sold) {
  //             dumSold.push(nft);
  //           }
  //         }
  //         setAllNFTSSold(dumSold);

  //         setLoader3(false);
  //         setLoader2(false)
  //       })
  //       .catch((err) => {
  //         setLoader3(false);
  //         setLoader2(false)
  //       });
  //     // setLoader(true);
  //     // setLoader2(true)
  //   };

  // console.log("unsolddd", allNFTSSold);

  //unclaimed old function

  //   const UnSold = async () => {
  //     setLoader3(true);

  //     const data = JSON.stringify({
  //       query: `query MyQuery {
  //       auctionCreateds(where: {isClaimed: false}) {
  //         isClaimed
  //         mintAmount
  //         labelhash
  //         label
  //         tokenId
  //         tld
  //         startTime
  //         partnerId
  //         minter
  //       }
  //     }`,
  //       variables: {},
  //     });

  //     const config = {
  //       method: "post",
  //       url: Environment.marketplaceGraph,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     try {
  //       const response = await axios(config);
  //       const minted = response?.data?.data?.auctionCreateds || [];

  //       // Create an array of promises for the claim checks and expiry checks
  //       const claimChecks = minted.map(nft => CheckClaimNft(nft.tokenId));
  //       const expiryChecks = minted.map(nft => CheckExpiry(nft.tokenId));
  //       const Highest = minted.map(nft => HighestBid(nft.tokenId));

  //       // Resolve all promises concurrently
  //       const claimResults = await Promise.all(claimChecks);
  //       const expiryResults = await Promise.all(expiryChecks);
  //       const highestResult = await Promise.all(Highest);
  //       const dumSold = minted.filter((nft, index) => {
  //         const res = claimResults[index];
  //         const res1 = expiryResults[index];
  //         const res2 = highestResult[index];
  //         nft.HighestBid = res2.amount
  //         if (res1?.expiryTime) {
  //           const expiryDate = new Date(0);
  //           expiryDate.setUTCSeconds(parseFloat(res1.expiryTime));
  //           const now = new Date();
  //           // Ensure the token is not expired and not claimed
  //           return expiryDate <= now && res?.isClaimed === false;
  //         }
  //         return false;
  //       });
  // console.log("mydata",dumSold)
  //       setAllNFTSUnclaim(dumSold);
  //     } catch (err) {
  //       console.error('Error fetching unclaimed NFTs:', err);
  //     } finally {
  //       setLoader3(false);
  //     }
  //   };

  useEffect(() => {
    if (tabM === "all") {
      getMinted();
    }
  }, [tabM]);

  const handleSearchnew = async (e) => {
    // setSearch(e.target.value);
    setError("");
    setSearch2(e.target.value?.toLowerCase());
    setLoader(false);
    if (e.target.value === "") {
      setMetaDataSearch([]);
    }
  };
  const calculateHeight = () => {
    const width = window.innerWidth;

    if (width >= 3000 || width >= 2560) {
      return 24;
    } else if (width >= 1700 || width < 2151) {
      return 12;
    } else if (width >= 1600 || width < 1681) {
      return 12;
    } else {
      return 8;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    setTimeout(() => {
      const height = calculateHeight();
      setitemlength(parseInt(height));
      setHeightState(height);
      setLoader(true);
    }, 500);
  }, []);
  useEffect(() => {
    if (disLanding === "d-none") {
      OnAuction();
    } else {
      getMinted();
    }
  }, []);
  const [itemlength, setitemlength] = useState(heightState);
  // console.log('alsdjflasjfl', itemlength, allNFTS?.length, metaData?.length)
  const LoadMoreItems = async () => {
    const a = itemlength + heightState;
    if (a <= allNFTS?.length) {
      setStart(itemlength);
      setitemlength(a);
    } else {
      setitemlength(allNFTS?.length);
    }
  };
  const allldata = async () => {
    if (itemlength < 9) {
      setLoader2(true);
    }
    try {
      setLoader(true);
      if (allNFTS?.length > 0) {
        const t = start;
        const endIndex = Math.min(allNFTS?.length, itemlength);
        const itemsToProcess = allNFTS.slice(t, endIndex);
        const metaDataPromises = itemsToProcess.map(async (item) => {
          const res1 = await CheckExpiry(item.tokenId);
          const dat = new Date(0);
          dat.setUTCSeconds(parseFloat(res1?.expiryTime));
          const data = {
            tokenId: item.tokenId,
            HighestBid: "",
            Auction: "",
            Timeline: "",
            name: item.label,
            mintTime: item.mintTime,
            initialExpiryTime: dat,
            tld: item.tld,
          };

          const res0 = await HighestBid(item.tokenId);
          data.HighestBid = await res0?.amount;

          const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
          res1.expiryTime = dateVal;
          const date0 = new Date();
          data.Auction = dat > date0;

          data.Timeline = await res1;
          return data;
        });

        const processedData = await Promise.all(metaDataPromises);
        setMetaData([...metaData, ...processedData]);
      }
    } catch (error) {
      // Handle error here
    } finally {
      setLoader2(false);
      setLoader(false);
    }
  };
  const Filterdata = async () => {
    try {
      setLoader(true);
      setMetaData([]);

      if (allNFTS?.length > 0) {
        const endIndex = Math.min(allNFTS.length, itemlength);
        const itemsToProcess = allNFTS.slice(0, endIndex);

        const metaDataPromises = itemsToProcess.map(async (item) => {
          const res1 = await CheckExpiry(item.tokenId);
          const dat = new Date(0);
          dat.setUTCSeconds(parseFloat(res1?.expiryTime));

          const data = {
            tokenId: item.tokenId,
            HighestBid: "",
            Auction: false,
            Timeline: "",
            name: item.label,
            mintTime: item.mintTime,
            initialExpiryTime: dat,
            tld: item.tld,
          };

          const res0 = await HighestBid(item.tokenId);
          data.HighestBid = await res0?.amount;

          const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
          res1.expiryTime = dateVal;
          const date0 = new Date();
          data.Auction = dat > date0;

          data.Timeline = await res1;
          return data;
        });

        const processedData = await Promise.all(metaDataPromises);
        // const bidDesc = processedData.sort((a, b) => parseInt(b.HighestBid) - parseInt(a.HighestBid));
        setMetaData(processedData);
      }
    } catch (error) {
      // Handle error here
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    Price();
    if (filter) {
      Filterdata();
    } else {
      allldata();
    }
  }, [allNFTS?.length > 0, itemlength]);

  // Card component to render each card
  const Card = ({ elem, auctionEnded }) => {
    let name = elem?.name || elem?.label;
    // console.log(elem?.Auction == false || auctionEnded == true);
    return (
      <div className="custom_cardss_mainss">
        <div className="custom-cards">
          <Link to={{ pathname: `/place/${elem.tokenId}` }}>
            <div className="card">
              <svg className="cardinner_svg" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M14.6826 0.625C6.67462 0.625 0.183594 7.11647 0.183594 15.125C0.183594 18.4914 1.3307 21.5908 3.25507 24.0513C3.41724 24.259 3.71193 24.3044 3.93542 24.164L7.15324 22.1406C7.18687 22.1189 7.21653 22.0951 7.24422 22.0674L11.8722 19.1678V28.9189C11.8722 29.1701 12.0502 29.3896 12.2994 29.4292C13.0747 29.5578 13.8697 29.625 14.6826 29.625C15.4955 29.625 16.3538 29.5538 17.1588 29.4134C17.406 29.3699 17.582 29.1543 17.582 28.9031V19.2232L22.1783 22.103C22.1922 22.1129 22.2041 22.1248 22.2179 22.1347L25.4417 24.1482C25.6651 24.2886 25.9579 24.2412 26.12 24.0335C27.0219 22.8784 27.7517 21.5829 28.2718 20.1865C28.3569 19.957 28.2659 19.7019 28.0582 19.5713L27.4056 19.1619C27.3858 19.1441 27.364 19.1263 27.3403 19.1105L15.0129 11.3868C14.9911 11.3729 14.9674 11.363 14.9456 11.3531C14.7815 11.2562 14.5718 11.2503 14.3978 11.3591L5.21503 17.1128C5.07461 16.4581 4.99946 15.7797 4.99946 15.0835C4.99946 9.73522 9.33472 5.39966 14.6826 5.39966C19.5459 5.39966 23.5707 8.98361 24.2609 13.6554C24.2847 13.8137 24.3737 13.9541 24.5082 14.0391L28.3727 16.4601C28.699 16.6658 29.1282 16.4601 29.152 16.0744C29.1717 15.7599 29.1836 15.4415 29.1836 15.123C29.1836 7.11648 22.6906 0.625 14.6826 0.625Z" fill="#FF0083" />
              </svg>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="195" height="277" viewBox="0 0 195 277" fill="none" className='cardimg'>
              <path d="M140.005 46.7178C190.547 46.7178 231.748 86.9204 233.65 137.251L277.988 164.613C279.313 156.837 280 148.851 280 140.694C280 62.991 217.327 0 139.995 0C62.6626 0 0 63.001 0 140.704C0 157.528 2.93793 173.671 8.33576 188.613C13.6738 203.435 21.422 217.086 31.1023 229.116L70.9088 204.546L116.81 176.213V284H161.985V175.342L209.181 204.476L248.977 229.045C258.648 217.016 266.386 203.355 271.714 188.523L231.041 163.412L184.293 134.559L140.105 107.287L140.005 107.347L95.9161 134.559L48.9988 163.512C47.2161 156.267 46.27 148.71 46.27 140.914C46.27 88.892 88.2376 46.7278 139.995 46.7278L140.005 46.7178ZM140.005 161.771L140.095 161.821L140.005 161.881V161.761V161.771Z" fill="white" fill-opacity="0.03" />
            </svg> */}
              {/* <h5 className="card-heading-1">Highest Bid</h5> */}
              {/* <p className="card-heading-2 text-lowercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                <rect x="0.183594" y="0.772461" width="46.2121" height="46.2121" rx="23.1061" fill="white" fill-opacity="0.05" />
                <path d="M22.913 15.7245C27.115 15.7245 30.5403 19.0472 30.6984 23.2071L34.3846 25.4686C34.4947 24.8258 34.5518 24.1658 34.5518 23.4916C34.5518 17.0695 29.3414 11.8633 22.9122 11.8633C16.483 11.8633 11.2734 17.0703 11.2734 23.4925C11.2734 24.8829 11.5177 26.2171 11.9664 27.4521C12.4102 28.6771 13.0544 29.8054 13.8592 30.7997L17.1686 28.769L20.9847 26.4272V35.3359H24.7404V26.3553L28.6641 28.7632L31.9727 30.7939C32.7767 29.7996 33.42 28.6705 33.8629 27.4447L30.4815 25.3693L26.595 22.9846L22.9213 20.7305L22.913 20.7355L19.2476 22.9846L15.3471 25.3776C15.1988 24.7787 15.1202 24.1542 15.1202 23.5098C15.1202 19.2102 18.6093 15.7253 22.9122 15.7253L22.913 15.7245ZM22.913 25.2336L22.9205 25.2378L22.913 25.2427V25.2328V25.2336Z" fill="#FF0083" />
              </svg>
              {elem.HighestBid
                ? (parseInt(elem.HighestBid) / 1000000000000000000)?.toFixed(2)
                : "70"}{" "}
            </p> */}
              <div className="card-img-top-div">
                <span className="w-100 text-left set-text-font">
                  {name?.substring(0, 13)}
                  {name?.length > 13 && "(...)"}.{elem.tld}
                </span>
                <div className="buttton_mains">
                  <button className="view_detaill">View Detail</button>
                </div>
              </div>
              <div className="card-style-2 w-100">
                {(elem?.Auction == false || auctionEnded == true) && (
                  <>
                    {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                    {/* <h5 className="endedspan">Ended</h5> */}
                  </>
                )}
                {elem?.Auction == true && auctionEnded == false && (
                  <>
                    {/* <h5 className="card-heading-4">Auction Ends In</h5> */}
                    {elem.Timeline?.expiryTime && (
                      <Countdown
                        date={elem.initialExpiryTime}
                        renderer={({ days, hours, minutes, seconds }) => {
                          return (
                            <>
                              <h5 class="card-heading-6">
                                <div className="timermain">
                                  <span className="timerinner">
                                    {days < 10 && 0}
                                    {days}
                                  </span>
                                  <p className="timerpara">DAYS</p>
                                </div>
                                <div className="timermain">
                                  <span className="timerinner">
                                    {hours < 10 && 0}
                                    {hours}
                                  </span>
                                  <p className="timerpara">HOURS</p>
                                </div>
                                <div className="timermain">
                                  <span className="timerinner">
                                    {minutes < 10 && 0}
                                    {minutes}
                                  </span>
                                  <p className="timerpara">MINUTES</p>
                                </div>
                                <div className="timermain">
                                  <span className="timerinner">
                                    {seconds < 10 && 0}
                                    {seconds}
                                  </span>
                                  <p className="timerpara">SECONDS</p>
                                </div>
                              </h5>
                            </>
                          );
                        }}
                      ></Countdown>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="botttoms_cards">
              <div className="bidteext">
                <p>Highest Bid</p>
                <h1>
                  {" "}
                  {elem.HighestBid
                    ? `${(parseInt(elem.HighestBid) / 1000000000000000000)?.toFixed(2)} tomi`
                    : "70"}{" "}
                </h1>
              </div>
              <div>
                <p>Estimated value </p>
                <h1>
                  {" "}
                  {elem.HighestBid
                    ? (parseInt(elem.HighestBid / 1000000000000000000) * realPrice)?.toFixed(2)
                    : "70"}{" "}
                  USDT{" "}
                </h1>
              </div>
            </div>
          </Link>
        </div>
      </div>

    );
  };

  const display = (
    search2 ? metaDataSearch : disLanding === "d-none" ? metaData?.slice(0, 8) : metaData
  )
    ?.filter((elem) => {
      if (search === null) {
        return true;
      } else {
        return elem?.name?.toLowerCase().includes(search.toLowerCase());
      }
    })
    .slice(0, search2 ? 1000 : itemlength)
    ?.map((elem, index) => <Card key={index} elem={elem} auctionEnded={false} />);

  const fetchMoreData = () => {
    if (metaData?.length >= allNFTS?.length) {
      return false;
    } else {
      return true;
    }
  };

  // console.log("testing", allNFTSUnclaim);

  return (
    <>
      {loader3 && <Loader />}
      <div className="wrappper-market">
        <img src="\assets\marketbg.png" alt="img" className="img-fluid banner-bg4" />
        {/* <img src="\assets\marketbottom.png" alt="img" className="img-fluid banner-bg5" /> */}
        <Navbar disLanding={disLanding} setTab={""} setTab2={""} setTab3={"common"} />
        <section class="market">
          <div class="custom-container">
            <div className="upper_domainnss">
              <h1 className="btttttt">
                Domains <span className="mydpayyy"> Marketplace</span>{" "}
              </h1>
              <p className="btttttty">
                All the stats you need to stay up-to-date with the tomi Domains market.
              </p>
            </div>
            <div>
              <div className={`domainSearh ${disLanding} mb-5`}>
                <div className="newwwww_bar" style={{ position: "relative" }}>
                  <input
                    type="text"
                    value={search2}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    onChange={handleSearchnew}
                    className="search-bar"
                    placeholder="Search Domain"
                  />
                  <img
                    onClick={getSearchData}
                    className="img-fluid sImg searc-icon"
                    src="\assets\search-normal.svg"
                    alt="img"
                  />
                  <button className="butttttonn">Search</button>
                </div>
              </div>
              <div className="pspans">
                <span
                  type="button"
                   className="buttton_svggneww"
                  onClick={() => {
                    if (!loader && !loader2) {
                      getMinted();
                      setSortByy("all");
                      setTabM("all"); // Setting tabM to ensure useEffect knows to refetch
                    }
                  }}
                >
                  {loader2 || loader ? (
                    <p className={sortByy === "all" ? "sortcolr" : "cNAllowed"}>All</p>
                  ) : (
                    <p className={sortByy === "all" ? "sortcolr" : "cPointer"}>All</p>
                  )}
                </span>
                {/* //when you want to call OnAuction() OnAuction() */}
                {/* <span
                      type="button"

                      onClick={() => { (loader2 || loader) ||  setSortByy('auction'); setTabM('') }}
                    >
                      {(loader2 || loader) ? <p className={sortByy === 'auction' ? "sortcolr" : 'cNAllowed'}> On Auction</p> : <p className={sortByy === 'auction' ? "sortcolr" : 'cPointer'}> On Auction</p>}
                    </span> */}
                <span
                  type="button"
                   className="buttton_svggneww"
                  onClick={() => {
                    loader2 || loader || setSortByy("sold");
                    setTabM("soldTab");
                    setLoader(false);
                  }}
                >
                  {loader2 || loader ? (
                    <p className={sortByy === "sold" ? "sortcolr" : "cNAllowed "}>Sold</p>
                  ) : (
                    <p className={sortByy === "sold" ? "sortcolr" : "cPointer "}>Sold</p>
                  )}
                </span>

                <span
                  type="button"
                   className="buttton_svggneww"
                  onClick={() => {
                    loader2 || loader || setSortByy("Auction");
                    setTabM("Auction");
                    setLoader(false);
                  }}
                >
                  {loader2 || loader ? (
                    <p className={sortByy === "Auction" ? "sortcolr" : "cNAllowed "}>Auction</p>
                  ) : (
                    <p className={sortByy === "On Action" ? "sortcolr" : "cPointer "}>Auction</p>
                  )}
                </span>
                <span
                className="buttton_svggneww"
                  type="button"
                  onClick={() => {
                    loader2 || loader || setSortByy("unclaimed");
                    setTabM("unclaimed");
                    setLoader(false);
                  }}
                >
                  {loader2 || loader ? (
                    <p className={sortByy === "unclaimed" ? "sortcolr" : "cNAllowed"}>Unclaimed</p>
                  ) : (
                    <p className={sortByy === "unclaimed" ? "sortcolr" : "cPointer"}>Unclaimed</p>
                  )}
                </span>
              </div>
              {tabM === "soldTab" && (
                <MarketSold
                  search2={search2}
                  metaDataSearch={metaDataSearch}
                  setLoader3={setLoader3}
                  loader3={loader3}
                  price={realPrice}
                />
              )}
              {tabM === "unclaimed" && (
                <MarketUnclaimed
                  search2={search2}
                  metaDataSearch={metaDataSearch}
                  setLoader3={setLoader3}
                  loader3={loader3}
                  price={realPrice}
                />
              )}
              {tabM === "all" &&
                (search2 ? (
                  <>
                    <div class="cardsss">{display ? display : ""}</div>
                    {display?.length < 1 && !loader2 && !loader && (
                      <div className={`centershowing-text text-center w-100 ${disLanding} py-3`}>
                        {error && (
                          <h2>
                            Domains Not Found <span className="common">!</span>
                          </h2>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <InfiniteScroll
                    dataLength={itemlength}
                    next={LoadMoreItems}
                    hasMore={fetchMoreData}
                  // scrollableTarget="scrollableDiv"
                  >
                    <div class="cardsss">{display ? display : ""}</div>
                    {display?.length < 1 && !loader2 && !loader && (
                      <div className={`centershowing-text text-center w-100 ${disLanding} py-3`}>
                        {metaDataSearch?.lenfth < 1 && (
                          <h2>
                            Domains Not Found <span className="common">!</span>
                          </h2>
                        )}
                      </div>
                    )}
                  </InfiniteScroll>
                ))}
              {loader && (
                <div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
                  <img
                    width={35}
                    style={
                      {
                        // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                      }
                    }
                    src="\loader\loader.gif"
                    alt="loader"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Market;
