import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Modal from "react-bootstrap/Modal";
import ClaimToken from "../../../hooks/dataSenders/claimToken";
import BalanceOf from "../../../hooks/dataFetchers/balanceOf";
import { toast } from "react-toastify";
import Environment from "../../../utils/Environment";
import axios from "axios";
import "./bannernew.scss";
import { useRef } from "react";
const BannerNew = () => {
  const inputRef = useRef(null);
  const [domainType, setDomainType] = useState(0);
  const [inputVal, setInputVal] = useState("");
  const [inputValOrg, setInputValOrg] = useState("");
  const [balance, setBalance] = useState(true);
  const [claimRes, setClaimRes] = useState();
  const { account } = useWeb3React();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const { claimTokenHook } = ClaimToken();
  const { balanceOfHook } = BalanceOf();
  const [countDown, setCountDown] = useState();
  // console.log('lsadflasdlf', inputVal,domainType)
  const inputVAlue = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    const pattern = /^[A-Za-z0-9-]*$/; // Regular expression pattern

    if (pattern.test(value)) {
      const inpoval = value.toLowerCase().split(".");

      setInputValOrg(value.toLowerCase());
      if (inpoval[1] === "tomi") {
        setDomainType(0);
      } else if (inpoval[1] === "com") {
        setDomainType(1);
      } else {
        setDomainType(0);
      }
      //   else if (inpoval[1] !== '' && inpoval[1] !== 'tomi' && inpoval[1] !== 'com') {
      //     setDomainType(-1);
      //   }
      setInputVal(inpoval[0]);
    }
  };
  // const inputVAlue = (e) => {
  //     const value = e.target.value.replace(/\s/g, "");
  //     const inpoval = value.toLowerCase().split('.')

  //     setInputValOrg(value.toLowerCase())
  //     if (inpoval[1] === 'tomi') {
  //         setDomainType(0)
  //     } else if (inpoval[1] === 'com') {
  //         setDomainType(1)
  //     } else if (!inpoval[1] === '' && (!inpoval[1] === 'tomi' || !inpoval[1] === 'com')) {
  //         setDomainType(-1)
  //     }
  //     setInputVal(inpoval[0])
  // }

  const claim = async () => {
    try {
      setLoader(true);
      let res = await claimTokenHook(account);
      setClaimRes(res);
      setLoader(false);
      toast.success("Claimed Successfully");
      setShow(false);
    } catch (error) {
      setLoader(false);
      toast.error("Claimed Error");
    }
  };
  const allowanceofAccount = async () => {
    let res = await balanceOfHook(account);
    setBalance(res);

    if (parseFloat(res) > 100) {
      setBalance(false);
      setShow(false);
    } else {
      setBalance(true);
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const timer = async () => {
    // Set the end date and time in UTC
    const endDate = new Date("2024-05-15T11:44:35Z"); // Set the end time to 12:00 PM UTC 2024-05-15T12:00:00Z'

    // Get the countdown element
    const countdownElement = document.getElementById("countdown");

    // Update the countdown every second
    const countdownTimer = setInterval(updateCountdown, 1000);

    // Initial state
    let countdownState = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    function updateCountdown() {
      // Get the current local date and time
      const now = new Date();

      // Calculate the time remaining in milliseconds
      const timeRemaining = endDate.getTime() - now.getTime();

      // Calculate the days, hours, minutes, and seconds
      countdownState = {
        days: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
        hours: Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeRemaining % (1000 * 60)) / 1000),
      };

      setCountDown(countdownState);
    }

    updateCountdown(); // Call initially to avoid delay

    // Set the interval to update the countdown every second
    setInterval(updateCountdown, 1000);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      domainFun();
    }
  };
  const domainFun = () => {
    if (inputVal.endsWith("-")) {
      toast.info("Domain Should Not End With Special Character", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (inputVal.length < 3) {
      toast.info("Character Length should be greater then 2", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      history.push(`/domainSearhResult/${domainType}/${inputVal}`);
    }
  };
  useEffect(() => {
    if (account) {
      allowanceofAccount();
    }
  }, [account, claimRes]);
  useEffect(() => {
    timer();
  }, []);

  // const renderTooltip = (props) => (
  //     <Tooltip id="button-tooltip" {...props}>
  //         Have a question? Need Help? Contact Us
  //     </Tooltip>
  // );
  return (
    <>
      <section className="banner-new">
        {/* <div className="floating-btn-telegram">
                    <a href="https://t.me/+88806879426" target="_black">
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <img src="\assets\newlanding-assets\exchangesicons\telegram-icon.svg" alt="img" className="img-fluid" style={{ cursor: "pointer" }} />
                        </OverlayTrigger>
                    </a>
                </div>
                <div className="floating-btn-telegrammobile d-none">
                    <a href="https://t.me/+88806879426" target="_black">
                            <div className="main-set">
                            <img src="\assets\newlanding-assets\exchangesicons\telegram-icon.svg" alt="img" className="img-fluid tele-icon" style={{ cursor: "pointer" }} />
                            <div className="inner-hover d-none">
                                <p>Have a question? Need Help? Contact Us</p>
                            </div>
                            </div>
                    </a>
                </div> */}
        <img src="\assets\pinkbg.png" alt="img" className="img-fluid banner-bg" />
        <img src="\assets\phonebannner.png" alt="img" className=" banner-bg_phone d-none" />
        <div className="custom-containerl">
          <div className="row">
            <div className="col-xl-12 col-12 padd-sm ordersm-2">
              <div className="inner-content">
                <div className="inner-text">
                  <p className="greyishpara">
                    Explore // <span className="domainsspan">tomi Token</span>
                  </p>
                  <h1>
                    {" "}
                    DNS for the TOMI Net {" "}
                  </h1>
                  <p>Claim your NFT domain on TOMI's mainnet!</p>
                </div>
                <div className="option-field">

                  <svg className="img-fluid search-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z" stroke="#6E8491" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.7517 15.7509L14.1641 14.1633" stroke="#6E8491" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
                  </svg>
                  <input
                    pattern="[^\s]+"
                    onChange={inputVAlue}
                    value={inputValOrg}
                    type="text"
                    placeholder="Search names or addresses"
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                  />

                  <Link className="dgcvdvtdctcdvtvd" onClick={domainFun}>
                    <button
                      disabled={domainType < 0 || inputVal == ""}
                      class={domainType < 0 || inputVal == "" ? "btn-search" : "btn-search"}
                      type="button"
                    >
                      Search
                    </button>
                  </Link>
                </div>
                <a
                  href="https://tomi-domains.gitbook.io/tomi-domains/"
                  className="pinkbtn"
                  target="_blank"
                >
                  Documentation{" "}
                 
                </a>
                {/* <div className="timer">
                                    <h6 className='auctionendtext'><span className="whitetext">Domains is live!</span> <br />   We successfully moved to mainnet. Claim your domain now!
                                    </h6>

                                    <h6 className='timer-text'>All live auctions end In: </h6>
                                    <div className="timer-div">
                                        <div className="inner-timer">
                                            <h6>{countDown?.days < 0 ? '00' : countDown?.days < 10 ? `0${countDown?.days}` : countDown?.days}</h6>
                                            <p>DAYS</p>
                                        </div>
                                        <div className="inner-timer">
                                            <h6>{countDown?.hours < 0 ? '00' : countDown?.hours < 10 ? `0${countDown?.hours}` : countDown?.hours}</h6>
                                            <p>HOURS</p>
                                        </div>
                                        <div className="inner-timer">
                                            <h6>{countDown?.minutes < 0 ? '00' : countDown?.minutes < 10 ? `0${countDown?.minutes}` : countDown?.minutes}</h6>
                                            <p>MINUTES</p>
                                        </div>
                                        <div className="inner-timer">
                                            <h6>{countDown?.seconds < 0 ? '00' : countDown?.seconds < 10 ? `0${countDown?.seconds}` : countDown?.seconds}</h6>
                                            <p>SECONDS</p>
                                        </div>
                                    </div> 
                                </div> */}
                {/* <a href="https://tomi-domain-name-service.gitbook.io/titled" className="btn-docs" target="_blank">Documentation
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM14.79 12.53L11.26 16.06C11.11 16.21 10.92 16.28 10.73 16.28C10.54 16.28 10.35 16.21 10.2 16.06C9.91 15.77 9.91 15.29 10.2 15L13.2 12L10.2 9C9.91 8.71 9.91 8.23 10.2 7.94C10.49 7.65 10.97 7.65 11.26 7.94L14.79 11.47C15.09 11.76 15.09 12.24 14.79 12.53Z" fill="#FF0083" />
                                    </svg>

                                </a> */}
              </div>
            </div>
            {/* <div className="col-xl-5 col-12 padd-sm ordersm-1"> */}
            {/* <div className="banner-new-img">
                                <img src="\assets\newlanding-assets\banner-img-shadow.png" alt="img" className="img-fluid banner-img-shadow" />
                                <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715279948/tDNS/Frame_48095806_rz1vmq.png" alt="img" className='img-fluid' />
                            </div> */}
            {/* </div> */}
          </div>
        </div>
        {/* <div className="custom-containerl">
                    <div className="bottom-contenttt">
                        <div className="inner-content">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1689600719/tDNS/search-domain_stusks.svg" alt="img" className='img-fluid' />
                            <h6>Search for a Domain</h6>
                        </div>
                        <div className="inner-content">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1689600746/tDNS/mint_gsvyz7.svg" alt="img" className='img-fluid' />
                            <h6>Mint & become a partner</h6>
                        </div>
                        <div className="inner-content">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1689600762/tDNS/auction_eeduda.svg" alt="img" className='img-fluid' />
                            <h6>WIN the AUCTION</h6>
                        </div>
                        <div className="inner-content">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1689600835/tDNS/managedomain_kkhwa0.svg" alt="img" className='img-fluid' />
                            <h6>Manage domains</h6>
                        </div>
                    </div>
                </div> */}
      </section>
    </>
  );
};

export default BannerNew;
