import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getRegistryAddressContract } from "../../utils/contractHelpers";
import { getPublicResolverContract } from "../../utils/contractHelpers";
import { getSetContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import contentHash from '@ensdomains/content-hash'
import namehash from 'eth-ens-namehash'
const DomainProfileCont = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const registrycontractAddress = environment.registryAddress;
    const contractRegistry = getRegistryAddressContract(registrycontractAddress, web3);
    const contractAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(contractAddress, web3);

    // // console.log('account of the day', account)
    const domainProfileFun = useCallback(
        async (node, value, type, bool) => {
            // console.log("nodeee",node)
            const name = namehash.hash(node)
            // // console.log('good hash', name)
            if (bool) {
                if (name) {
                    try {
                        const resContract = await contractRegistry.methods.resolver(name).call();
                        // console.log("name",name)
                        if (resContract) {
                            const publicResolverContract = await getPublicResolverContract(resContract, web3);
                            try {
                                const res = await publicResolverContract.methods.contenthash(name).call()
                                const codec = contentHash.getCodec(res)
                                const domainDecoded = contentHash.decode(res)
                                // console.log("name111",res,codec,domainDecoded)
                                // // console.log('res of the hassh', res, codec, domainDecoded)
                                let resArray = {
                                    codec, domainDecoded
                                }
                                return resArray;
                            } catch (error) {
                                // // console.log('res of the hassh', error)
                                throw error;
                            }
                        } else {
                            // // console.log('kdfaisfiofjdi')
                        }
                    } catch (error) {
                        // // console.log('resHash of the da', error)
                        throw error
                    }


                }
            } else {
                if (name) {
                    let contentHashRes;
                    try {
                        var gasFunPrice;
                        web3.eth.getGasPrice().then((result) => {
                            var result2 = parseInt(result) + 3000000000
                            // // console.log("gasfun", typeof result2, result2)
                            gasFunPrice = result2.toString()
                        })

                        let types = parseInt(type)
                        if (types === 1) {
                            contentHashRes = contentHash.encode("ipfs-ns", value)
                        } else if (types === 2) {
                            contentHashRes = contentHash.encode("ip4", value)
                        } else if (types === 3) {
                            contentHashRes = contentHash.encode("dns", value)
                        } else {
                            contentHashRes = contentHash.encode("onion", value + '.onion')
                        }
                        // // console.log('type of the ', contentHashRes, types)
                        const resContract = await contractRegistry.methods.resolver(name).call();
                        if (resContract && contentHashRes) {
                            const publicResolverContract = await getPublicResolverContract(resContract, web3);
                            try {
                                const gas = await publicResolverContract.methods.setContenthash(name, "0x" + contentHashRes).estimateGas({ from: account, })
                                const res = await publicResolverContract.methods.setContenthash(name, "0x" + contentHashRes).send({ from: account, gas: gas, gasPrice: gasFunPrice, })
                                // // console.log('good publicResolver settext', res)
                                return res;
                            } catch (error) {
                                // // console.log('good publicResolver settext', error)
                                throw error;
                            }
                        } else {
                            // // console.log('kdfaisfiofjdi')
                        }
                    } catch (error) {
                        // // console.log('resHash of the da', error)
                        throw error
                    }


                }
            }

            // if (bool) {
            //     try {
            //         // // console.log('domainProfile contract data contract', key, value, tokenId)
            //         const resContract = await contract.methods.resolverOf(tokenId).call();
            //         if (resContract) {
            //             // // console.log('resContract', resContract)
            //             const contract1 = await getSetContract(resContract, web3);
            //             const details = await contract1.methods
            //                 .get(key, tokenId)
            //                 .call()
            //             return details;
            //         }

            //     } catch (error) {
            //         throw (error)
            //     }
            // } else {
            //     try {
            //         // // console.log('domainProfile contract data contract', key, value, tokenId)
            //         const resContract = await contract.methods.resolverOf(tokenId).call();
            //         if (resContract) {
            //             // // console.log('resContract', resContract)
            //             const contract1 = await getSetContract(resContract, web3);
            //             const details = await contract1.methods
            //                 .set(key, value, tokenId)
            //                 .send({
            //                     from: account,
            //                 })
            //             return details;
            //         }

            //     } catch (error) {
            //         throw (error)
            //     }
            // }

        },
        [contract, account]
    );
    return { domainProfileFun: domainProfileFun };
};
export default DomainProfileCont;