import '../../App.scss';
import React, { useState, useEffect } from "react";
// import Banner from './main-banner/Banner.js';
// import Giveaway from './giveaway/Giveaway.js';
// import Building from './building/Building ';
// import Computerb from './computerb/Computerb';
// import Getinvolved from './getinvolved/Getinvolved';
// import Faqs from './faqs/Faqs';
import Navbar from './header/Navbar';
// import { Buffer } from 'buffer';
// import Tominet from './TomiNet/Tominet';
// import Partner from './Partner/Partner';
// import LiveAuction from './LiveAuction/LiveAuction';
// import Market from '../Marketplace/Market';
function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
    window.Buffer = Buffer;
  }, [])
  return (
    <>
    <Navbar setTab={'common'} setTab2={''} setTab3={''} />
      {/* <Banner />
      <Giveaway />
      <LiveAuction />
      <Computerb/>
      <Building />
      <Tominet />
      <Partner />
      <Faqs />
      <Getinvolved/> */}
    </>
  );
}
export default Landing;