import React, { useState } from "react";
import "./threebanners.scss";

const Threebanners = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("values", values);

    // Update the corresponding field in state
    setValues({
      ...values, // Keep the other fields intact
      [name]: value, // Update the specific field being changed
    });
  };

  const Connect = () => {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "generate_lead",
        event_label: "early_access",
      });
      console.log("cartttttt1111", window.dataLayer);
      console.log(`GTM event 'generate_lead' triggered `);
    } else {
      console.error("Google Tag Manager dataLayer is not available");
    }
  };
  return (
    <>
      <section className="befirst">
        {/* <img src="\assets\bgfolder.png" alt="" className="elipse img-fluid" /> */}
        <div className="custom-container">
          <div className="section_one">
            <div>
              <img src="\assets\Circles.png" alt="NFT Domain" className="nftsdomainimg" />
            </div>
            <div className="right_banner_section">
              <p>//NFT ownership</p>
              <h2>
                Ownership You
              </h2>
              <h2 className="whitetext">Can Prove</h2>
              <p className="whitepara">
                Since tomi domains are NFTs, you can prove ownership and claim rewards for eternity!
                NFT ownership is the key to building a new version of the internet that is truly
                distributed.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="buydomain">
        <img src="\assets\eslipse2.svg" alt="" className="elipse2" />
        <div className="custom-containerl">
          <div className="main_outer">
            <div className="innner_two">
              <p>//Decentralized deployment</p>
              <h2>Do it all on tomi</h2>

            </div>
            <div className="right_side">
              <div className="flexxdiv">
                <div className="innnner_div">
                  <div className="cards__sections">

                    <h3>01. Super VPN</h3>
                    <p className="lowerpara">
                      Protect your browsing journey with robust encryption and tomi’s no-logs
                      policy.
                    </p>
                  </div>

                  <div className="cards__sections">
                    <h3>02. Explore, Share, Create</h3>
                    <p className="lowerpara">
                      Uncensored video sharing offers an open space for self-expression,
                      creativity, and education.
                    </p>
                  </div>

                </div>
                <div className="innnner_div">

                  <div className="cards__sections">
                    <h3>0.3 Cut the Cookies</h3>
                    <p className="lowerpara">
                      Cookies don’t follow you around and track your every move while you surf.
                    </p>
                  </div>


                  <div className="cards__sections">
                    <h3>0.4 News Feed</h3>
                    <p className="lowerpara">
                      Customize your daily news to fit your interests without subjecting yourself
                      to data mining and ad snooping.
                    </p>
                  </div>

                </div>
                <div className="innnner_div">

                  <div className="cards__sections">
                    <h3>0.5 Poof!</h3>
                    <p className="lowerpara">
                      Annoying Ads and cookies don’t exist on tomi. (we block the ones you don’t
                      find particularly annoying too.)
                    </p>
                  </div>


                  <div className="cards__sections">
                    <h3>0.6 Listen Offline</h3>
                    <p className="lowerpara">
                      Tune into your favorite songs and catch the latest podcast episode. No
                      internet connection needed!
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="rewardeveryone">
        <div className="custom-containerl">
          <div className="mainsection_three">
            <div className="left_side">
              <p className="contactusstext">//Contact Us</p>
              <h2 className="whitetext">
                Get<span> Early Access</span>
              </h2>
              <p className="whitepara">
                Sign up to get exciting new tomi features as soon as they're ready for use!
              </p>
            </div>
            <div className="input_type">
              <div className="typpppe">
                <input
                  placeholder="Enter Name"
                  type="text"
                  id="fname"
                  name="name"
                  value={values?.name}
                  onChange={handleChange}
                />
         
              </div>
              <div className="typpppe">
                <input
                  placeholder="Enter Email Address"
                  type="email"
                  id="fname"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
            
              </div>
              <button onClick={Connect}>Let’s Connect</button>
            </div>
          </div>
        </div>
      </section> */}

      <section className="refferprogram">
        {/* <img src="\assets\newshade.svg" className="myyyelipse img-fluid" /> */}
        <img src="\assets\auctionbg.png" className="myyyelipse2 img-fluid" />
        <div className="custom-containerl">
          <div className="mainlast">
            <div className="innnnnner">
              <h2>
                Own Your Identity on the Web: Claim <span> Your NFT Domain </span> on tomi
              </h2>
              <p>
                Claim your unique NFT domain name on tomi's secure and decentralized network. Enjoy
                greater privacy, control, and potential rewards!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Threebanners;
