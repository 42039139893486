import "./place.scss";
import Navbar from "../landing/header/Navbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import CheckHigh from "../../hooks/dataFetchers/getHighestBid";
import CheckExpire from "../../hooks/dataFetchers/checkExpiry";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";
import CopyToClipboard from 'react-copy-to-clipboard'
import "react-toastify/dist/ReactToastify.css";
import useWeb3 from "../../hooks/useWeb3";
import Claim from "../../hooks/dataSenders/claim";
import { useWeb3React } from "@web3-react/core";
import "./place.scss";
import Loader from "../../hooks/loader";
import { Link, useHistory, useParams } from "react-router-dom";
import Countdown from "react-countdown";
import Nftbiding from "../../hooks/dataSenders/bid";
import CancelBid from "../../hooks/dataSenders/cancelBid";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWindowSize } from 'react-use';
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Confetti from 'react-confetti'
export const Place = () => {
  const { width, height } = useWindowSize();
  const { type } = useParams();
  let cardType = type.split(':')[1]
  let typeParamId = type?.split(':')[0]
  console.log('sladflad', typeParamId)
  const { account } = useWeb3React();
  const [loader, setLoader] = useState();
  const [highestBid, setHighestBid] = useState(0);
  // console.log('jghhjhty', highestBid)
  const [bidAmount, setBidAmount] = useState(0);
  const [minimumBid, setMinimumBid] = useState(0.1);
  const [bided, setBided] = useState(false);
  const [userBid, setUserBid] = useState(null);
  const [balance, setBalance] = useState(null);
  const [itemArr, setItemArr] = useState([]);
  const [auction, setAuction] = useState(false);
  const [windowReload, setWindowReload] = useState(false);
  const [errors, setErrors] = useState()
  const [copied, setCopied] = useState(false)
  const history = useHistory()
  const [price, setPrice] = useState(null);
  const [bidNftState, setBidNftState] = useState()
  const [cancelBidState, setCancelBidState] = useState()
  const [claimBidDetail, setClaimBidDetail] = useState()
  const [claimBidDetailTwoState, setClaimBidTwoState] = useState()
  const [bidsMade, setBidsMade] = useState([]);
  const [timerDat, setTimerdat] = useState('');
  const [firstbider, setFirstbider] = useState('')
  const [increaseBid, setIncreaseBid] = useState('');
  const [congrats, setCongrats] = useState(false)
  const [urlLoc, setUrlLoc] = useState()
  const [congratsModal, setCongratsModal] = useState(true)
  const [timeline, setTimeline] = useState('');
  const [error, setError] = useState();
  const [TBid, setTBid] = useState(0);
  const { UserClaim } = Claim();
  const web3 = useWeb3();
  const { HighestBid } = CheckHigh();
  const { CheckExpiry } = CheckExpire();
  const { UserBiding } = Nftbiding();
  const { CancelBiding } = CancelBid();
  const contractAddress = Environment.tomiToken;
  const contract = gettomiTokenContract(contractAddress, web3);
  const socketUrl = 'https://api.tomiback.co';
  var data = JSON.stringify({
    query: `query MyQuery {
      auctionCreateds(where: {tokenId: "${typeParamId}"}) {
        expiryTime
        id
        isClaimed
        label
        labelhash
        mintAmount
        minter
        startTime
        tld
        tokenId
      }
    }`,
    variables: {},
  });
  var config = {
    method: "post",
    url: Environment.marketplaceGraph,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const getBidDetail = async () => {
    setLoader(true)
    await axios(config)
      .then(async function (response) {
        if (response?.data?.data?.auctionCreateds?.length > 0) {
          getBidDetailFun(response)
          highestBidFun()
          expirtyTimeFun()

        } else {
          getBidDetail()
        }
        setLoader(false)
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  const getBidDetailFun = async (response) => {
    setLoader(true)
    let bid = response.data.data.auctionCreateds;
    let dumObj = bid[0];
    setItemArr(dumObj);
    setFirstbider(dumObj?.minter)
    getData(dumObj);
    setLoader(false)
  }
  const highestBidFun = async () => {
    let res0 = await HighestBid(typeParamId);
    localStorage.setItem('tokenId', res0?.bidder)
    await setHighestBid(res0);
    let minBid = parseFloat(res0?.amount) === 0 ? '0' : res0?.amount / 1000000000000000000
    minBid = parseFloat(minBid)
    let p10 = (minBid * 15) / 100;
    minBid = minBid + p10;
    setMinimumBid(minBid)
  }
  const expirtyTimeFun = async () => {

    let res1 = await CheckExpiry(typeParamId);

    let dat = new Date(0);
    dat.setUTCSeconds(parseFloat(res1?.expiryTime));
    let dateVal = null;
    setTimerdat(dat)
    if (dat) {
      dateVal = new Date(parseFloat(res1?.expiryTime) * 1000);
      res1.expiryTime = dateVal;
      const date0 = new Date();

      if (dat > date0) {
        setAuction(true);
      } else {
        setAuction(false);
      }
      await setTimeline(res1);
    }
  }
  // console.log('firsddddt', auction)
  useEffect(() => {
    if (account) {
      setBidAmount(0);
    }
  }, [account, itemArr, windowReload]);


  const getBalance = async () => {
    if (account) {
      let res = await contract.methods
        .balanceOf(account)
        .call({
          from: account,
        })
      await setBalance(parseFloat(web3.utils.fromWei(res, "ether")).toFixed(0));
    }
  };

  const getData = (bid) => {
  };

  const OKay = () => {
    window.$("#accept").modal("hide");
  }

  const closeWithdraw = () => {
    window.$("#withdraw").modal("hide");
  }

  const closeCancel = () => {
    window.$("#cancelmodal").modal("hide");
  }

  const BidNft = async () => {
    if (account) {
      try {
        let prevBid = bidsMade?.find((i) => {
          return i?.bidder?.toUpperCase() === account.toUpperCase();
        });
        if (prevBid) {
          prevBid = parseInt(prevBid?.totalBalance) / 1000000000000000000;
        } else {
          prevBid = 0;
        }
        let val = highestBid > 0 ? parseFloat(highestBid?.amount) / 1000000000000000000 : 0 || minimumBid;
        val = parseFloat(val);
        let p10 = (val * 15) / 100;
        val = val - p10;
        let pre = parseFloat(price);

        let pri = pre + prevBid;
        if (pre > val) {
          try {
            setLoader(true);
            let prevBid = parseFloat(bidAmount) / 1000000000000000000;
            // // console.log('sladflad bid', )
            const res = await UserBiding(
              parseFloat(price),
              typeParamId,
              account,
              prevBid
            );
            var socket = io(socketUrl, {
              path: '/socket',
              autoConnect: false,
            });
            socket.open();
            typeParamId = type?.split(':')[0];
            // console.log('bid method', typeParamId)
            socket.emit("bid", typeParamId);
            setBidNftState(res)
            setLoader(false);
            setError("");
            window.$("#placebid").modal("hide");
            window.$("#accept").modal("show");
            window.$("#increase-bid").modal("hide");
          } catch (err) {
            setError(err.message);
            setLoader(false);
            window.$("#placebid").modal("hide");
            if (err?.message?.includes('User denied transaction')) {
              toast.error('User Denied Trasaction', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            } else {
              toast.error('Sorry, Your offer didn’t go through. Another participant has submitted a higher bid than yours.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            }
          }
        } else {
          setErrors(`Bid amount must be greater than ${minimumBid} Tomi`);
        }
      } catch (err) { }

    } else {
      toast.error('Connect Your Wallet First', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const adjustPrice = (val) => {
    let currentbid = parseFloat(userBid?.amount / 1000000000000000000);
    let b = parseFloat(val)
    setPrice(b); let a = b + 0
    setTBid(a)
    if (b < balance) {
      if (bided === true) {
        if (b > currentbid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors('')
          toast.error(error?.split('::')[1]?.split(",")[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        if (b > minimumBid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors(`Bid amount must be greater than ${minimumBid} Tomi`)
        }
      }

    } else {
    }

  };

  const adjustPrice2 = (val) => {
    let currentbid = parseFloat(userBid?.amount / 1000000000000000000);
    let b = parseFloat(val)
    setIncreaseBid(b)
    let a = b + 0
    setTBid(a)
    if (b < balance) {
      if (bided === true) {
        if (b > currentbid) {
          setPrice(b);
          setErrors('')
        } else {
          setErrors(`Bid Amount must be greater than ${(parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) || 0 + (0 * 15 / 100)} Tomi`)
        }
      } else {
        if (b > minimumBid) {
          setPrice(b);
        } else {
          toast.error('Bid amount must be greater Minimum Bid', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }

    } else {

    }

  };

  const Bids = async () => {
    var data = JSON.stringify({
      query: `
    query MyQuery {
      bidCreateds(
        where: {tokenId: "${typeParamId}"}
        orderBy: amount
        orderDirection: desc
      ) {
        amount
        bidder
        id
        tokenId
      }
    }`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(async function (response) {
        // console.log('ggggggg', response?.data?.data?.bidCreateds)
        setBidsMade(response?.data?.data?.bidCreateds);
        localStorage.setItem('highestBid', response?.data?.data?.bidCreateds[0]?.bidder)
        return response?.data?.data?.bidCreateds[0]?.bidder
      })
      .catch(function (error) {
      });
  };
  const CancelBided = async () => {
    if (account) {
      setLoader(true)
      window.$("#cancelmodal").modal("hide");
      try {
        const res = await CancelBiding(
          account,
          userBid?.amount,
          typeParamId
        );
        setCancelBidState(res)
        setLoader(false)
        await toast.success("Bid Cancelled Successfully");
      } catch (error) {
        setLoader(false)
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      setLoader(false)
      await setError("Connect your account");
    }
  };

  const ClaimBid = async () => {
    if (account) {
      window.$("#exampleModal1").modal("hide");
      setLoader(true)
      try {
        const res = await UserClaim(typeParamId, account);
        var socket = io(socketUrl, {
          path: '/socket',
          autoConnect: false,
        });
        socket.open();
        socket.on('connect', function () {
        });
        typeParamId = type?.split(':')[0];
        socket.emit("bid", typeParamId);

        setLoader(false);
        window.$('#acceptOkay').modal('show')
        setClaimBidDetail(res)
        expirtyTimeFun()
        setLoader(false);
      } catch (err) {
        setLoader(false);
        toast.error(err?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0] || err?.message?.split(':')[1], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      }
    } else {
      setError("Connect your wallet");
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      window.location.reload()
      return null
    } else {
      // Render a countdown

      return (
        <span>
          {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
        </span>
      );
    }
  };
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  useEffect(() => {
    if (account) {
      for (let i of bidsMade) {
        if (i.bidder?.toUpperCase() === account?.toUpperCase()) {
          setUserBid(i)
          let weiBid = (parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) || 0 + (0 * 15 / 100)
          setBided(true);
        }
      }
    }

  }, [bidsMade, account, highestBid?.amount, windowReload]);
  useEffect(() => {
    getBidDetail();
    getBalance();
  }, [account, bidNftState, cancelBidState, claimBidDetail, claimBidDetailTwoState, windowReload]);
  //here is the code to take bid update when it delay its response
  useEffect(() => {
    // if (typeParamId) {
    //   setInterval(() => {
    //     if (bidsMade[0]?.bidder) {
    //       let heighestbidEffect = highestBid?.bidder?.toLowerCase();
    //       let heighestbidGraph = bidsMade[0]?.bidder?.toLowerCase();
    //       if (!(heighestbidEffect === heighestbidGraph)) {
    //         Bids();
    //         highestBidFun()
    //       } else {
    //       }
    //     }
    //   }, 4000);
    // highestBidFun()
    // expirtyTimeFun()
    Bids();
    // }
  }, [account, itemArr, bidsMade[0]?.bidder, highestBid]);
  useEffect(() => {
    getSocket();
  }, [])
  const getSocket = async () => {
    try {
      const socket = io(socketUrl, {
        path: '/socket',
        autoConnect: false,
      });
      socket.on('connect', function () {
        // console.log('Connected', 'connected');
      });
      socket.on('disconnect', (reason) => {
        // console.log(`Disconnected: ${reason}`);
      });
      socket.open();
      socket.on("connect_error", (err) => {
        // // console.log(err instanceof Error); // true
        // console.log(err.message); // not authorized
        // // console.log(err.data); // { content: "Please retry later" }
      });
      socket.on("error", (err) => {
        // console.log(err);
      });
      const listener = (...args) => {
        // console.log('socket ka andar', args, typeParamId, 11111)
        if (args[0] === typeParamId) {
          Bids()
          highestBidFun()
          expirtyTimeFun()
          setTimeout(() => {
            Bids()
            highestBidFun()
            expirtyTimeFun()
          }, 7000);
        }
      }
      socket.on("bidReceived", listener);
    } catch (ex) { }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (highestBid?.bidder && timeline?.isClaimed === false && congratsModal && !auction) {
      if (
        highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
        (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase())
      ) {
        window.$('#acceptOkayClaimed').modal('show')
        setCongrats(true)
      }
    }
  }, [highestBid, account, timeline, itemArr])
  const disconnect = () => {
    var socket = io(socketUrl, {
      path: '/socket',
      autoConnect: false,
    });
    socket.disconnect();
  }
  const shareUrl = `https://tdns.network/place/${typeParamId}`; // The URL you want to share
  const title = `${itemArr?.label}.${itemArr?.tld}`
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      {congrats &&
        <Confetti
          width={width - 20}
          height={height + 100}
        />
      }
      <div>

      </div>
      <section className="main-place">
        <img src='\assets\shadows.png' className='myyyelipseww22 img-fluid' />
        {/* <img src='\assets\newshade.svg' className='myyyelipse2dd img-fluid'/> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-12 m-auto p-0">
              <div className="botton">
                <button  onClick={() => { cardType === 'mintedDomain' ? history.push('/domainSearhResult/active/mm') : history.goBack() }} className="btn-back" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5285 11.4696L11.0588 11.9999L9.99816 13.0605L9.46783 12.5302L5.46783 8.53022L4.9375 7.99989L5.46783 7.46956L5.96367 6.97372L7.21392 8.15499L10.5285 11.4696ZM7.19787 7.86084L6.92417 6.01322L9.46783 3.46956L9.99816 2.93923L11.0588 3.99989L10.5285 4.53022L7.19787 7.86084Z" fill="#0E1120" />
                  </svg>
                  Back
                </button>
              </div>
              <div className="inner_sections_Datadiv">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-12 pl-0">
                    {cardType === 'minted' ?
                      <div className="maincard mainCardtwo">

                        <h3 className="cardinnerheading cardinnerheadingRelative">{itemArr?.label}.{itemArr?.tld}</h3>
                        <img src="\mintCard\tomi.svg" alt="" className="img-fluid  tomis" />
                        <img src="\mintCard\goverMint.svg" alt="" className="img-fluid govern" />

                      </div>
                      :
                      <div className="outeer_sidescards">
                        <div className="maincard" >
                          <svg className="tommmi_acrd" xmlns="http://www.w3.org/2000/svg" width="67" height="66" viewBox="0 0 67 66" fill="none">
                            <path d="M33.4079 0.15625C15.39 0.15625 0.785156 14.7621 0.785156 32.7813C0.785156 40.3556 3.36614 47.3292 7.69597 52.8654C8.06087 53.3326 8.72391 53.435 9.22676 53.119L16.4669 48.5664C16.5425 48.5174 16.6093 48.464 16.6716 48.4017L27.0845 41.8776V63.8175C27.0845 64.3827 27.485 64.8767 28.0457 64.9657C29.7901 65.255 31.579 65.4063 33.4079 65.4063C35.2369 65.4063 37.1682 65.246 38.9793 64.9301C39.5355 64.8322 39.9316 64.3471 39.9316 63.7819V42.0022L50.2733 48.4818C50.3045 48.5041 50.3312 48.5308 50.3623 48.553L57.6158 53.0834C58.1186 53.3994 58.7772 53.2926 59.1421 52.8253C61.1713 50.2264 62.8134 47.3114 63.9837 44.1695C64.1751 43.6533 63.9704 43.0792 63.5031 42.7855L62.0346 41.8643C61.9901 41.8242 61.9412 41.7842 61.8878 41.7486L34.1511 24.3702C34.1021 24.3391 34.0487 24.3168 33.9998 24.2946C33.6304 24.0765 33.1587 24.0632 32.7671 24.3079L12.1059 37.2538C11.7899 35.7807 11.6208 34.2543 11.6208 32.6878C11.6208 20.6542 21.3752 10.8992 33.4079 10.8992C44.3504 10.8992 53.4061 18.9631 54.9592 29.4747C55.0126 29.8307 55.2128 30.1467 55.5154 30.338L64.2107 35.7852C64.9449 36.248 65.9106 35.7852 65.964 34.9174C66.0085 34.2098 66.0352 33.4933 66.0352 32.7768C66.0352 14.7621 51.4259 0.15625 33.4079 0.15625Z" fill="#FF0083" />
                          </svg>
                          <h3 className="cardinnerheading ">{itemArr?.label}.{itemArr?.tld}</h3>
                          <div className="prize_div">
                            <h2 >Highest Bid</h2>
                            <div className="innner_prize">
                              <h3>2797.33 </h3>
                              {/* <img src="\assets\smalltomi.svg" /> */}
                            </div>

                          </div>
                        </div>
                      </div>


                    }
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12 ">
                    <div className="bittingpills">
                      <div className="heading-bid">
                        <h5 className="detailBidH">{itemArr?.label?.substring(0, 12)}{itemArr?.label?.length > 12 && '(...)'}.{itemArr?.tld}</h5>
                        {(itemArr?.minter?.toLowerCase() === account?.toLowerCase()) && <button className="btn-sharee" data-toggle="modal" data-target="#exampleModal"><img src="\assets\share-icon.svg" alt="img" className="img-fluid" />Share</button>}
                      </div>
                      <div className="bid-tabs">
                        <nav className="justify-content-center">
                          <div className="nav nav-tabs border-0 justify-content-center" id="nav-tab">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#nav-Bids"
                            >
                              Bids History
                            </a>
                            <a
                              className="nav-link pl-0"
                              data-toggle="tab"
                              href="#nav-Information"
                            >
                              Information
                            </a>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade show active" id="nav-Bids">
                            <div className={highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                              (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase()) ? "bidhistory" : "bidhistory newmyheight"}>
                              {bidsMade?.map((item) => {
                                return (
                                  <>
                                    <div className="content-history">
                                      <div className="fitcont">
                                        <ul className="list-inline maaiinnoonnee">
                                          <li className="list-inline-item">
                                            <div className="history-heads">
                                              <p className="hours">Bid by</p>
                                              <h3 className="address-title">
                                                {item?.bidder}{" "}
                                              </h3>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <ul className="list-inline">
                                        <li className="list-inline-item shoqatali">
                                          <h4 className="shoqii">{(parseFloat(item?.amount) /
                                            1000000000000000000)?.toString()?.length > 4 ? (parseFloat(item?.amount) /
                                              1000000000000000000)?.toFixed(2) : parseFloat(item?.amount) /
                                          1000000000000000000}{" "} TOMI</h4>
                                          {/* <p className="sectime">= $12.246</p> */}
                                        </li>
                                      </ul>
                                    </div>

                                  </>
                                )

                              })}
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="nav-Information"
                          >
                            <div className="information">
                              <div className={highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                                (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase()) ? "bid-card" : "bid-card myheight"}>
                                <div className="inner-content">
                                  <h3 className="">Contract Address</h3>
                                  <p className="">{Environment.MarketContract}</p>
                                  <h3 className="">Token ID</h3>
                                  <h5 className="text-truncate">{typeParamId} </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lastcardbtn position-sticky">
                        <div className="main-cardsbid">
                          <div className="history-cards2 mycardnewone">
                            <div className="history-content auction-history">
                              {auction === true ? (<>
                                <h5 className="">Auction Ends in</h5>
                                <p className="">
                                  <>
                                    {timeline?.expiryTime && (
                                      <Countdown
                                        date={timerDat}
                                        renderer={renderer}
                                      ></Countdown>
                                    )}
                                  </>
                                </p>
                              </>) : <h5 className="">Auction Ended</h5>}
                            </div>
                            <div className="history-content auction-history">
                              <h5 className="">Highest Bid</h5>
                              <div className="history-inner">
                                <p className="text-lowercase">{((parseFloat(highestBid?.amount) / 1000000000000000000)?.toString()?.length > 6 ? (parseFloat(highestBid?.amount) / 1000000000000000000)?.toFixed(2) : (parseFloat(highestBid?.amount) / 1000000000000000000)) || 0}{" "} tomi</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 p-0">
                            {/* place a bid button show on place a bid component */}
                            {/* jawad auction */}
                            {auction === true ? (
                              <>
                                {/* jawad bided */}
                                {bided === true ? <button
                                  className="btnbtnbid"
                                  data-toggle="modal"
                                  data-target="#increase-bid"
                                >
                                  Increase Bid
                                </button> : <button
                                  className="btnbtnbid"
                                  data-toggle="modal"
                                  data-target="#placebid"
                                >
                                  Place a Bid
                                </button>}
                              </>

                            ) : ''}



                          </div>
                        </div>
                        <div className="withdraw-increase ">
                          {auction === true ? (
                            <>
                              {bided ?
                                auction === true || <button
                                  className="cancelmodal"
                                  data-toggle="modal"
                                  data-target="#withdraw"
                                >
                                  Cancel Bid
                                </button>

                                :
                                ''}
                            </>
                          ) :
                            <>
                              {highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === false ||
                                (highestBid?.bidder === '0x0000000000000000000000000000000000000000' && timeline?.isClaimed === false) && (itemArr?.minter?.toLowerCase() === account?.toLowerCase())
                                ?
                                //  <p className="claimdomainpara">Congrats! You can claim your Domain in a few hours</p>
                                <button
                                  className="withdraw"
                                  onClick={ClaimBid}
                                >
                                  Claim
                                </button>
                                :
                                <>
                                  {highestBid?.bidder?.toUpperCase() === account?.toUpperCase() && timeline?.isClaimed === true ?
                                    null
                                    :
                                    <>
                                      {bided === true && highestBid?.bidder?.toUpperCase() != account?.toUpperCase() && userBid?.totalBalance > 0 ?

                                        null
                                        : null}
                                    </>}
                                </>
                              }
                            </>
                          }
                        </div>

                        {/* {!account &&
                        <button className="button-hedaer w-100 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button>

                      } */}
                        <div className="mydomain-btns w-100 d-none">
                          <Link className="w-100">
                            <button className="putonmarket">
                              Put On Marketplace
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="placebid-modal">
        <div
          className="modal fade"
          id="placebid"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Place a Bid
                </h3>


              </div>
              <div className="modal-body">
                <p className="">
                  You are about to Place a Bid on<span className="px-2">{itemArr?.label}.{itemArr?.tld}</span>
                </p>
                <div className="input-field">
                  <div className="upper-text">
                    <p>Your Bid</p>
                    <p>
                      Bid must be greater than: <span> {minimumBid?.toString()?.length > 6 ? minimumBid?.toFixed(2) : minimumBid} TOMI </span>
                    </p>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Enter Bid"
                    step={0.1}
                    value={price}
                    min={0.0}
                    onChange={(e) => adjustPrice(e.target.value)}
                    type={"number"}
                  />
                  <p className="text-danger fSm">{errors}</p>
                  <span className="input-inner">TOMI</span>
                </div>
                <div className="text-modalbid mt-4">
                  <h4 className="bidhead">Your Balance</h4>
                  <h3 className="head1 ml-2">{balance || 0} TOMI</h3>
                </div>
                <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Total Bid amount</h4>
                  <h3 className="head1 ml-2"> {(TBid || 0)?.toFixed(2)} TOMI</h3>
                </div>
                {error != null && price < minimumBid ?
                  <h3 className="text-center w-100 text-danger pt-2">
                    {error?.split('::')[1]?.split(",")[0]}
                  </h3> :
                  (
                    <>{price > balance ? (
                      account ?
                        <h5 className="text-center w-100 text-danger  pt-2">
                          Insufficient Token Balance
                        </h5>
                        :
                        <button onClick={() => window.$('#placebid').modal('hide')} data-toggle="modal"
                          data-target="#exampleModalwallet"
                          type="button" className="btnmodalbtn">Connect Wallet</button>

                    ) :
                      (
                        <>{price > minimumBid ?
                          (<button
                            className="btnmodalbtn" onClick={BidNft}
                          >
                            Place a Bid
                          </button>) : ''}</>
                      )
                    }
                    </>

                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="accept"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Place a Bid
                </h3>

              </div>
              <div class="modal-body">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p className="set-custom-weight">
                  Your bid has been <br /> placed successfully
                </p>
                <p className='alertWar'>New bid data will take some time to display</p>
                <button onClick={OKay}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="withdraw"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Withdraw Bid
                </h3>

              </div>
              <div class="modal-body">
                <div className="withdraw-body">
                  <p className="withdraw-para">
                    Are you Sure to <br /> Withdraw your Bid
                  </p>
                  <div className="withdraw-btn">
                    <button className="cancel" onClick={closeWithdraw}>Cancel</button>
                    <button className="confirm" onClick={CancelBided} >Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="placebid2-modal modal-dialog-centered">
        <div
          class="modal fade"
          id="cancelmodal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Cancel Bid
                </h3>

              </div>
              <div class="modal-body">
                <div className="withdraw-body">
                  <p className="withdraw-para">
                    Are you Sure to <br /> Cancel your Bid
                  </p>
                  <div className="withdraw-btn">
                    <button className="cancel" onClick={closeCancel}>Cancel</button>
                    <button className="confirm" onClick={CancelBided} >Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid-modal">
        <div
          className="modal fade"
          id="increase-bid"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Increase Bid
                </h3>


              </div>
              <div className="modal-body">
                <div className="input-field">
                  <div className="upper-text">
                    <p>You Bid</p>
                    <p>
                      Bid must be greater than: <span>{((parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100))?.toFixed(3) || 0 + (0 * 15 / 100)}
                        {/* {(userBid?.amount / 1000000000000000000)+((userBid?.amount / 1000000000000000000)*10/100)}  */}
                        {' '} TOMI </span>
                    </p>
                  </div>
                  <input type="number" step={0.1} placeholder="Enter Bid" onChange={(e) => adjustPrice2(e.target.value)} />

                  {/* <input type="number" step={0.1} placeholder="Enter Bid" value={increaseBid} onChange={(e) => adjustPrice2(e.target.value)} /> */}
                  <p className="text-danger fSm">{errors}</p>
                  <span className="input-inner">TOMI</span>
                </div>
                <div className="text-modalbid mt-5">
                  <h4 className="bidhead">Your Balance</h4>
                  <h3 className="head1 ml-2">{balance} TOMI</h3>
                </div>
                {/* <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Service fee</h4>
                  <h3 className="head1 ml-2">0.00 TOMI</h3>
                </div> */}
                <div className="text-modalbid mt-2">
                  <h4 className="bidhead">Total Bid amount</h4>
                  <h3 className="head1 ml-2">{TBid > 0 ? parseFloat(TBid).toFixed(2) : 0} TOMI</h3>
                </div>
                <p className="text-center w-100 text-danger pt-4">
                  {error?.split('::')[1]?.split(",")[0]}
                </p>
                {/* userBid?.amount / 1000000000000000000 */}
                {/* // (i?.amount / 1000000000000000000)   (parseFloat(highestBid?.amount) / 1000000000000000000)+((parseFloat(highestBid?.amount) / 1000000000000000000)*10/100) */}
                {increaseBid > (parseFloat(highestBid?.amount) / 1000000000000000000) + ((parseFloat(highestBid?.amount) / 1000000000000000000) * 15 / 100) && balance > increaseBid ?
                  <button className="btnmodalbtn" onClick={BidNft}>Increase Bid</button>
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="setting-modal">
        <div
          class="modal fade"
          id="setting"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h6>MintING</h6>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="\close-icon.png" alt="img" className="img-fluid" />
                </button>
              </div>
              <div class="modal-body">
                <p className="upper-para">No. of years</p>
                <div className="upper">
                  <div className="left">
                    <button>
                      <img src="\minus.png" alt="img" className="img-fluid" />
                    </button>
                  </div>
                  <div className="mid">1</div>
                  <div className="right">
                    <button>
                      <img src="\plus.png" alt="img" className="img-fluid" />
                    </button>
                  </div>
                </div>
                <div className="mid">
                  <div className="text">
                    <p className="para1">Previous Subscription Period:</p>
                    <p className="para2">1 Year</p>
                  </div>
                  <div className="text">
                    <p className="para1">Subscription Fee</p>
                    <p className="para2">0.01 TOMI</p>
                  </div>
                </div>
                <div className="bottom">
                  <p className="para1">Total Amount</p>
                  <p className="para2">1.123 TOMI</p>
                </div>
                <button className="mint">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          data-backdrop="static" data-keyboard="false"
          class="modal fade"
          id="acceptOkay"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Claimed Successfully
                </h3>
                {/*  */}
              </div>
              <div class="modal-body modal-body2">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  Your Domain has been <br /> claimed successfully
                </p>
                <button onClick={() => window.$('#acceptOkay').modal('hide')}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placebid2-modal modal-dialog-centered">
        <div
          data-backdrop="static" data-keyboard="false"
          class="modal fade"
          id="acceptOkayClaimed"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="exampleModalLabel">
                  Congratulations
                </h3>
              </div>
              <div class="modal-body modal-body2">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  You won the auction !<br /> Please claim within 15 days
                </p>
                <button onClick={() => { window.$('#acceptOkayClaimed').modal('hide'); setCongratsModal(false); setCongrats(false) }}>Okay</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* share modal ..................... */}
      <div className="share-modal-parent">
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Share <svg  data-dismiss="modal" aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M19.281 18.2193C19.3507 18.289 19.406 18.3717 19.4437 18.4628C19.4814 18.5538 19.5008 18.6514 19.5008 18.7499C19.5008 18.8485 19.4814 18.9461 19.4437 19.0371C19.406 19.1281 19.3507 19.2109 19.281 19.2806C19.2114 19.3502 19.1286 19.4055 19.0376 19.4432C18.9465 19.4809 18.849 19.5003 18.7504 19.5003C18.6519 19.5003 18.5543 19.4809 18.4632 19.4432C18.3722 19.4055 18.2895 19.3502 18.2198 19.2806L12.0004 13.0602L5.78104 19.2806C5.64031 19.4213 5.44944 19.5003 5.25042 19.5003C5.05139 19.5003 4.86052 19.4213 4.71979 19.2806C4.57906 19.1398 4.5 18.949 4.5 18.7499C4.5 18.5509 4.57906 18.36 4.71979 18.2193L10.9401 11.9999L4.71979 5.78055C4.57906 5.63982 4.5 5.44895 4.5 5.24993C4.5 5.05091 4.57906 4.86003 4.71979 4.7193C4.86052 4.57857 5.05139 4.49951 5.25042 4.49951C5.44944 4.49951 5.64031 4.57857 5.78104 4.7193L12.0004 10.9396L18.2198 4.7193C18.3605 4.57857 18.5514 4.49951 18.7504 4.49951C18.9494 4.49951 19.1403 4.57857 19.281 4.7193C19.4218 4.86003 19.5008 5.05091 19.5008 5.24993C19.5008 5.44895 19.4218 5.63982 19.281 5.78055L13.0607 11.9999L19.281 18.2193Z" fill="#0E1120"/>
</svg></h5>
                {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img src="\assets\close-icon-new.svg" alt="img" className="img-fluid" /></span>
                </button> */}
              </div>
              <div class="modal-body">
                <div className="parent-img-wrapper">
                  <img src="\assets\sharetdns-modalimg.svg" alt="img" className="img-fluid" />
                  <h6>{itemArr?.label?.substring(0, 12)}{itemArr?.label?.length > 12 && '(...)'}.{itemArr?.tld}</h6>
                </div>
                <div className="bottom-links">
                  <h6>Share with friends</h6>
                  <div className="inner-links">
                    {/* {`Check out my domain ${itemArr?.label}.${itemArr?.tld} on tdns.network`} */}
                    <WhatsappShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img data-toggle="tooltip" data-placement="top" title={''} src="\assets\svgx.svg" alt="img" className="img-fluid" />
                    </WhatsappShareButton>
                    <TwitterShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img src="\assets\svgfb.svg" alt="img" className="img-fluid" />
                    </TwitterShareButton>
                    <TelegramShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img src="\assets\svgacord.svg" alt="img" className="img-fluid" />
                    </TelegramShareButton>
                    <TelegramShareButton url={`https://tdns.network/place/${typeParamId}`} title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}>
                      <img src="\assets\svgm.svg" alt="img" className="img-fluid" />
                    </TelegramShareButton>
                    {/* <a href="#"><img src="\assets\insta.svg" alt="img" className="img-fluid" /></a> */}
                  </div>
                </div>
                <div className="last-part">
                  <h6 className="text-truncate"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_1296_3086)">
    <path d="M7.5 9.74997C7.82209 10.1806 8.23302 10.5369 8.70491 10.7947C9.17681 11.0525 9.69863 11.2058 10.235 11.2442C10.7713 11.2826 11.3097 11.2052 11.8135 11.0173C12.3173 10.8294 12.7748 10.5353 13.155 10.155L15.405 7.90497C16.0881 7.19772 16.4661 6.25046 16.4575 5.26722C16.449 4.28398 16.0546 3.34343 15.3593 2.64815C14.664 1.95287 13.7235 1.55849 12.7403 1.54995C11.757 1.5414 10.8098 1.91938 10.1025 2.60247L8.8125 3.88497" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5006 8.24992C10.1785 7.81933 9.76762 7.46304 9.29573 7.20522C8.82383 6.9474 8.30201 6.79409 7.76565 6.75567C7.22929 6.71726 6.69095 6.79465 6.18713 6.98259C5.68331 7.17053 5.2258 7.46462 4.84564 7.84492L2.59564 10.0949C1.91255 10.8022 1.53457 11.7494 1.54311 12.7327C1.55165 13.7159 1.94604 14.6565 2.64132 15.3517C3.3366 16.047 4.27715 16.4414 5.26038 16.45C6.24362 16.4585 7.19088 16.0805 7.89814 15.3974L9.18064 14.1149" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_1296_3086">
      <rect width="18" height="18" fill="white"/>
    </clipPath>
  </defs>
</svg>https://tdns.network/place/{typeParamId?.slice(0, 8)}...</h6>
                  <a href="#">
                    {!copied ?
                      <CopyToClipboard text={`https://tdns.network/place/${typeParamId}`}
                        onCopy={() => { setCopied('Copied'); settime() }}>
                        <img src="\assets\copy-address.svg" alt="img" className='img-fluid imoo' style={{ cursor: "pointer" }} /></CopyToClipboard> :
                      <p className=" copy text-common">Copied</p>}
                  </a>
                  {/* <img src="\assets\copy-address.svg" alt="img" className="img-fluid" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Place;