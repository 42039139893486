import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./domainSearch.scss";
import ChildOfId from "../../hooks/dataFetchers/childIdOf";
import DomainMinting from "../../hooks/dataSenders/mintDomain";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Countdown from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import axios from "axios";
import { async } from "q";
import { useRef } from "react";
import Cart from "../cart/Cart";
import namer from "@rstacruz/startup-name-generator";
import DomainMintingPrice from "../../hooks/dataSenders/mintPrice";
function DomainSearch({
  domainProp,
  domainName,
  setLoader,
  setMinted,
  minted,
  setCartState,
  cartState,
}) {
  const [inputVal, setInputVal] = useState(domainName);
  const [domainType, setDomainType] = useState(parseInt(domainProp));
  const [show, setShow] = useState(false);
  const { domainMintingMethod } = DomainMinting();
  const [domainAvail, setDomainAvail] = useState(false);
  const [realPrice, setRealPrice] = useState(0);
  const handleClose = () => setShow(false);
  const [sugNameLoad, setSugNameLoad] = useState(false);
  const [show1, setShow1] = useState(false);
  const [mintRes, setMintRes] = useState();
  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [toastDesign] = useState({
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  const [pBid, setPBid] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const { Mainfunct } = ChildOfId();
  let { account } = useWeb3React();
  const [domainAvailMeth, setDomainAvailMeth] = useState();
  const [sugNames, SetSugNames] = useState([]);
  const [filterArray, setFilterArray] = useState(sugNames);
  const [normalDomArr, setNormalDomArr] = useState(sugNames);
  const [showTimer, setShowTimer] = useState(false);
  const [timerTime, setTimerTime] = useState(0);
  const [suggArr, setSugges] = useState();
  const { domainMintingPriceMethod } = DomainMintingPrice();
  const domainTypebyProp = [
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
    "TOMI",
    "COM",
  ];
  const inputRef = useRef(null);
  const [cartArr, setCartArr] = useState([]);
  const [cartStorageData, setCartStorageData] = useState([]);
  // console.log("cartttttt", normalDomArr, realPrice);
  const getSearchData = async (domain, type) => {
    if (!domain || !type) {
      return false;
    }

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
          auctionCreateds(orderBy: startTime, orderDirection: desc, where: {label: "${domain}", tld: "${type}"}) {
            expiryTime
            id
          }
        }`,
        variables: {},
      };

      const response = await axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const minted = response?.data?.data?.auctionCreateds;
      // setLoader(false);

      if (minted?.length > 0) {
        return minted;
      } else {
        return false;
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const domainSearchFunc = async () => {
    try {
      setDomainAvail(false);
      const res = await Mainfunct(inputVal, domainTypebyProp[domainType].toLocaleLowerCase());
      setDomainAvailMeth(res);
      setDomainAvail(res);
    } catch (error) {
      setDomainAvail(false);
      toast.error("Domain Unavailable", toastDesign);
    } finally {
      setLoader(false);
    }
  };

  const mintdom = async () => {
    try {
      setShow(false);
      setShow1(true);

      const isMinted = await domainMintingMethod(inputVal, true);
      if (isMinted) {
        setTimerTime(65000);
        setShowTimer(true);
        const mintRes = await domainMintingMethod(inputVal, false);

        toast.success("Mint Successful", toastDesign);
        toast.info("Minted Domains take time to show", toastDesign);

        setShow1(false);
        setShow2(true);
        setMinted(!minted);
        setMintRes(mintRes);
      }
    } catch (error) {
      toast.error("Minting Failed", toastDesign);
      setShow(false);
      setShow1(false);
      setShow2(false);
    }
  };

  const inputVAlue = (e) => {
    const value = e.replace(/\s/g, "");
    const pattern = /^[A-Za-z0-9-]*$/;

    if (pattern.test(value)) {
      const lowercaseValue = value.toLowerCase();
      setInputVal(lowercaseValue);
      // Call other functions or perform additional actions if needed
      // domainSearchFunc();
    }
  };
  // const suggestedDomains = async () => {
  //   if (inputVal.endsWith("-")) {
  //     toast.info("Domain Should Not End With Special Character", toastDesign);
  //   } else if (inputVal.length < 3) {
  //     toast.info("Character Length should be greater than 2", toastDesign);
  //   } else {
  //     try {
  //       const res2 = [inputVal, inputVal, ...(namer(inputVal)?.slice(0, 10))];
  //       setSugges(res2)
  //       setSugNameLoad(true);
  //       setLoader(true);

  //       const requests = domainTypebyProp.map((domainType, id) =>
  //         Promise.all([
  //           Mainfunct(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //           getSearchData(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //         ])
  //       );
  //       setLoader(true);
  //       const results = await Promise.all(requests);

  //       const dumArray = results.map(([res]) => res);
  //       const dumArray2 = results.map(([, res2]) => res2);
  //       console.log('dumArray', dumArray);
  //       setPBid(dumArray2);
  //       SetSugNames(dumArray);
  //       setFilterArray(dumArray);
  //       setNormalDomArr(dumArray);
  //       setLoader(false);
  //     } catch (error) {
  //       console.error("Error occurred:", error);
  //       setLoader(false);
  //     } finally {
  //       setSugNameLoad(false);
  //     }
  //   }
  // };
  const suggestedDomains = async () => {
    if (inputVal.endsWith("-")) {
      toast.info("Domain Should Not End With Special Character", toastDesign);
    } else if (inputVal.length < 3) {
      toast.info("Character Length should be greater than 2", toastDesign);
    } else {
      try {
        const res2 = [inputVal, inputVal, ...namer(inputVal)?.slice(0, 10)];
        setSugges(res2);
        setSugNameLoad(true);
        setLoader(true);

        const requests = domainTypebyProp.map((domainType, id) =>
          Promise.all([
            Mainfunct(res2[id]?.toLowerCase(), domainType.toLowerCase()),
            getSearchData(res2[id]?.toLowerCase(), domainType.toLowerCase()),
          ])
        );
        setLoader(true);
        const results = await Promise.all(requests);

        const dumArray = results.map(([res]) => res);
        const dumArray2 = results.map(([, res2]) => res2);
        console.log("dumArray", dumArray);
        setPBid(dumArray2);
        SetSugNames(dumArray);
        setFilterArray(dumArray);
        setNormalDomArr(dumArray);
        setLoader(false);

        // Combine `notAvaliable` function call
        const availabilityArray = dumArray.map(async (item, id) => {
          const domainType = domainTypebyProp[id]?.toLowerCase();
          const suggestion = res2[id]?.toLowerCase(); // Use res2 here since suggArr might not be defined at this point
          return await notAvaliable(domainType, suggestion);
        });

        // Store availabilityArray in state or variable as needed
        setAvailabilityArray(availabilityArray);
      } catch (error) {
        console.error("Error occurred:", error);
        setLoader(false);
      } finally {
        setSugNameLoad(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      suggestedDomains();
    }
  };
  const cartFun = async (id) => {
    // console.log('ddddddddddd', id)
  };
  // console.log('cartArr', cartArr, cartState)
  const addToCart = async (name, tld) => {
    let domain = { name, tld };
    // setCartArr((pre) => {
    //   return [...pre, domain]

    // })
    const res = await domainMintingPriceMethod(name, tld);
    let a = parseInt(res);
    let b = parseFloat(a * realPrice).toFixed(3);
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "USD",
          value: "100",
          items: [
            {
              item_name: { domain },
              USD: "$100",
              quantity: 1,
            },
          ],
        },
      });
      console.log("cartttttt1111eve", window.dataLayer);
      console.log(`GTM event 'Add to cart' triggered `);
    } else {
      console.error("Google Tag Manager dataLayer is not available");
    }

    setCartArr([domain]);
  };
  const mergeArrays = (arr1, arr2) => {
    // Combine both arrays into a single array
    const combinedArray = [...arr1, ...arr2];

    // Create an object to track unique objects based on name and tld
    const uniqueObjects = {};

    // Filter out duplicates and keep only the first occurrence of each unique combination
    const mergedArray = combinedArray.filter((obj) => {
      const key = obj.name + obj.tld;
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        return true;
      }
      return false;
    });

    return mergedArray;
  };
  const removeItem = async (lowerCaseSuggArr, domainType) => {
    let res = cartStorageData?.filter((item) => {
      return `${item?.name}${item?.tld}` !== `${lowerCaseSuggArr}${domainType}`;
    });
    localStorage.setItem("cart", JSON.stringify(res));
    setCartState(!cartState);
    // console.log('filter', res)
  };

  const Price = async(() => {
    axios
      .get(
        "https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false"
      )
      .then((res) => {
        setRealPrice(res?.data?.tominet?.usd);
        // setEthUsd(res.data.USD);
      });
  });
  useEffect(() => {
    if (cartArr?.length !== 0) {
      let cartArrSt = localStorage.getItem("cart");
      cartArrSt = cartArrSt ? JSON.parse(cartArrSt) : []; // Parse only if cartArrSt is not null

      const mergedArray = mergeArrays(cartArrSt, cartArr);
      localStorage.setItem("cart", JSON.stringify(mergedArray));
      setCartState(!cartState);
    }
  }, [cartArr]);
  let notAvaliable = async function (tld, name) {
    console.log(name, tld, "availability");

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
         auctionCreateds(where: {label: "${name}", tld: "${tld}"}) {
    expiryTime
  }
        }`,
        variables: {},
      };

      const response = await axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const expiryTime = response?.data?.data?.auctionCreateds[0]?.expiryTime; // 1715773475
      const currentTime = Math.floor(Date.now() / 1000);
      setLoader(false);
      console.log(expiryTime < currentTime, expiryTime, currentTime, "availability");
      return expiryTime < currentTime;
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  useEffect(() => {
    Price();
    if (inputVal) {
      domainSearchFunc();
      suggestedDomains();
    }
  }, [domainType, mintRes, account]);
  useEffect(() => {
    let cartArr = localStorage.getItem("cart");
    cartArr = JSON.parse(cartArr);
    setCartStorageData(cartArr);
  }, [cartState !== undefined && cartState]);
  console.log("cartStorageData", normalDomArr);
  return (
    <>
      <section className="domain-search">
        <h1>
          Search <span className="domainss">Domains</span>
        </h1>
        <div className="input-field">
          <div className="mysearchinput">
            <img src="\assets\search-normal.svg" alt="img" className="img-fluid  input-icon" />
            <input
              onKeyDown={handleKeyDown}
              ref={inputRef}
              onChange={(e) => {
                inputVAlue(e.target.value?.split(".")[0]);
                setDomainAvail(false);
                setNormalDomArr([]);
              }}
              value={inputVal}
              type="text"
              placeholder="domain.tomi"
            />
            <img
              onClick={() => setInputVal("")}
              src="\assets\crosssssss.svg"
              className="img-fluid crosImg2 "
              alt=""
            />
          </div>
          <button
            disabled={domainType < 0 || inputVal == ""}
            onClick={() => {
              suggestedDomains();
            }}
            class="btn-searchbar"
            type="button"
          >
            <img
              src="\assets\newlanding-assets\search-icon-mbl.svg"
              alt="img"
              className="img-fluid d-none showsearch-icon"
            />
            <span>Search</span>
          </button>
        </div>
        <div className="top_domainss">
          {console.log(normalDomArr, domainTypebyProp, suggArr, "fafafasfasfdas")}
          {inputVal &&
            normalDomArr?.map((item, id) => {
              const isAuction = !item;

              const domainType = domainTypebyProp[id]?.toLocaleLowerCase();
              const lowerCaseSuggArr = suggArr[id]?.toLowerCase();
              const linkToPath = `/progressbar/${domainType}/${lowerCaseSuggArr}`;
              const availability = availabilityArray[id]; // Get availability from domain
              // console.log("availability", availability);
              const stored = cartStorageData?.some(
                (item) => item?.name === lowerCaseSuggArr && item?.tld === domainType
              );
              return (
                <div className="custom_cardss_mainss">

                  <div className={`availableDomain${isAuction ? " mb-0 pb-0" : ""}`} key={id}>
                    <svg className="tommi_newsvg" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                      <path d="M15.1826 0.625C7.17462 0.625 0.683594 7.11647 0.683594 15.125C0.683594 18.4914 1.8307 21.5908 3.75507 24.0513C3.91724 24.259 4.21193 24.3044 4.43542 24.164L7.65324 22.1406C7.68687 22.1189 7.71653 22.0951 7.74422 22.0674L12.3722 19.1678V28.9189C12.3722 29.1701 12.5502 29.3896 12.7994 29.4292C13.5747 29.5578 14.3697 29.625 15.1826 29.625C15.9955 29.625 16.8538 29.5538 17.6588 29.4134C17.906 29.3699 18.082 29.1543 18.082 28.9031V19.2232L22.6783 22.103C22.6922 22.1129 22.7041 22.1248 22.7179 22.1347L25.9417 24.1482C26.1651 24.2886 26.4579 24.2412 26.62 24.0335C27.5219 22.8784 28.2517 21.5829 28.7718 20.1865C28.8569 19.957 28.7659 19.7019 28.5582 19.5713L27.9056 19.1619C27.8858 19.1441 27.864 19.1263 27.8403 19.1105L15.5129 11.3868C15.4911 11.3729 15.4674 11.363 15.4456 11.3531C15.2815 11.2562 15.0718 11.2503 14.8978 11.3591L5.71503 17.1128C5.57461 16.4581 5.49946 15.7797 5.49946 15.0835C5.49946 9.73522 9.83472 5.39966 15.1826 5.39966C20.0459 5.39966 24.0707 8.98361 24.7609 13.6554C24.7847 13.8137 24.8737 13.9541 25.0082 14.0391L28.8727 16.4601C29.199 16.6658 29.6282 16.4601 29.652 16.0744C29.6717 15.7599 29.6836 15.4415 29.6836 15.123C29.6836 7.11648 23.1906 0.625 15.1826 0.625Z" fill="#FF0083" />
                    </svg>
                    {/* <div className="row"> */}
                    {/* <div className="col-sm-9 my-auto"> */}
                    <div className=" domainMain">
                      <p>
                        <span>{lowerCaseSuggArr}</span>.{domainType}
                      </p>
                      <div
                        className={` availtick ${isAuction ? (availability ? " bg-danger" : " bg-warning") : ""
                          }`}
                      >
                        <h5 className={isAuction ? (availability ? " reed" : " heee") : ""}>
                          {isAuction ? (availability ? "Unavailable" : "In Auction") : "Available"}
                        </h5>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-sm-3"> */}
                    <div className="d-flex  domainRightHeartDiv">
                      {isAuction ? (
                        availability ? null : (
                          <Link
                            to={`/place/${pBid[id][0]?.id}`}
                            className="cartButton text-center w-50 cartButton2"
                          >
                            Place a bid
                          </Link>
                        )
                      ) : (
                        <>
                          {stored ? (
                          <div
                          onClick={() => removeItem(lowerCaseSuggArr, domainType)}
                          className="add-cart-btn cPointer svg-hover myyysvgdivnew"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
  <path d="M7.61589 20.1667C8.12215 20.1667 8.53255 19.7563 8.53255 19.25C8.53255 18.7438 8.12215 18.3334 7.61589 18.3334C7.10962 18.3334 6.69922 18.7438 6.69922 19.25C6.69922 19.7563 7.10962 20.1667 7.61589 20.1667Z" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.6979 20.1667C18.2042 20.1667 18.6146 19.7563 18.6146 19.25C18.6146 18.7438 18.2042 18.3334 17.6979 18.3334C17.1917 18.3334 16.7812 18.7438 16.7812 19.25C16.7812 19.7563 17.1917 20.1667 17.6979 20.1667Z" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.16016 1.87915H3.99349L6.43182 13.2641C6.52127 13.6811 6.75327 14.0538 7.08789 14.3182C7.42251 14.5825 7.83882 14.722 8.26516 14.7125H17.2302C17.6474 14.7118 18.0519 14.5688 18.3769 14.3072C18.7019 14.0455 18.928 13.6808 19.0177 13.2733L20.5302 6.46248H4.97432" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        </div>
                          ) : (
                            <div
                              onClick={() => addToCart(lowerCaseSuggArr, domainType)}
                              className="add-cart-btn cPointer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                <path d="M7.61589 20.1667C8.12215 20.1667 8.53255 19.7563 8.53255 19.25C8.53255 18.7438 8.12215 18.3334 7.61589 18.3334C7.10962 18.3334 6.69922 18.7438 6.69922 19.25C6.69922 19.7563 7.10962 20.1667 7.61589 20.1667Z" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.6979 20.1667C18.2042 20.1667 18.6146 19.7563 18.6146 19.25C18.6146 18.7438 18.2042 18.3334 17.6979 18.3334C17.1917 18.3334 16.7812 18.7438 16.7812 19.25C16.7812 19.7563 17.1917 20.1667 17.6979 20.1667Z" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.16016 1.87915H3.99349L6.43182 13.2641C6.52127 13.6811 6.75327 14.0538 7.08789 14.3182C7.42251 14.5825 7.83882 14.722 8.26516 14.7125H17.2302C17.6474 14.7118 18.0519 14.5688 18.3769 14.3072C18.7019 14.0455 18.928 13.6808 19.0177 13.2733L20.5302 6.46248H4.97432" stroke="#FF0083" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div>
                          )}
                          {/* <Cart path="\cart\addcart.svg" bool={false} onClick={() => cartFun(pBid[id][0]?.id)} /> */}
                          <Link to={linkToPath} className="cartButton text-center w-50">
                            {/* {true ? "Buy Now" : "Already Registered"} */}
                            Buy Now
                          </Link>
                        </>
                      )}
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="botttoms_cards">
                    <div>
                      <p>Highest Bid</p>
                      <h1>
                        {isAuction
                          ? availability
                            ? "-"
                            : "-"
                          : `${parseInt(100 / realPrice).toFixed(2)} tomi`}
                        {/* {availability ? "-" : `${parseInt(100 / realPrice).toFixed(2)} tomi`}{" "} */}
                      </h1>
                    </div>
                    <div>
                      <p>Estimated value </p>

                      <h1>{isAuction ? (availability ? "-" : "-") : "100 USDT"} </h1>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      <section className="modal-mint1">
        <Modal show={show} onHide={handleClose} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Register & Mint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a className="register-btn" onClick={mintdom}>
              Register & Mint
            </a>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show1} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Minting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\laoding.png" alt="img" className="img-fluid rotate" />
              <p>Your NFT is minting</p>
              {showTimer ? (
                <p>
                  Estimate Time{" "}
                  <span className="common">
                    {" "}
                    <Countdown date={Date.now() + parseInt(timerTime)} />
                  </span>{" "}
                  Minute
                </p>
              ) : (
                <p>
                  Request To <span className="common">Register</span>{" "}
                </p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show2} onHide={handleClose2} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>MintING</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\tick-circle.png" alt="img" className="img-fluid" />
              <p>Your NFT is minted successfully</p>
              <h6>And placed on marketplace for an auction for 24h period</h6>
              <Link
                to="/domainSearhResult/active/mm"
                onClick={() => {
                  handleClose2();
                  handleClose();
                }}
              >
                <a className="register-btn"> Go Checkout My Domain</a>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default DomainSearch;
