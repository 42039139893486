import React, { useEffect, useState } from "react";
import "./live.scss";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import CheckExpire from "../../../hooks/dataFetchers/checkExpiry";
import Environment from "../../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { Link } from "react-router-dom";
import Market from "../../Marketplace/Market";
const LiveAuction = () => {
  const [expTime, setExpTime] = useState([]);
  const [allNFTS, setAllNFTS] = useState([]);
  const [loader, setLoader] = useState(false);
  // console.log('asfkd;a;', allNFTS)
  const { account } = useWeb3React();
  const { CheckExpiry } = CheckExpire();
  // const expTimeFun = (arr) => {
  //     console.log('adlfjalsdfjlasfd',arr)
  //     setLoader(true);
  //     var data = JSON.stringify({
  //         query: `query MyQuery {
  //             auctionCreateds(
  //               orderBy: mintAmount
  //               orderDirection: asc
  //               where: {tokenId_in: ${JSON.stringify(arr)}}
  //             ) {
  //               expiryTime
  //               label
  //               tokenId
  //             }
  //           }`,
  //         variables: {},
  //     });

  //     var config = {
  //         method: "post",
  //         url: Environment.marketplaceGraph,
  //         headers: {
  //             "Content-Type": "application/json",
  //         },
  //         data: data,
  //     };

  //     axios(config)
  //         .then(async (response) => {
  //             setLoader(true);
  //             var dumAuction = [];
  //             let minted = response?.data?.data?.auctionCreateds;

  //             for (let nft of minted) {
  //                 let dat = new Date(0);
  //                 dat.setUTCSeconds(parseFloat(nft?.expiryTime));
  //                 dumAuction.push(dat)
  //             }
  //             setExpTime(dumAuction);
  //             setLoader(false);
  //         })
  //         .catch((err) => {
  //             console.log('sdf', err)
  //             setLoader(false);
  //         });
  //     setLoader(true);
  // };
  const OnAuction = () => {
    setLoader(true);
    var data = JSON.stringify({
      query: `query MyQuery {
                bidCreateds(first: 12, orderBy: bidTime, orderDirection: desc) {
                  amount
                  label
                  tokenId
                  tld
                }
              }`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        setLoader(true);
        var dumAuction = [];
        let minted = response?.data?.data?.bidCreateds;
        const uniquePairs = new Set();
        const uniqueData = [];

        minted.forEach((obj) => {
          const pair = obj.label + obj.tld;

          if (!uniquePairs.has(pair) && uniqueData.length < 8) {
            uniquePairs.add(pair);
            uniqueData.push(obj);
          }
        });
        for (let nft of uniqueData) {
          let resauctioned;
          try {
            resauctioned = await CheckExpiry(nft?.tokenId);
          } catch (error) { }

          let dat = new Date(0);
          dat.setUTCSeconds(parseFloat(resauctioned?.expiryTime));
          let dateVal = null;
          if (dat) {
            const date0 = new Date();
            if (dat > date0) {
              dumAuction.push(nft);
            }
          }
        }

        console.log(dumAuction);
        setAllNFTS(dumAuction);
        // let dumArr=[]
        // for (let nft of minted) {
        //     dumArr.push(nft?.tokenId?.toString())
        // }
        // console.log('dummarr',dumArr)
        setLoader(false);
      })
      .catch((err) => {
        // console.log('sdf', err)
        setLoader(false);
      });
    setLoader(true);
  };
  function formatAmount(amount) {
    return (parseInt(amount) / 1000000000000000000)?.toFixed(2);
  }

  function displayAmount(amount) {
    const parsedAmount = formatAmount(amount);
    if (parsedAmount && parsedAmount >= 10000000) {
      return `${parsedAmount?.toString()?.slice(0, 9)}...`;
    }
    return parsedAmount;
  }
  useEffect(() => {
    OnAuction();
  }, [account]);
  let metaData = [0, 1];
  const display = allNFTS?.map((elem, id) => {
    return (
      <div className="myydivnew">
        <img src="\assets\bgsection.svg" className="eliiiip"></img>
        <div className="custom-containerl">
          <div>
            <div className="custom-cards cardnnn">
              <Link to={{ pathname: `/place/` + elem.tokenId }}>
                <div class="cardn">
                  <div class="card-style-2">
                    <h5 class="card-heading-1">Highest Bid</h5>
                    <p class="card-heading-2 text-lowercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="50" height="50" rx="25" fill="white" fill-opacity="0.05" />
                        <path
                          d="M24.5937 16.1777C29.14 16.1777 32.8461 19.7728 33.0172 24.2736L37.0055 26.7205C37.1246 26.0251 37.1864 25.3109 37.1864 24.5815C37.1864 17.6329 31.5489 12 24.5928 12C17.6366 12 12 17.6338 12 24.5824C12 26.0868 12.2643 27.5304 12.7498 28.8666C13.23 30.192 13.9269 31.4128 14.7977 32.4885L18.3784 30.2914L22.5073 27.7577V37.3966H26.5708V27.6799L30.8162 30.2851L34.3959 32.4823C35.2658 31.4065 35.9618 30.1849 36.4411 28.8585L32.7825 26.6131L28.5775 24.0329L24.6026 21.5941L24.5937 21.5994L20.6278 24.0329L16.4075 26.622C16.2472 25.9741 16.1621 25.2984 16.1621 24.6012C16.1621 19.9491 19.9371 16.1786 24.5928 16.1786L24.5937 16.1777ZM24.5937 26.4663L24.6017 26.4708L24.5937 26.4761V26.4654V26.4663Z"
                          fill="#FF0083"
                        />
                      </svg>
                      {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                      <span className="text-truncate">{displayAmount(elem?.amount) || "0"}</span>
                    </p>
                  </div>
                  <div className="card-img-top-div ">
                    <span className=" w-100 text-left set-text-font">
                      {elem?.label?.substring(0, 13)}
                      {elem?.label?.length > 13 && "(...)"}.{elem?.tld}
                    </span>
                  </div>
                  <div class="card-body-1">
                    <div class="card-style-2">
                      {/* {elem?.Auction === true && ( */}
                      <>
                        {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                        <p class="card-heading-6 justify-content-start">
                          {/* <h5 className="endedspan">Ended</h5> */}
                          {true && (
                            <Countdown
                              date={"Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)"}
                              renderer={({ days, hours, minutes, seconds }) => {
                                return (
                                  <>
                                    <h5 class="card-heading-6">
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {days < 10 && 0}
                                          {days}
                                        </span>
                                        <p className="timerpara">DAYS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {hours < 10 && 0}
                                          {hours}
                                        </span>
                                        <p className="timerpara">HOURS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {minutes < 10 && 0}
                                          {minutes}
                                        </span>
                                        <p className="timerpara">MINUTES</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {seconds < 10 && 0}
                                          {seconds}
                                        </span>
                                        <p className="timerpara">SECONDS</p>
                                      </div>
                                    </h5>
                                  </>
                                );
                              }}
                            ></Countdown>
                          )}
                        </p>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={{ pathname: `/place/` + elem.tokenId }}>
                <div class="cardn">
                  <div class="card-style-2">
                    <h5 class="card-heading-1">Highest Bid</h5>
                    <p class="card-heading-2 text-lowercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="50" height="50" rx="25" fill="white" fill-opacity="0.05" />
                        <path
                          d="M24.5937 16.1777C29.14 16.1777 32.8461 19.7728 33.0172 24.2736L37.0055 26.7205C37.1246 26.0251 37.1864 25.3109 37.1864 24.5815C37.1864 17.6329 31.5489 12 24.5928 12C17.6366 12 12 17.6338 12 24.5824C12 26.0868 12.2643 27.5304 12.7498 28.8666C13.23 30.192 13.9269 31.4128 14.7977 32.4885L18.3784 30.2914L22.5073 27.7577V37.3966H26.5708V27.6799L30.8162 30.2851L34.3959 32.4823C35.2658 31.4065 35.9618 30.1849 36.4411 28.8585L32.7825 26.6131L28.5775 24.0329L24.6026 21.5941L24.5937 21.5994L20.6278 24.0329L16.4075 26.622C16.2472 25.9741 16.1621 25.2984 16.1621 24.6012C16.1621 19.9491 19.9371 16.1786 24.5928 16.1786L24.5937 16.1777ZM24.5937 26.4663L24.6017 26.4708L24.5937 26.4761V26.4654V26.4663Z"
                          fill="#FF0083"
                        />
                      </svg>
                      {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                      <span className="text-truncate">{displayAmount(elem?.amount) || "0"}</span>
                    </p>
                  </div>
                  <div className="card-img-top-div ">
                    <span className=" w-100 text-left set-text-font">
                      {elem?.label?.substring(0, 13)}
                      {elem?.label?.length > 13 && "(...)"}.{elem?.tld}
                    </span>
                  </div>
                  <div class="card-body-1">
                    <div class="card-style-2">
                      {/* {elem?.Auction === true && ( */}
                      <>
                        {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                        <p class="card-heading-6 justify-content-start">
                          {/* <h5 className="endedspan">Ended</h5> */}
                          {true && (
                            <Countdown
                              date={"Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)"}
                              renderer={({ days, hours, minutes, seconds }) => {
                                return (
                                  <>
                                    <h5 class="card-heading-6">
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {days < 10 && 0}
                                          {days}
                                        </span>
                                        <p className="timerpara">DAYS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {hours < 10 && 0}
                                          {hours}
                                        </span>
                                        <p className="timerpara">HOURS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {minutes < 10 && 0}
                                          {minutes}
                                        </span>
                                        <p className="timerpara">MINUTES</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {seconds < 10 && 0}
                                          {seconds}
                                        </span>
                                        <p className="timerpara">SECONDS</p>
                                      </div>
                                    </h5>
                                  </>
                                );
                              }}
                            ></Countdown>
                          )}
                        </p>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={{ pathname: `/place/` + elem.tokenId }}>
                <div class="cardn">
                  <div class="card-style-2">
                    <h5 class="card-heading-1">Highest Bid</h5>
                    <p class="card-heading-2 text-lowercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="50" height="50" rx="25" fill="white" fill-opacity="0.05" />
                        <path
                          d="M24.5937 16.1777C29.14 16.1777 32.8461 19.7728 33.0172 24.2736L37.0055 26.7205C37.1246 26.0251 37.1864 25.3109 37.1864 24.5815C37.1864 17.6329 31.5489 12 24.5928 12C17.6366 12 12 17.6338 12 24.5824C12 26.0868 12.2643 27.5304 12.7498 28.8666C13.23 30.192 13.9269 31.4128 14.7977 32.4885L18.3784 30.2914L22.5073 27.7577V37.3966H26.5708V27.6799L30.8162 30.2851L34.3959 32.4823C35.2658 31.4065 35.9618 30.1849 36.4411 28.8585L32.7825 26.6131L28.5775 24.0329L24.6026 21.5941L24.5937 21.5994L20.6278 24.0329L16.4075 26.622C16.2472 25.9741 16.1621 25.2984 16.1621 24.6012C16.1621 19.9491 19.9371 16.1786 24.5928 16.1786L24.5937 16.1777ZM24.5937 26.4663L24.6017 26.4708L24.5937 26.4761V26.4654V26.4663Z"
                          fill="#FF0083"
                        />
                      </svg>
                      {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                      <span className="text-truncate">{displayAmount(elem?.amount) || "0"}</span>
                    </p>
                  </div>
                  <div className="card-img-top-div ">
                    <span className=" w-100 text-left set-text-font">
                      {elem?.label?.substring(0, 13)}
                      {elem?.label?.length > 13 && "(...)"}.{elem?.tld}
                    </span>
                  </div>
                  <div class="card-body-1">
                    <div class="card-style-2">
                      {/* {elem?.Auction === true && ( */}
                      <>
                        {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                        <p class="card-heading-6 justify-content-start">
                          {/* <h5 className="endedspan">Ended</h5> */}
                          {true && (
                            <Countdown
                              date={"Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)"}
                              renderer={({ days, hours, minutes, seconds }) => {
                                return (
                                  <>
                                    <h5 class="card-heading-6">
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {days < 10 && 0}
                                          {days}
                                        </span>
                                        <p className="timerpara">DAYS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {hours < 10 && 0}
                                          {hours}
                                        </span>
                                        <p className="timerpara">HOURS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {minutes < 10 && 0}
                                          {minutes}
                                        </span>
                                        <p className="timerpara">MINUTES</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {seconds < 10 && 0}
                                          {seconds}
                                        </span>
                                        <p className="timerpara">SECONDS</p>
                                      </div>
                                    </h5>
                                  </>
                                );
                              }}
                            ></Countdown>
                          )}
                        </p>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={{ pathname: `/place/` + elem.tokenId }}>
                <div class="cardn">
                  <div class="card-style-2">
                    <h5 class="card-heading-1">Highest Bid</h5>
                    <p class="card-heading-2 text-lowercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="50" height="50" rx="25" fill="white" fill-opacity="0.05" />
                        <path
                          d="M24.5937 16.1777C29.14 16.1777 32.8461 19.7728 33.0172 24.2736L37.0055 26.7205C37.1246 26.0251 37.1864 25.3109 37.1864 24.5815C37.1864 17.6329 31.5489 12 24.5928 12C17.6366 12 12 17.6338 12 24.5824C12 26.0868 12.2643 27.5304 12.7498 28.8666C13.23 30.192 13.9269 31.4128 14.7977 32.4885L18.3784 30.2914L22.5073 27.7577V37.3966H26.5708V27.6799L30.8162 30.2851L34.3959 32.4823C35.2658 31.4065 35.9618 30.1849 36.4411 28.8585L32.7825 26.6131L28.5775 24.0329L24.6026 21.5941L24.5937 21.5994L20.6278 24.0329L16.4075 26.622C16.2472 25.9741 16.1621 25.2984 16.1621 24.6012C16.1621 19.9491 19.9371 16.1786 24.5928 16.1786L24.5937 16.1777ZM24.5937 26.4663L24.6017 26.4708L24.5937 26.4761V26.4654V26.4663Z"
                          fill="#FF0083"
                        />
                      </svg>
                      {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                      <span className="text-truncate">{displayAmount(elem?.amount) || "0"}</span>
                    </p>
                  </div>
                  <div className="card-img-top-div ">
                    <span className=" w-100 text-left set-text-font">
                      {elem?.label?.substring(0, 13)}
                      {elem?.label?.length > 13 && "(...)"}.{elem?.tld}
                    </span>
                  </div>
                  <div class="card-body-1">
                    <div class="card-style-2">
                      {/* {elem?.Auction === true && ( */}
                      <>
                        {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                        <p class="card-heading-6 justify-content-start">
                          {/* <h5 className="endedspan">Ended</h5> */}
                          {true && (
                            <Countdown
                              date={"Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)"}
                              renderer={({ days, hours, minutes, seconds }) => {
                                return (
                                  <>
                                    <h5 class="card-heading-6">
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {days < 10 && 0}
                                          {days}
                                        </span>
                                        <p className="timerpara">DAYS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {hours < 10 && 0}
                                          {hours}
                                        </span>
                                        <p className="timerpara">HOURS</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {minutes < 10 && 0}
                                          {minutes}
                                        </span>
                                        <p className="timerpara">MINUTES</p>
                                      </div>
                                      <div className="timermain">
                                        <span className="timerinner">
                                          {seconds < 10 && 0}
                                          {seconds}
                                        </span>
                                        <p className="timerpara">SECONDS</p>
                                      </div>
                                    </h5>
                                  </>
                                );
                              }}
                            ></Countdown>
                          )}
                        </p>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <button className="viewbutton">
              View More{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 5.92969L8.5 7.42969L13.0703 12L8.5 16.5703L10 18.0703L16.0703 12L10 5.92969Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="liveAuc">
        <img src="\assets\auctionbg.png" alt="img" className="img-fluid banner-bg11" />

        <section className="wrappper-market liveauction market">
          <div className="top-heading flex-wrap ml-3">
            <p>//Domains bank </p>
            <h2>
              Hot Auctions{" "}
            </h2>
            <p className="lowertext">
              Offer 15% more $TOMI tokens than the highest bid to claim trending domain names. If
              you get outbid, you’ll get your tokens back plus 20% of the difference between your
              bid and the price that topped you.
            </p>
          </div>
          {display.length > 0 ? (
            <div class="cardsssy">{display ? display : ""}</div>
          ) : (
            loader || (
              <div className="texttt_container">
                <img src="\assets\bglive.png" className="bgliveimg"/>
                <p className="auctionendtext text-center">
                  There are currently no auctions available.<br></br> Keep an eye on the auction page
                  to be the first to discover new listings
                </p>
              </div>

            )
          )}
          {loader && (
            <div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
              <img
                width={35}
                style={
                  {
                    // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                  }
                }
                src="\loader\loader.gif"
                alt="loader"
              />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default LiveAuction;

// {allNFTS?.map((elem) => {
//     // console.log('dsfjlasjfl', elem)
//     return (

//         <div className="col-xl-3 col-lg-4 col-md-6 col-12">
//             <Link to={{ pathname: `/place/` + elem.tokenId }}>
//                 <div class="card">
//                     <div className="card-img-top-div">
//                         {elem?.label?.substring(0, 37)}{elem?.label?.length > 37 && '(...)'}.{elem?.tld}
//                     </div>

//                     {/* <div class="card-body-1">
//                         <div class="card-style-2">
//                             {elem?.Auction === false && <>
//                                 <h5 class="card-heading-1">Auction </h5>
//                                 <h5 class="card-heading-1 font-weight-bold text-dark">Ended </h5>
//                             </>}

//                             {elem?.Auction === true && (
//                                 <>
//                                     <h5 class="card-heading-1">Auction Ends</h5>
//                                     <p class="card-heading-2 justify-content-start">
//                                         {elem?.Timeline?.expiryTime && (
//                                             <Countdown
//                                                 date={elem?.expiryTime}
//                                                 renderer={({ days, hours, minutes, seconds }) => {
//                                                     return (
//                                                         <>
//                                                             <h5 class="card-heading-4">
//                                                                 {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
//                                                             </h5>
//                                                         </>
//                                                     );
//                                                 }}
//                                             ></Countdown>
//                                         )}
//                                     </p>
//                                 </>)}
//                         </div>
//                         <div class="card-style-2">
//                             <h5 class="card-heading-1">Highest Bid</h5>
//                             <p class="card-heading-2 text-lowercase">
//                                 <img
//                                     class="img-fluid mr-1"
//                                     src="\assets\eth-icon.svg"
//                                     alt="img"
//                                 />
//                                 {elem?.HighestBid
//                                     ? parseInt(elem?.HighestBid).toFixed(4) /
//                                     1000000000000000000 || 70
//                                     : "70"}{" "}

//                             </p>
//                         </div>
//                     </div> */}
//                 </div>
//             </Link>
//         </div>

//     )
// })}
