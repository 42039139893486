import React, { useEffect } from "react";
import Navbar from "../landing/header/Navbar";
import "./domainprofile.scss";
// import { useParams } from "react-router-dom";
const DomainProfile1 = () => {
  // const { data } = useParams()
  // const UrlData = JSON.parse(data);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Navbar />
      <section className="domainprofile domainprofile1">
        <div className="container-fluid p-0">
          <div className="cover">
            {/* <img src="" alt="img" className="img-fluid w-100" /> */}
            <a className="share-btn"><img src="\assets\profile\Share.svg" alt="img" className="img-fluid mr-2" />Share</a>
          </div>
          <div className="profile">
            <img
              src="\assets\profile\profile-img.png"
              alt="img"
              className="profile-img img-fluid"
            />
          </div>
          <div className="row">
            <div className="col-xl-11 m-auto p-0">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="mainprofile-left">
                    <h6>
                      domain.tomi{" "}
                      <img
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid ml-2"
                      />
                    </h6>
                    <div className="inner-p">
                      <p>
                        <img
                          src="\assets\profile\ETHEREUM.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        0x0f..b5d8
                        <img
                          src="\assets\copy.svg"
                          alt="img"
                          className="img-fluid ml-2"
                        />
                      </p>
                      <p>
                        <img
                          src="\assets\profile\coin.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        0x0f..b5d8
                        <img
                          src="\assets\copy.svg"
                          alt="img"
                          className="img-fluid ml-2"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="mainprofile-right">
                    <h6>Website <span>WEB3</span></h6>
                    <div className="bottom-card">
                        <div className="top-parent">
                        <div className="parent-left">
                        <div className="left">
                            <img src="\assets\profile\domain.svg" alt="img" className="img-fluid" />
                        </div>
                        <div className="right">
                            <h6>domain.tomi</h6>
                            <p className="d-flex align-items-center">IPFS Hash: Qme6tjqyFh...xDn9 <img src="\assets\copy.svg" alt="img" className="img-fluid ml-2" /></p>
                        </div>
                        </div>
                        <div className="parent-right">
                            <a >Go <img src="\assets\profile\go-arrow.svg" alt="img" className="img-fluid ml-2" /></a>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="badgenew">
                    <h6>Badges (1)</h6>
                    <div className="parent">
                        <div className="left">
                            <img src="\assets\profile\badge.svg" alt="img" className="img-fluid" />
                        </div>
                        <div className="right">
                            <h6>NFT Domain</h6>
                            <p>Holder</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DomainProfile1;
