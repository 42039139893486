import { useCallback } from "react";
import { useToast } from "../state/hooks";
import { connectorsByName } from "../utils/web3React";

const useAuth = () => {
  const { toastError } = useToast();

  const login = async (connectorID) => {
    console.log("---------->", connectorID);
    // console.log("---------->", connectorsByName);

    // console.log("AAAAAA", connectorID)

    const connector = connectorsByName[connectorID];
    // console.log("---------->", connector);
    if (connector) {
      if (connectorID === "injected") {
        await connector.activate(1);
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "connect_wallet",
            event_label: "metamask",
          });
          console.log("connector", connector);
          console.log(`GTM event 'metamask' triggered for: metamask`);
        } else {
          console.error("Google Tag Manager dataLayer is not available");
        }
      } else {
        await connector.activate();
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "connect_wallet",
            event_label: "walletConnect",
          });
          console.log(`GTM event 'connect_wallet' triggered for: walletConnect`);
        } else {
          console.error("Google Tag Manager dataLayer is not available");
        }
      }
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const logout = async (connectorID) => {
    console.log("disssss", connectorID);
    const connector = connectorsByName[connectorID];
    console.log("disssss", connector);
    if (connector) {
      if (connector?.deactivate) {
        await connector.deactivate();
      } else {
        await connector.resetState();
      }
      // await connector.deactivate()
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return { login, logout };
};

export default useAuth;
