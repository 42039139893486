import "./navbar.scss";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import { Modal } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import Loader from "../../../hooks/loader";
import { API_URL } from "../../../ApiUrl";
import axios from "axios";
import Signature from "../../../hooks/userSign";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Cart from "../../cart/Cart";
const Navbar = ({ setTab, setTab2, setTab3, disLanding, tNav, setCartState, cartState }) => {
  const { account } = useWeb3React();
  let history = useHistory();
  const [authStatus, setAuthStatus] = useState("");
  const [currAccount, setCurrAccount] = useState();
  const [nickName, setNickName] = useState();
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState();
  const { login, logout } = useAuth();
  const [cartState2, setCartState2] = useState(false);
  const { userSign } = Signature();
  const user = localStorage.getItem("user");
  // const userObj = JSON.parse(user);
  let invitation = window?.location?.search?.split("?")[1];
  const connectMetamask = async () => {
    window.$("#exampleModalwallet").modal("hide");
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
      setAuthStatus("");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setAuthStatus("signUp");
    }
  };
  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    nickNameBool();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const connectMetamaskSignUp = async () => {
    window.$("#exampleModalwallet").modal("hide");
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
      setAuthStatus("");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setAuthStatus("signUp");
    }
  };
  const trustWalletSignUp = async () => {
    window.$("#exampleModalwallet").modal("hide");
    // handleShow()
    if (account) {
      await logout("walletconnect");
      setAuthStatus("");
    } else {
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      login("walletconnect");
      setAuthStatus("signUp");
    }
  };
  const nickNameBool = async () => {
    let tok = localStorage.getItem("accessToken");
    if (account) {
      setLoader(true);
      var config = {
        method: "patch",
        url: `${API_URL}/customers/set-nickname`,
        headers: {
          authorization: `Bearer ` + tok,
          accept: "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    } else {
      // toast.error('ChainId is missing !')
    }
  };
  const signUpFun = async () => {
    window.$("#exampleModalwallet").modal("hide");
    let data;
    if (invitation && account) {
      data = {
        referredBy: invitation,
        walletAddress: account?.toLowerCase(),
      };
    } else if (!invitation && account) {
      data = {
        walletAddress: account?.toLowerCase(),
      };
    }
    if (account) {
      setLoader(true);
      var config = {
        method: "post",
        url: `${API_URL}/auth/customers/signup`,
        headers: {
          authorization: "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // toast.success(response?.data?.message, toastDesign);
          // toast.info('Now Login', toastDesign)
          signIn();
          setLoader(false);

          setAuthStatus("");
        })
        .catch(async function (error) {
          if (error?.response?.data?.statusCode === 409) {
            signIn();
          } else {
            setLoader(false);
            toast.error(error?.response?.data?.message, toastDesign);
            const connectorId = window.localStorage.getItem("connectorId");
            await logout(connectorId);
            localStorage.setItem("flag", false);
            setAuthStatus("");
          }
        });
    } else {
      // toast.error('ChainId is missing !')
    }
  };
  const signIn = async () => {
    window.$("#exampleModalwallet").modal("hide");
    if (account) {
      setLoader(true);
      try {
        const sign = await userSign(account?.toString()?.toLowerCase());
        let data = {
          sign: sign?.toLowerCase(),
          walletAddress: account?.toLowerCase(),
        };
        var config = {
          method: "post",
          url: `${API_URL}/auth/customers/signin`,
          data: data,
        };
        axios(config)
          .then(function (response) {
            setLoader(false);
            toast.success(response?.data?.message, toastDesign);
            localStorage.setItem("accessToken", response?.data?.data?.accessToken);
            localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
            localStorage.setItem("user", JSON.stringify(response?.data?.data));
            setAuthStatus("");
            if (response?.data?.data?.isNicknameSet) {
            } else {
              handleShow();
            }
          })
          .catch(async function (error) {
            setLoader(false);
            toast.error(error?.response?.data?.message, toastDesign);
            const connectorId = window.localStorage.getItem("connectorId");
            await logout(connectorId);
            localStorage.setItem("flag", false);
            setAuthStatus("");
          });
      } catch (error) {
        // console.log('error', error)
        toast.error("User Denied Sign", toastDesign);
        setLoader(false);
        const connectorId = window.localStorage.getItem("connectorId");
        await logout(connectorId);
        localStorage.setItem("flag", false);
        setAuthStatus("");
        localStorage.clear();
      }
    }
  };
  // console.log('ladlasd', nickName)
  const checkAvail = async (e) => {
    setNickName(e.target.value);
    var data = {
      nickName: e.target.value,
    };
    var config = {
      method: "get",
      url: `${API_URL}/customers/nickname/is-available?nickName=${e.target.value}`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log('ladlasd', response)
        setError(false);
        setNickName(e.target.value);
      })
      .catch(function (error) {
        toast.error();
        if (error?.response?.data?.statusCode === 409) {
          setNickName("");
          setError(true);
        }
        // console.log('sdfasdf',error?.response?.data?.statusCode)/
      });
  };
  // console.log('jajajajajaj', nickName)
  let logoutApi = async () => {
    setLoader(true);
    let tok = localStorage.getItem("accessToken");
    var config = {
      method: "delete",
      url: `${API_URL}/auth/customers/logout`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        toast.success(response?.data?.message, toastDesign);
        if (cartState !== undefined) {
          setCartState(!cartState);
        }
        setCartState2(!cartState2);
        setCurrAccount();
        const connectorId = window.localStorage.getItem("connectorId");
        logout(connectorId);
        localStorage.setItem("flag", false);
        localStorage.clear();
        // setAuthStatus('')
        if (window?.location?.href?.includes("/?")) {
        } else {
          history.push("/");
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  let nameUpdate = async () => {
    if (nickName) {
      setLoader(true);
      let tok = localStorage.getItem("accessToken");
      let data = {
        nickName: nickName,
      };
      var config = {
        method: "patch",
        url: `${API_URL}/customers/profile`,
        data: data,
        headers: {
          authorization: `Bearer ` + tok,
        },
      };
      axios(config)
        .then(function (response) {
          setLoader(false);
          toast.success(response?.data?.message, toastDesign);
          localStorage.setItem("userName", JSON.stringify(response?.data?.data));
          handleClose();
        })
        .catch(function (error) {
          setLoader(false);
          handleClose();
          toast.error(error?.response?.data?.message, toastDesign);
        });
    } else {
      toast.info("Name Input is Empty", toastDesign);
    }
  };
  useEffect(() => {
    if (authStatus === "signIn" && account) {
      signIn();
    }
    if (authStatus === "signUp" && account) {
      signUpFun();
    }
  }, [authStatus === "signIn" && account, authStatus === "signUp" && account]);
  useEffect(() => {
    if (!currAccount) {
      setCurrAccount(account);
    } else {
      localStorage.removeItem("cart");
      if (cartState !== undefined) {
        setCartState(!cartState);
      }
      setCartState2(!cartState2);
      logoutApi();
      setCurrAccount();
      const connectorId = window.localStorage.getItem("connectorId");
      logout(connectorId);
      localStorage.setItem("flag", false);
      localStorage.clear();
      // setAuthStatus('')
      if (window?.location?.href?.includes("/?")) {
      } else {
        history.push("/");
      }
    }
  }, [account]);

  const [cart, setCart] = useState(false);
  const handleCloseCart = () => setCart(false);
  const handleShowCart = () => setCart(true);

  const [cart1, setCart1] = useState(false);
  const handleCloseCart1 = () => setCart1(false);
  const handleShowCart1 = () => setCart1(true);

  const [cart2, setCart2] = useState(false);
  const handleCloseCart2 = () => setCart2(false);
  const handleShowCart2 = () => setCart2(true);

  const [cart3, setCart3] = useState(false);
  const handleCloseCart3 = () => setCart3(false);
  const handleShowCart3 = () => setCart3(true);
  const isActive = (path) => {
    return window.location.pathname === path;
  };
  return (
    <>
      {loader && <Loader />}
      <section
        className={`main-navbar-mobile main-navbar ${disLanding} ${tNav && "main-navbar-mobilenew main-navbarnew"
          }`}
      >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <nav className="navbar  navbar-expand-xl">
                <a href="#" className="navbar-brand ">
                  <Link to="/">
                    {tNav ? (
                      <img src="\newlogo.svg" alt="img" className="img-fluid mLogWid" />
                    ) : (
                      <img src="\newlogo.svg" alt="img" className="img-fluid mLogWid" />
                    )}
                  </Link>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="togg">
                    {tNav ? (
                      <img
                        src="\assets\togle.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src="\assets\togle.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    )}
                  </span>
                </button>
                <div className="collapse navbar-collapse marg mytext_divvvv" id="navbarSupportedContent">
                  <ul className="navbar-nav m-auto toper_navbar">
                    {/* Home Tab */}
                    <li className="nav-item">
                      <Link
                        className={`${setTab} ${isActive('/') ? 'active-bold' : ''}`}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>

                    {/* My Domains Tab */}
                    <li className="nav-item">
                      <Link
                        className={`${setTab2} ${isActive('/domainSearhResult/active/mm') ? 'active-bold' : ''}`}
                        to="/domainSearhResult/active/mm"
                      >
                        My Domains
                      </Link>
                    </li>

                    {/* Marketplace Tab */}
                    <li className="nav-item">
                      <Link
                        className={`${setTab3} ${isActive('/marketplace') ? 'active-bold' : ''}`}
                        to="/marketplace"
                      >
                        Marketplace
                      </Link>
                    </li>
                  </ul>
                  <div className="sycvcvsvscyvscvsv">
                    {/* <button
                      class=" btn button-hedaer high high1"
                      data-toggle="modal"
                      data-target="#exampleModalwallet"
                      type="button"
                    >
                      {'SignUp'}
                    </button> */}
                    <div class={account ? "dropdown profile-dropdown " : "d-none"}>
                      <button
                        class="btn btn-secondary dropdown-toggle high"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path d="M10.2174 9.99999C12.5186 9.99999 14.3841 8.13451 14.3841 5.83332C14.3841 3.53214 12.5186 1.66666 10.2174 1.66666C7.91626 1.66666 6.05078 3.53214 6.05078 5.83332C6.05078 8.13451 7.91626 9.99999 10.2174 9.99999Z" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M17.3753 18.3333C17.3753 15.1083 14.1669 12.5 10.2169 12.5C6.26692 12.5 3.05859 15.1083 3.05859 18.3333" stroke="#0E1120" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Account
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09026 4.87877L3.71902 4.50754L2.97656 5.25L3.34779 5.62123L6.84779 9.12123L7.21902 9.49246L7.59026 9.12123L7.92811 8.78337L6.78516 7.57367L4.09026 4.87877ZM7.54829 7.67828L8.84162 7.86987L11.0903 5.62123L11.4615 5.25L10.719 4.50754L10.3478 4.87877L7.54829 7.67828Z" fill="#0E1120" />
                        </svg>
                      </button>

                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className="myyyyyyyy">
                          <div className="header">
                            <h1>John Doe</h1>
                            <h6 className="text-truncate">{account ? account : "0x00..."}</h6>
                          </div>

                          <div className="bottom-content">
                            <div className="multi">
                              <Link to="/settings" className="d-flex align-items-center multiitextual">
                                <img src="\assets\settingsvg.svg" />
                                <h6>Settings</h6>
                              </Link>
                            </div>
                            <div className="multi">
                              <Link to="/commission" className="d-flex align-items-center multiitextual">
                                <img src="\assets\handsvg.svg" />
                                <h6>My Partner Claims</h6>
                              </Link>
                            </div>
                            <div className="multi">
                              <Link to="/refferalprogram" className="d-flex align-items-center multiitextual">
                                <img src="\assets\mensvg.svg" />

                                <h6>Referral Program</h6>
                              </Link>
                            </div>
                            {account && (
                              <div
                                className="multi"
                                onClick={() => {
                                  logoutApi();
                                }}
                              >
                                <a className="d-flex align-items-center multiitextual">
                                  <img src="\assets\disconectsvg.svg" />
                                  <h6 className="disconnect_textt">Disconnect</h6>
                                </a>
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                      <Cart
                        setCartState={setCartState}
                        cartState={cartState}
                        setLoader={setLoader}
                        setCartState2={setCartState2}
                        cartState2={cartState2}
                      />
                    </div>
                    {/* <div className="cart-parent">
                      <button onClick={handleShowCart} className="cart-btn"><img src="\cart\cart-icon.svg" alt="img" className="img-fluid" /></button>
                      <span>3</span>
                    </div> */}
                    {account ? null : (
                      <button
                        class="btn button-hedaer"
                        data-toggle="modal"
                        data-target="#exampleModalwallet"
                        type="button"
                      >
                        {account ? "Wallet  Connected" : "Connect Wallet"}
                      </button>
                    )}
                    {/* <Cart
                      setCartState={setCartState}
                      cartState={cartState}
                      setLoader={setLoader}
                      setCartState2={setCartState2}
                      cartState2={cartState2}
                    /> */}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="connectwalletmodal">
        <div
          class="modal fade modal-Nav navbarMOdal"
          id="exampleModalwallet"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
              <div class="modal-body">
                <div className="main-heading">
                  <h3> {account ? "Disconnect" : "Connect Wallet"} </h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">

                    <svg className="img-fluid crossimg" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                      <path d="M19.281 18.7198C19.3507 18.7895 19.406 18.8722 19.4437 18.9632C19.4814 19.0543 19.5008 19.1519 19.5008 19.2504C19.5008 19.349 19.4814 19.4465 19.4437 19.5376C19.406 19.6286 19.3507 19.7114 19.281 19.781C19.2114 19.8507 19.1286 19.906 19.0376 19.9437C18.9465 19.9814 18.849 20.0008 18.7504 20.0008C18.6519 20.0008 18.5543 19.9814 18.4632 19.9437C18.3722 19.906 18.2895 19.8507 18.2198 19.781L12.0004 13.5607L5.78104 19.781C5.64031 19.9218 5.44944 20.0008 5.25042 20.0008C5.05139 20.0008 4.86052 19.9218 4.71979 19.781C4.57906 19.6403 4.5 19.4494 4.5 19.2504C4.5 19.0514 4.57906 18.8605 4.71979 18.7198L10.9401 12.5004L4.71979 6.28104C4.57906 6.14031 4.5 5.94944 4.5 5.75042C4.5 5.55139 4.57906 5.36052 4.71979 5.21979C4.86052 5.07906 5.05139 5 5.25042 5C5.44944 5 5.64031 5.07906 5.78104 5.21979L12.0004 11.4401L18.2198 5.21979C18.3605 5.07906 18.5514 5 18.7504 5C18.9494 5 19.1403 5.07906 19.281 5.21979C19.4218 5.36052 19.5008 5.55139 19.5008 5.75042C19.5008 5.94944 19.4218 6.14031 19.281 6.28104L13.0607 12.5004L19.281 18.7198Z" fill="#0E1120" />
                    </svg>
                  </button>
                </div>
                <div className="Submitproposal">
                  <div className="iconimages">
                    <div className="leftimage meta" onClick={connectMetamaskSignUp}>
                      <img src="\assets\metamask.svg" className="img-fluid" />
                      {account ? <p>Disconnect</p> : <p>Metamask</p>}
                    </div>
                    <div onClick={trustWalletSignUp} className="leftimage wallo">
                      <img src="\assets\connectwallet.svg" className="img-fluid" />
                      <p>{account ? "Disconnect" : "WalletConnect"} </p>
                    </div>
                  </div>
                </div>
                {/* <div className="clear">
                  <button onClick={() => account && connectMetamask()} className="d-flex justify-content-center align-items-center">
                    <img
                      src="\assets\Globe.svg"
                      alt="img"
                      className="img-fluid mr-2"
                    />
                    Clear WalletConnect Data
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="refer-modal" show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Choose Your Nickname <svg onClick={handleClose} className="clossebuttons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19.281 18.2193C19.3507 18.289 19.406 18.3717 19.4437 18.4628C19.4814 18.5538 19.5008 18.6514 19.5008 18.7499C19.5008 18.8485 19.4814 18.9461 19.4437 19.0371C19.406 19.1281 19.3507 19.2109 19.281 19.2806C19.2114 19.3502 19.1286 19.4055 19.0376 19.4432C18.9465 19.4809 18.849 19.5003 18.7504 19.5003C18.6519 19.5003 18.5543 19.4809 18.4632 19.4432C18.3722 19.4055 18.2895 19.3502 18.2198 19.2806L12.0004 13.0602L5.78104 19.2806C5.64031 19.4213 5.44944 19.5003 5.25042 19.5003C5.05139 19.5003 4.86052 19.4213 4.71979 19.2806C4.57906 19.1398 4.5 18.949 4.5 18.7499C4.5 18.5509 4.57906 18.36 4.71979 18.2193L10.9401 11.9999L4.71979 5.78055C4.57906 5.63982 4.5 5.44895 4.5 5.24993C4.5 5.05091 4.57906 4.86003 4.71979 4.7193C4.86052 4.57857 5.05139 4.49951 5.25042 4.49951C5.44944 4.49951 5.64031 4.57857 5.78104 4.7193L12.0004 10.9396L18.2198 4.7193C18.3605 4.57857 18.5514 4.49951 18.7504 4.49951C18.9494 4.49951 19.1403 4.57857 19.281 4.7193C19.4218 4.86003 19.5008 5.05091 19.5008 5.24993C19.5008 5.44895 19.4218 5.63982 19.281 5.78055L13.0607 11.9999L19.281 18.2193Z" fill="#0E1120" />
          </svg></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will help your friends recognize you on the platform</p>
          <div className="option-field">
            <label>
              Enter your nickname <span className="common">(Optional)</span>
            </label>
            <input onChange={checkAvail} type="text" placeholder="Nickname" />
            {error && (
              <p className="errorTxt">This nickname is already taken. Try a different one</p>
            )}
          </div>
          <button
            disabled={error}
            onClick={nameUpdate}
            className={error ? "btn-submit btnDisable" : "btn-submit"}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>

      {/* All Cart modal /here.................................. */}
      <Modal className="cart-modal" show={cart} onHide={handleCloseCart} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cart <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19.281 18.2193C19.3507 18.289 19.406 18.3717 19.4437 18.4628C19.4814 18.5538 19.5008 18.6514 19.5008 18.7499C19.5008 18.8485 19.4814 18.9461 19.4437 19.0371C19.406 19.1281 19.3507 19.2109 19.281 19.2806C19.2114 19.3502 19.1286 19.4055 19.0376 19.4432C18.9465 19.4809 18.849 19.5003 18.7504 19.5003C18.6519 19.5003 18.5543 19.4809 18.4632 19.4432C18.3722 19.4055 18.2895 19.3502 18.2198 19.2806L12.0004 13.0602L5.78104 19.2806C5.64031 19.4213 5.44944 19.5003 5.25042 19.5003C5.05139 19.5003 4.86052 19.4213 4.71979 19.2806C4.57906 19.1398 4.5 18.949 4.5 18.7499C4.5 18.5509 4.57906 18.36 4.71979 18.2193L10.9401 11.9999L4.71979 5.78055C4.57906 5.63982 4.5 5.44895 4.5 5.24993C4.5 5.05091 4.57906 4.86003 4.71979 4.7193C4.86052 4.57857 5.05139 4.49951 5.25042 4.49951C5.44944 4.49951 5.64031 4.57857 5.78104 4.7193L12.0004 10.9396L18.2198 4.7193C18.3605 4.57857 18.5514 4.49951 18.7504 4.49951C18.9494 4.49951 19.1403 4.57857 19.281 4.7193C19.4218 4.86003 19.5008 5.05091 19.5008 5.24993C19.5008 5.44895 19.4218 5.63982 19.281 5.78055L13.0607 11.9999L19.281 18.2193Z" fill="#0E1120" />
          </svg></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cart-main-modal">
            <div className="inner-first">
              <h6>
                Domains <span>3</span>
              </h6>
              <a href="#">Clear All</a>
            </div>
            <div className="cart-items">
              <div className="cart-item">
                <div className="left">
                  <div className="inner-left">
                    <div className="domain-set-img">
                      <img
                        src="\cart\domain-bg-img.svg"
                        alt="img"
                        className="img-fluid domain-img"
                      />
                      <p>world.tomi</p>
                    </div>
                  </div>
                  <div className="inner-right">
                    <h6>world.tomi</h6>
                    <p className="available">Available</p>
                  </div>
                </div>
                <div className="right">
                  <div className="close-icon">
                    <img src="\cart\close-icon.svg" alt="img" className="img-fluid" />
                  </div>
                  <div className="inner-text">
                    <p>Price</p>
                    <h6>24.99 TOMI</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="total-fee">
              <div className="inner-text">
                <p>Gas fee</p>
                <h6>0.0918 ETH</h6>
              </div>
              <div className="inner-text">
                <p>Subtotal</p>
                <h6>2,524.99 TOMI</h6>
              </div>
            </div>
            <div className="bottom-btn">
              <button
                className="btn-pink"
                onClick={() => {
                  handleCloseCart();
                  handleShowCart1();
                }}
              >
                Complete Purchase
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="cart-modal" show={cart1} onHide={handleCloseCart1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchase-main-content">
            <div className="top-heading">
              <h6>Your domains have been minted successfully</h6>
              <p>And placed on the marketplace for auction until 20th June, 2024</p>
            </div>
            <div className="inner-mid">
              <div className="inner-cart">
                <div className="inner-left">
                  <div className="domain-set-img">
                    <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                    <p>world.tomi</p>
                  </div>
                  <h6>world.tomi</h6>
                </div>
                <div className="inner-right">
                  <p>Price</p>
                  <h6>24.99 TOMI</h6>
                </div>
              </div>
              <div className="inner-cart">
                <div className="inner-left">
                  <div className="domain-set-img">
                    <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                    <p>world.tomi</p>
                  </div>
                  <h6>world.tomi</h6>
                </div>
                <div className="inner-right">
                  <p>Price</p>
                  <h6>24.99 TOMI</h6>
                </div>
              </div>
            </div>
            <div className="total-fee">
              <div className="inner-text">
                <p>Total Price</p>
                <h6>2 items</h6>
              </div>
              <div className="inner-text">
                <p>1,024.99 TOMI</p>
                <h6>~3,662.084 USD</h6>
              </div>
            </div>
            <div className="bottom-btn">
              <button
                className="btn-pink"
                onClick={() => {
                  handleCloseCart1();
                  handleShowCart2();
                }}
              >
                Go Checkout My Domain
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="cart-modal" show={cart2} onHide={handleCloseCart2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchase-main-content">
            <div className="top-heading">
              <h6>Your purchase was partially completed</h6>
            </div>
            <div className="ifitem-failed">
              <img src="\cart\info-circle.svg" alt="img" className="img-fluid" />
              <div className="inner-text">
                <h6>1 item failed</h6>
                <p>
                  Purchase can fail due to network issues, gas fee increases, or because someone
                  else bought the item before you.
                </p>
              </div>
            </div>
            <div className="inner-mid">
              <div className="inner-cart">
                <div className="inner-left">
                  <div className="domain-set-img">
                    <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                    <p>world.tomi</p>
                  </div>
                  <h6>world.tomi</h6>
                </div>
                <div className="inner-right">
                  <p>Price</p>
                  <h6>24.99 TOMI</h6>
                </div>
              </div>
              <div className="inner-cart">
                <div className="inner-left">
                  <div className="domain-set-img">
                    <img src="\cart\domain-bg-img.svg" alt="img" className="img-fluid domain-img" />
                    <p>world.tomi</p>
                  </div>
                  <h6>world.tomi</h6>
                </div>
                <div className="inner-right">
                  <p>Price</p>
                  <h6>24.99 TOMI</h6>
                </div>
              </div>
            </div>
            <div className="total-fee">
              <div className="inner-text">
                <p>Total Price</p>
                <h6>2 items</h6>
              </div>
              <div className="inner-text">
                <p>1,024.99 TOMI</p>
                <h6>~3,662.084 USD</h6>
              </div>
            </div>
            <div className="bottom-btn">
              <button
                className="btn-pink"
                onClick={() => {
                  handleCloseCart2();
                  handleShowCart3();
                }}
              >
                Go Checkout My Domain
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="cart-modal" show={cart3} onHide={handleCloseCart3} centered>
        <Modal.Header closeButton>
          <Modal.Title>Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchase-main-content">
            <div className="top-heading">
              <h6>Your purchase failed</h6>
            </div>
            <div className="ifitem-failed">
              <img src="\cart\info-circle.svg" alt="img" className="img-fluid" />
              <div className="inner-text">
                <h6>3 items failed</h6>
                <p>
                  Purchase can fail due to network issues, gas fee increases, or because someone
                  else bought the item before you.
                </p>
              </div>
            </div>
            <div className="total-fee">
              <div className="inner-text">
                <p>Total Price</p>
                <h6>2 items</h6>
              </div>
              <div className="inner-text">
                <p>1,024.99 TOMI</p>
                <h6>~3,662.084 USD</h6>
              </div>
            </div>
            <div className="bottom-btn">
              <button
                className="btn-pink"
                onClick={() => {
                  handleCloseCart3();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
