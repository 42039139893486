import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/Navbar'
import "./commission.scss"
import DataTable from './DataTable'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Environment from '../../utils/Environment'
import axios from 'axios'
import Loader from '../../hooks/loader'
import Commision from '../../hooks/dataFetchers/commision'
import useWeb3 from '../../hooks/useWeb3'
import MinterMultipleClaimReward from '../../hooks/dataSenders/minterMultipleClaimReward'
import Modal from 'react-bootstrap/Modal';
const Commission = () => {
  const [loader, setLoader] = useState(false)
  const { account } = useWeb3React()
  const web3 = useWeb3();
  const [commision, setCommision] = useState(0)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [mintedData, setMintedData] = useState()
  const [additionalDataArr, setAdditionalDataArr] = useState([])
  const [availableCommision, setAvaiCommision] = useState(0)
  const [apiStatus, setApiCallStatus] = useState(false)
  const [multiIds, setMultiIds] = useState([])
  const { ClaimMultipleCommison } = MinterMultipleClaimReward()
  const [LastCommisionClaimed, setLastCommisionClaimed] = useState(0)
  const { currCommision } = Commision()
  console.log('alsdfladddd', additionalDataArr)
  const getAdditionalData = async (ids) => {
    setLoader(true)
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
        query MyQuery {
        auctionCreateds(where: {partnerId_in: [${ids}]}) {
        id
        tokenId
        minter
        mintAmount
        startTime
        expiryTime
        label
        labelhash
        tld
        isClaimed
        partnerId
      }
    }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log('additional data', response, ids)
          setAdditionalDataArr(response?.data?.data?.auctionCreateds)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getMinteddata = () => {
    setLoader(true)
    var config = {
      method: "get",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft/${Environment.partnerNFT}?chain=ftm&format=decimal`,
      headers: {
        "x-api-key":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZlMGI5MjNkLTNkYmYtNDBlZC04MGY4LTU3NWVhODEwYjc0ZiIsIm9yZ0lkIjoiMjA3ODg5IiwidXNlcklkIjoiMjA3NTYxIiwidHlwZUlkIjoiZWMwMWYzMDctNTJhMS00ZTNiLWExYzgtNWM3MmQwOGExZDc1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODczNTcwNDYsImV4cCI6NDg0MzExNzA0Nn0.Uv0uw5Q_lnSgXMquIJ0GHKlx_uNMkdDptrT00MzmwPo",
      },
    };
    axios(config)
      .then(function (response) {
        setLoader(true)
        // console.log('partner nfts reward', response)
        let minted = response.data.result;
        // console.log('tttytytyty ==== ', minted)
        let partnerNftsIds = minted?.map((item) => parseInt(item?.token_id))
        // console.log('tttytytyty partnerNftsIds ', partnerNftsIds)
        getAdditionalData(partnerNftsIds)
        getTotalClaimed(partnerNftsIds)
        // console.log('partnerNft', minted, partnerNftsIds)
        setMintedData(minted)
        setLoader(false)
      })
      .catch(function (error) {
        // console.log('partnerNft', error)
        // // console.log(error);
        setLoader(false)
      });
  };

  // const getMinted = async () => {
  //   // var config = {
  //   //   method: "get",
  //   //   url: `https://deep-index.moralis.io/api/v2/${account}/nft/${Environment.partnerNFT}?chain=eth&format=decimal`,
  //   //   headers: {
  //   //     "x-api-key":
  //   //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZlMGI5MjNkLTNkYmYtNDBlZC04MGY4LTU3NWVhODEwYjc0ZiIsIm9yZ0lkIjoiMjA3ODg5IiwidXNlcklkIjoiMjA3NTYxIiwidHlwZUlkIjoiZWMwMWYzMDctNTJhMS00ZTNiLWExYzgtNWM3MmQwOGExZDc1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODczNTcwNDYsImV4cCI6NDg0MzExNzA0Nn0.Uv0uw5Q_lnSgXMquIJ0GHKlx_uNMkdDptrT00MzmwPo",
  //   //   },
  //   // };

  //   setLoader(true)

  //   setLoader(true)
  //   try {
  //     var data = JSON.stringify({
  //       query: `
  //             query MyQuery {
  //               nfts(where: {owner: "${account}"}) {
  //                 owner
  //                 tokenId
  //                 id
  //                 transactionHash
  //               }
  //             }`,
  //       variables: {},
  //     });
  //     var config = {
  //       method: "post",
  //       url: 'https://api.studio.thegraph.com/query/63987/partner-nft/version/latest',
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     let dumArr = []
  //     let res = await axios(config)
  //     let newData = res?.data?.data?.nfts
  //     // console.log("partner res", res)
  //     let tokenIds = newData.map((item)=> parseInt(item?.tokenId))
  //     // console.log(tokenIds, 'tokenIds');
  //     // for (let key in newData) {
  //       var data = JSON.stringify({
  //         query: `
  //               query MyQuery {
  //                 auctionCreateds(where: {partnerId_in: [${tokenIds}]}) {
  //                   label
  //                   tld
  //                   tokenId
  //                   partnerId
  //                 }
  //               }`,
  //         variables: {},
  //       });
  //       var config1 = {
  //         method: "post",
  //         url: Environment.marketplaceGraph,
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         data: data,
  //       };
  //       let res1 = await axios(config1)
  //       console.log("data in ropw",res1)
  //       setMintedData(res1.data.data.auctionCreateds)
  //       // if (res?.data?.data?.auctionCreateds[key]) {
  //       //   dumArr.push(res?.data?.data?.auctionCreateds[0])
  //       // // }
  //       // console.log("partner res11111", res1)
  //     // }
  //     // setPartnerNfts(res1.data.data.auctionCreateds)
  //     getAdditionalData(tokenIds)
  //     getTotalClaimed(tokenIds)
  //     // console.log('fdssddsfsdfdf', response, key)
  //   }
  //   catch (error) {
  //     setLoader(false)
  //   }
  //   setLoader(false)

  //   setLoader(false)

  // };

  const getTotalClaimed = async (ids) => {
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
        query MyQuery {
          minterClaims(where: {partnerId_in: [${ids}]}) {
            tokenId
            id
            amount
          }
        }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // console.log('total claimed', response)
          let claimedCommison = response?.data?.data?.minterClaims
          console.log('tttytytyty', claimedCommison)
          var totalMintAmount = 0;
          for (var i = 0; i < claimedCommison.length; i++) {
            let weiAmount = web3.utils.fromWei(claimedCommison[i]?.amount, 'ether')
            totalMintAmount += parseFloat(weiAmount);
          }
          setLastCommisionClaimed(totalMintAmount)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getTotalCommison = async () => {
    var totalMintAmount = 0;
    let dumArr = []
    for (var i = 0; i < mintedData.length; i++) {
      try {
        // console.log("commsion11",mintedData) 
        const commision = await currCommision(mintedData[i]?.token_id,)
        // const commision = await currCommision(mintedData[i]?.partnerId)
        // console.log("commsion",commision)
        if (commision > 0) {
          dumArr.push(parseInt(mintedData[i]?.token_id))
          // dumArr.push(parseInt(mintedData[i]?.partnerId))

        }
        var mintAmount = parseFloat(commision);
        // console.log('first', commision)
      } catch (error) {
        var mintAmount = parseFloat(0);
      }
      totalMintAmount += mintAmount;
    }
    setMultiIds(dumArr)
    setAvaiCommision(totalMintAmount)
  }
  const claimCommisionValue = async () => {

    try {
      setLoader(true)
      const arr = Array.from(Object.values(multiIds));
      const claimed = await ClaimMultipleCommison(arr, account)
      handleShow()
      setApiCallStatus(!apiStatus)
      setLoader(false)
    } catch (error) {
      handleShow1()
      // console.log('first', error)
      setLoader(false)
    }

  }
  useEffect(() => {
    if (mintedData) {
      getTotalCommison()
    }
  }, [mintedData, apiStatus])
  useEffect(() => {
    if (account) {
      getMinteddata()
    }
  }, [account, apiStatus])
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      <section className="mycommission-section">
        <img src="\assets\shadows.png" alt="img" className='img-fluid myyyelipsenew' />
        <div className="custom-container">
          <Link to="/" className="back-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5285 11.4697L11.0588 12L9.99816 13.0607L9.46783 12.5303L5.46783 8.53034L4.9375 8.00001L5.46783 7.46968L5.964 6.97351L7.20232 8.14351L10.5285 11.4697ZM7.19847 7.86036L6.92477 6.01274L9.46783 3.46968L9.99816 2.93935L11.0588 4.00001L10.5285 4.53034L7.19847 7.86036Z" fill="#0E1120" />
          </svg>Back</Link>
          <div className="main-heading">
            <h6>My Partner Claims</h6>
          </div>
          <div className="twice-div">
            <div className="inner-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M3.75 27.5H26.25" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M7.5 22.5V13.75" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M12.5 22.5V13.75" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M17.5 22.5V13.75" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M22.5 22.5V13.75" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M15 2.5L25 8.75H5L15 2.5Z" stroke="#FF0083" stroke-width="1.5" stroke-linecap="round"/>
</svg>
              <h6>Available For Claim</h6>
              <h5>{availableCommision?.toString()?.length > 6 ? parseFloat(availableCommision)?.toFixed(2) : availableCommision} TOMI</h5>
            </div>
            <div className="inner-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
  <path d="M19.25 2.5H8C7.33696 2.5 6.70107 2.76339 6.23223 3.23223C5.76339 3.70107 5.5 4.33696 5.5 5V25C5.5 25.663 5.76339 26.2989 6.23223 26.7678C6.70107 27.2366 7.33696 27.5 8 27.5H23C23.663 27.5 24.2989 27.2366 24.7678 26.7678C25.2366 26.2989 25.5 25.663 25.5 25V8.75L19.25 2.5Z" stroke="#FF0083" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M18 2.5V7.5C18 8.16304 18.2634 8.79893 18.7322 9.26777C19.2011 9.73661 19.837 10 20.5 10H25.5" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square"/>
  <path d="M13 11.25H10.5" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M20.5 16.25H10.5" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M20.5 21.25H10.5" stroke="#FF0083" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
</svg>
              <h6>Total Claimed</h6>
              <h5>{LastCommisionClaimed?.toString()?.length > 6 ? parseFloat(LastCommisionClaimed)?.toFixed(2) : LastCommisionClaimed} TOMI</h5>
            </div>
          </div>
          <div className="bottom-part">
            <div className="upper-area">
              <h6>My Partner Claims History</h6>
              <button disabled={multiIds?.length === 0} className={multiIds?.length > 0 ? 'btn-claim' : 'btn-claim btnDis px-3'} onClick={claimCommisionValue}>{multiIds?.length === 0 ? 'All Claimed' : 'Claim All'}</button>
              {/* <button className='btn-claim' onClick={claimCommisionValue}>Claim All</button> */}
            </div>
            <DataTable mintedData={mintedData} setLoader={setLoader} setApiCallStatus={setApiCallStatus} apiStatus={apiStatus} additionalDataArr={additionalDataArr} />
          </div>
        </div>
      </section>
      <Modal className='success-modal' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\success2.svg" alt="img" className='img-fluid' />
            <h6> Claimed Successfully</h6>
          </div>
          <button onClick={handleClose} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>

      <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
            <h6>Failed to claim commission. try again</h6>
          </div>
          <button onClick={handleClose1} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Commission
