import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DomainProfile from '../../hooks/dataSenders/transferForm'
import WAValidator from 'multicoin-address-validator';
function Transfer({ UrlData, setLoader, tokenId }) {
    const [checkeds, setCheckeds] = useState()
    const [checkeds2, setCheckeds2] = useState()
    const [checkeds3, setCheckeds3] = useState()
    const [eth, seteth] = useState(false)
    // // console.log('5555555555', checkeds)
    const [cryptoData, setCryptoData] = useState({
        RAddress: '',
    })
    const { domainProfileFun } = DomainProfile()
    const profileDataFun = async (e) => {
        var { name, value } = e.target;
        setCryptoData((pre) => {
            return {
                ...pre, [name]: value
            }
        })
        var valid = WAValidator.validate(value, 'eth');
        if (valid) {
            // // console.log('This is a valid address');
            seteth(false)
        }
        else {
            // // console.log('Address INVALID');
            seteth(true)
        }

    }
    // // console.log('dkfjdtyuioiuytyui', tokenId)
    const confirCryptoChanges = async (e) => {
        if (cryptoData.RAddress === '') {
            toast.error('Fill the name field', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } else if (!UrlData) {
            toast.error('DomainName is missing', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
        else if (UrlData) {
            // let jsonData = JSON.stringify(cryptoData)
            try {
                setLoader(true)
                // // console.log('yyyyyyyyyyyyt', cryptoData.RAddress)
                const res = await domainProfileFun(cryptoData.RAddress.toLowerCase(), tokenId)
                toast.success('Transfer Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                // // console.log('domainProfile contract data succ', res)
                setLoader(false)
            } catch (error) {
                // // console.log('domainProfile contract data err', error)
                toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setLoader(false)
            }

        } else {
            // toast.error('dont know')
        }

    }
    // const getProfileData = async (e) => {

    //     if (UrlData) {
    //         try {
    //             setLoader(true)
    //             const res = await domainProfileFun(UrlData, 'RecipientAddress', '0', true)
    //             // toast.success('Profile Data get Successfully')
    //             // setProfilesData(JSON.parse(res))
    //             setLoader(false)
    //             if (res) {
    //                 const resObj = JSON.parse(res)
    //                 setCryptoData({
    //                     RAddress: resObj.RAddress,
    //                 })
    //             }
    //             // // console.log('domainProfile contract data succ', res)
    //         } catch (error) {
    //             setLoader(false)
    //             // // console.log('domainProfile contract data err', error)
    //             // toast.error('Profile Data get Error')
    //         }

    //     } else {
    //         // toast.error('dont know')
    //     }

    // }
    useEffect(() => {
        // getProfileData()
    }, [UrlData])
    return (
        <section className="third">
            <div className="thirdheading">
                <h6>Transfer domain to another address</h6>
                <p>
                    You must have the private key in
                    order to manage your domain.
                    If you transfer this domain to
                    an exchange or any other custodial
                    account where you do not control
                    the private key, you will not be
                    able to access your domain. Not your keys,
                    not your domain.
                    <a href="https://www.youtube.com/watch?v=PhvatlXI70E&t=2s">How it works</a>
                </p>
            </div>
            <div className="option-field">
                <label>Recipient Address</label>
                <input
                    value={cryptoData.RAddress} name='RAddress' onChange={profileDataFun}
                    type="text"
                    placeholder="Enter Recipient Address"
                />
                {cryptoData.RAddress && (eth && <p className='text-danger font-weight-bold'>ETH Address Invalid !</p>)}
            </div>
            <div className="inner-checks">
                <div class="custom-control custom-checkbox">
                    <input
                        onChange={(e) => setCheckeds(e.target.checked)}
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                    />
                    <label
                        class="custom-control-label"
                        for="customCheck1"
                    >
                        I understand that i am transferring ownership of
                        this domain and this acton is irreversible
                    </label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input
                        onChange={(e) => setCheckeds2(e.target.checked)}
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck3"
                    />
                    <label
                        class="custom-control-label"
                        for="customCheck3"
                    >
                        I’m not transferring to an exchange address
                    </label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input
                        onChange={(e) => setCheckeds3(e.target.checked)}
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                    />
                    <label
                        class="custom-control-label"
                        for="customCheck2"
                    >
                        Clear records upon transfer (Optional)
                    </label>
                </div>
            </div>
            <button disabled={!checkeds || !checkeds2 || !checkeds3 || eth} onClick={confirCryptoChanges} className={(checkeds && checkeds2 && checkeds3 && !eth) ? "transfer-btn w-100 border-0" : "transfer-btn w-100 border-0 bg-secondary"}>Transfer Domain</button>
        </section>
    )
}

export default Transfer