import React, { useState } from "react";
import "./account.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Navbar from "../landing/header/Navbar";

const Account = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  return (
    <>
    <Navbar />
      <section className="account">
      <img src='\assets\newshade.svg' className='myyyelipse img-fluid'/>
     
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-12 m-auto p-0">
              <div className="account-heading">
                <h3>Account Settings</h3>
                <h5>Two Factor Authentication</h5>
                <h6>
                  Enabling two factor authentication helps keep your domains
                  safe.
                </h6>
              </div>
              <div className="bottom-heading">
                <div className="row">
                  <div className="col-xl-8 col-12 padd-lg pl-0">
                    <div className="top-card">
                      <div className="heading">
                        <h4>Disabled</h4>
                      </div>
                      <div className="inner-text">
                        <h6 onClick={handleShow}>
                          <img
                            src="\assets\add-circle.svg"
                            alt="img"
                            className="img-fluid mr-3"
                          />
                          Set up two factor authentication
                        </h6>
                      </div>
                    </div>
                    {/* <div className="top-card">
                                <div className="heading">
                                    <h4>Enabled</h4>
                                </div>
                                <div className="inner-text">
                                    <h6 onClick={handleShow}><img src="\assets\minus-cirlce.svg" alt="img" className='img-fluid mr-3' />Disable</h6>
                                </div>
                            </div> */}
                    <div className="bottom-content">
                      <div className="left">
                        <h6>Wallet</h6>
                        <p>You have no wallets currently linked</p>
                      </div>
                      <div className="right">
                        <a href="">
                          <img
                            src="\assets\add-circle.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          Add Wallet
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-12 padd-lg pr-0">
                    <div className="right">
                      <div className="card-right">
                        <div className="heading">
                          <h4>Account</h4>
                        </div>
                        <div className="bottom-content1">
                          <div className="parent">
                            <div className="left">
                              <img
                                src="\assets\mail-icon.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="right">
                              <p>Email</p>
                              <h6>xxxxx@gmail.com</h6>
                            </div>
                          </div>
                          <a className="reset-btn" onClick={handleShow3}>
                            Reset Password
                          </a>
                          <a className="change-btn" onClick={handleShow5}>
                            Change Email Address
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Two Authentication modal.............. */}
      <Modal
        className="twofactor-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="inner-parent">
              <div className="left">
                <img src="\assets\1.svg" alt="img" className="img-fluid" />
              </div>
              <div className="right">
                <h5>Scan the barcode</h5>
                <p>
                  Use the authenticator app (such as Duo or Google Authenticator
                  from the <a href=""> App Store</a> or{" "}
                  <a href="">Play Store</a> ) to scan the barcode. Can't scan
                  the barcode? Enter the secret code under the barcode.
                </p>
              </div>
            </div>
            <div className="barscan">
              <img src="\assets\scanbar.png" alt="img" className="img-fluid" />
              <p>JN2QYOZ3LA2BU3RU</p>
            </div>
            <div className="card-down">
              <img
                src="\assets\info-circle.svg"
                alt="img"
                className="img-fluid"
              />
              <h6>
                Writing down this code above or printing the QR code can help
                you to recover your account, if you lose access to your phone.
              </h6>
            </div>
            <div className="bottom-btns">
              <a className="next-btn" onClick={handleShow1}>
                Next
              </a>
              <a className="cancel-btn">Cancle</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Two Authentication modal.............. */}
      <Modal
        className="twofactor-modal twofactor-modal1"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="inner-parent">
              <div className="left">
                <img src="\assets\1.svg" alt="img" className="img-fluid" />
              </div>
              <div className="right">
                <h5>Enter the verification code</h5>
                <p>Enter the code from your authentication app </p>
              </div>
            </div>
            <div className="bottom-code">
              <label>Authentication Code</label>
              <input type="text" placeholder="xxxxxxxxx" />
            </div>

            <div className="bottom-btns">
              <a className="next-btn" onClick={handleShow2}>
                Next
              </a>
              <a className="cancel-btn">Cancle</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Two Authentication modal.............. */}
      <Modal
        className="twofactor-modal confirm-email"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="upper-content">
              <h4>Confirm Your Email</h4>
              <p>
                We will send a verification code to your email:
                xxxxxxxx@gmail.com
              </p>
            </div>

            <div className="bottom-btns">
              <a href="" className="next-btn">
                Send Verification Code
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reset Password modal.............. */}
      <Modal
        className="reset-password"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="option-field">
              <label>Email</label>
              <input type="text" placeholder="Email" />
            </div>

            <div className="bottom-btns">
              <a className="next-btn" onClick={handleShow4}>
                Send Password Reset
              </a>
              <a href="" className="cancel-btn">
                Back
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal.............. */}
      <Modal
        className="reset-password success-modal"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <p>
              An email has been sent to ub1894497@gmail.com with a link to reset
              your account.
              <br />
              <br />
              If you have not received the email after a few minutes, please
              check your spam folder.
              <br />
              <br />
              Need help? Contact <a href="">support@tdns.com</a>
            </p>
            <a href="" className="cancel-btn">
              Back
            </a>
          </div>
        </Modal.Body>
      </Modal>

      {/* change email modal.............. */}
      <Modal
        className="reset-password changeemail-modal"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="option-field">
              <label>New Email</label>
              <input type="text" placeholder="Email" />
            </div>
            <div className="option-field">
              <label>Confirm Email</label>
              <input type="text" placeholder="Confirm Email" />
            </div>
            <a className="next-btn" onClick={handleShow6}>
              Change Email Address
            </a>
            <a href="" className="cancel-btn">
              Back
            </a>
          </div>
        </Modal.Body>
      </Modal>

      {/* change email modal 2.............. */}
      <Modal
        className="reset-password changeemail-modal"
        show={show6}
        onHide={handleClose6}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="verify-text">
              <p className="verify">
                Please verify your current email address.
              </p>
              <h6>Confirm Your Email</h6>
              <p className="send">
                We will send a verification code to your email:
              </p>
              <span>xxxxxxxxxx@gmail.com</span>
            </div>
            <div className="bottom-item">
              <input type="text" placeholder="Enter Verification Code" />
              <a className="confirm-btn" onClick={handleShow7}>
                Confirm
              </a>
              <p className="confirm">
                Confirmation emails can take up to 10 minutes to arrive
              </p>
              <a className="gethelp mb-3">
                <img
                  src="\assets\get-help.svg"
                  alt="img"
                  className="img-fluid mr-2"
                />
                Get Help
              </a>
            </div>
            <a className="next-btn">Send Verification Code</a>
            <a href="" className="cancel-btn">
              Back
            </a>
          </div>
        </Modal.Body>
      </Modal>

      {/* change email modal 3.............. */}
      <Modal
        className="reset-password changeemail-modal"
        show={show7}
        onHide={handleClose7}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <div className="para-last">
              <p>
                Your email has been changed!
                <br />
                <br /> <br />
                Need help? Contact <a>support@tdns.com</a>
              </p>
            </div>
            <a href="" className="cancel-btn">
              Back
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Account;
