import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getPublicResolverContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import namehash from 'eth-ens-namehash'
const DomainMintingPrice = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const tokenAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(tokenAddress, web3);
    const tokenAddressResolver = environment.resolver;
    const contractResolver = getPublicResolverContract(tokenAddressResolver, web3);
    const DAYS = 24 * 60 * 60
    const REGISTRATION_TIME = 28 * DAYS

    const domainMintingPriceMethod = useCallback(
        async (label, typeOD) => {
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                let tldHash = namehash.hash(`${typeOD}`)
                const resTime = await contract.methods.rentPrice(label, REGISTRATION_TIME, tldHash).call();
                // console.log('resolverrrrrrrrrr', label ,REGISTRATION_TIME,tldHash)
                let mintP = resTime[0]
                mintP = web3.utils.fromWei(mintP, 'ether')
                // console.log('resolverrrrrrrrrr', mintP)
                return mintP
            } catch (error) {
                console.log('res of the delay', error)
                throw error
            }
        },
        [contract, account]
    );
    return { domainMintingPriceMethod: domainMintingPriceMethod };
};
export default DomainMintingPrice;

                // let nonceoof = await await contract.methods.nonceOf(tokenId).call();

                // let soliditySha3Expected = null;
                // soliditySha3Expected = soliditySha3(
                //     0x7c69eae2,
                //     environment.MintContract,
                //     nonceoof,
                // );
                // let signature = await web3.eth.personal.sign(soliditySha3Expected, account);
                //  signature = signature.substring(2)
                // let r = '0x' + signature.slice(0, 64);
                // let s = '0x' + signature.slice(64, 128);
                // let v = '0x' + signature.slice(128, 130);
                // // // console.log('sign====>', r, s, v, signature)