let Environment = {
  MarketContract: "0xCecC83d4327D080eE38046C014188d77b7663123",
  UNSRegistry: '0x1AF5Fa02DebD1B6951d7743ade6c9b5C4e538D6D',//to check if domain exsist or not except .crypto
  MintContract: '0xc569b414966623de2023E6E115E5F74860ee8c87', //minting manager
  ProxyReader: '0xBDc7F8A1ae66153Dd59A066d77d80b000AF83ad3',  //to check if domain exsist or not only crypto
  CNSRegistry: '0x4aB5c3B3331a6c9888E7a8b388c87ef7bfE5974D',
  approveContract: '0xC8cDEEB67d553466861E53a0f5A009bd756Ad140',
  MarketPlaceFORCNS: '0xE39bc82058eA452152Eb78eb4749180a4593388e',



  ethRegistrar: '0x56146633c6C9D3536eEBbf0bcB1D5F20A788B82a',
  ethRegistrarBulk: '0xf20901c12498Dc4b7828693C8519a61F162F3090',
  registrarAddress: '0x893D5e7FfE6a489186Fcd1EBF6Bc5c83a4c42505',
  resolver: '0x19C28CA76464dEd607402AC9C9Ef92F929100d0d',
  resolverNew: '0x89394523b56FAA95E2822CcF2469016795F1Da1E',
  myDomain: '0xBEa4b90a2C8a910b7920afF1cb36C67B4a34E6B1',
  registryAddress: '0x4F85c3d1A5B9655FDbFf53f24Da6DB9ABD61b481',
  wrapperAddress: '0xe2096c37364D48D9292C0cEF29Aa27D0653c1aF4',
  market: '0xCecC83d4327D080eE38046C014188d77b7663123',
  tomiToken: '0x4385328cc4D643Ca98DfEA734360C0F596C83449',
  oTomiToken: '0x4385328cc4D643Ca98DfEA734360C0F596C83449',
  usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  partnerNFT: '0xCa521b7ACCb512cf218fE19fedECC6D8399fCbbb',
  uniSwapRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  // https://api.thegraph.com/subgraphs/name/waleed-shafiq/dns-polygon-tlds-auction
  dnsGraph: "https://api.studio.thegraph.com/query/63987/tdns/version/latest",
  marketplaceGraph: 'https://api.studio.thegraph.com/query/63987/test-tdns-marketplace/version/latest'
  // https://api.studio.thegraph.com/query/48709/tdns-auction/version/latest
};

export default Environment;