import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify'
import DomainProfile from '../../hooks/dataSenders/websiteHash'
function Website({ UrlData, setLoader }) {
  const [copied, setCopied] = useState(false)
  const [suggestion, setSuggestion] = useState()
  const [cryptoData, setCryptoData] = useState({
    IpfsHash: '',
    sitetype: ''
  })
  console.log('wajeorrvejwt o5ertdv', cryptoData)
  let dis = false
  const ipTest = RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
  // // console.log('ytfvbj9uytrfvbnm', ipTest.test('192.168.0.250'))
  const { domainProfileFun } = DomainProfile()
  const profileDataFun = async (e) => {
    var { name, value } = e.target;
    let values = value.replace(/\s/g, "")
    setCryptoData((pre) => {
      return {
        ...pre, [name]: values
      }
    })
    if (parseInt(value) === 1) {
      setSuggestion({
        IpfsHash: 'Ipfs hash',
        sitetype: 'QmNp3YSYupKnxtp5xTrRx4...'
      })
    } else if (parseInt(value) === 2) {
      setSuggestion({
        IpfsHash: 'Ip Address',
        sitetype: '192.168.0.53'
      })
    } else if (parseInt(value) === 3) {
      setSuggestion({
        IpfsHash: 'Dns Domain',
        sitetype: 'www.google.com , www.tomi.com'
      })
    } else if (parseInt(value) === 4) {
      setSuggestion({
        IpfsHash: 'Onion',
        sitetype: '3g2upl4pq6kufc4m...'
      })
    }

  }

  function isValidIPFSHash(hash) {
    // This regex checks for the typical base58-encoded multihash format used by IPFS
    const pattern = /^(Qm[a-zA-Z0-9]{44})$/;
    return pattern.test(hash);
  }

  function isValidIPv4(ip) {
    const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  }

  function isValidOnionV3Domain(domain) {
    const regex = /^[a-z2-7]{56}(\.onion)?$/;
    return regex.test(domain);
  }

  function isValidOnionV2Domain(domain) {
    const regex = /^[a-z2-7]{16}(\.onion)?$/;
    return regex.test(domain);
  }

  // // console.log('ddff', cryptoData)
  const confirCryptoChanges = async (e) => {
    if (cryptoData.IpfsHash === '' || cryptoData.sitetype === '') {
      toast.error('Fill the fields first', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    } else if (!UrlData) {
      toast.error('TokenId is missing', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
    else if (UrlData) {
      let a = isValidIPFSHash(cryptoData.IpfsHash)
      let b = isValidIPv4(cryptoData.IpfsHash)
      console.log("b", b, a)
      // if(!a &&  suggestion?.IpfsHash =='Ipfs hash'){
      //   toast.info('Invalid Hash', {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   })
      //   return
      // }
      // else if(!b && suggestion?.IpfsHash =='Ip Address'){
      //   toast.info('Invalid Ip Address', {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   })
      //   return
      // }
      let jsonData = JSON.stringify(cryptoData.IpfsHash)
      try {
        setLoader(true)
        const res = await domainProfileFun(UrlData, cryptoData.IpfsHash, cryptoData.sitetype, false)
        toast.success('Mapping Successful', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        // // console.log('domainProfile contract data succ', res)
        setLoader(false)
      } catch (error) {
        // // console.log('domainProfile contract data err', error)
        toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        setLoader(false)
      }

    } else {
      // toast.error('dont know')
    }

  }
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  const getProfileData = async (e) => {

    if (UrlData) {

      try {
        setLoader(true)
        const res = await domainProfileFun(UrlData, '00', 0, true)
        // // console.log('res of the hassh', res)
        setLoader(false)
        let types;
        if (res?.codec == "ipfs-ns") {
          types = 1;

        } else if (res?.codec == "ip4") {
          types = 2;
        } else if (res?.codec == "dns") {
          types = 3;
        } else {
          types = 4;
        }
        // // console.log('res of the hassh', types)
        if (res?.codec == "onion") {
          setCryptoData({
            IpfsHash: res?.domainDecoded?.split('.')[0],
            sitetype: types
          })
        } else {
          setCryptoData({
            IpfsHash: res?.domainDecoded,
            sitetype: types
          })
        }


      } catch (error) {
        setLoader(false)
        // // console.log('domainProfile contract data err', error)
        // toast.error('Profile Data get Error')
      }

    } else {
      // toast.error('dont know')
    }

  }
  useEffect(() => {
    if (UrlData) {
      setCryptoData({
        IpfsHash: '',
        sitetype: ''
      })
      getProfileData()
    }

  }, [UrlData])
  return (
    <>
      <div className="website">
        <div className="top">
          <h5>Website Launched</h5>
        </div>
        <div className="option-field ">
          {/* <label>IPFS Hash</label> */}
          <div className='inputt_topper_div'>
          <div className="d-flex justify-content-between align-items-center w-100 myfeild">
          <svg className='arrowpink' xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87151 4.87874L3.50027 4.50751L2.75781 5.24997L3.12904 5.6212L6.62904 9.1212L7.00027 9.49243L7.37151 9.1212L7.70936 8.78334L6.56641 7.57364L3.87151 4.87874ZM7.32954 7.67825L8.62287 7.86984L10.8715 5.6212L11.2427 5.24997L10.5003 4.50751L10.129 4.87874L7.32954 7.67825Z" fill="#0E1120"/>
</svg>
   

            <select value={cryptoData.sitetype} onChange={profileDataFun} name='sitetype' class={cryptoData.sitetype ? "custom-select w-25  " : "ccl custom-select w-25 mb-3 "} id="inputGroupSelect02">
              <option selected>Select Type</option>
              <option value="1">IPFS Hash</option>
              <option value="2">Ip Address</option>
              <option value="3">ICANN Domain</option>
              <option value="4">Onion</option>
            </select>

            {/* <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic"  className='backdrop'>
      Select Type <svg cla xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <path d="M6.71094 10.7608L12.1801 16.23L17.6494 10.7608L16.2943 9.40569L12.1801 13.5198L8.06602 9.40569L6.71094 10.7608Z" fill="#FF0083" />
          </svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">IPFS Hash</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Ip Address</Dropdown.Item>
        <Dropdown.Item href="#/action-3">ICANN Domain</Dropdown.Item>
        <Dropdown.Item href="#/action-4">Onion</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

          </div>
          <div className='tooop_inpiut' style={{ position: "relative" }}>
            <input value={cryptoData.IpfsHash} name='IpfsHash' onChange={profileDataFun} type="text" placeholder={`Enter ${suggestion?.IpfsHash || 'value'}`} />
            {!copied ?
              <CopyToClipboard text={cryptoData?.IpfsHash}
                onCopy={() => { setCopied('Copied'); settime() }}>

                <svg className="img-fluid copy" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path d="M17.5903 13.946V18.3606C17.5903 22.0395 16.1188 23.511 12.44 23.511H8.02537C4.34653 23.511 2.875 22.0395 2.875 18.3606V13.946C2.875 10.2672 4.34653 8.79565 8.02537 8.79565H12.44C16.1188 8.79565 17.5903 10.2672 17.5903 13.946Z" stroke="#6E8491" stroke-width="1.57664" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M23.8989 7.63962V12.0542C23.8989 15.7331 22.4274 17.2046 18.7486 17.2046H17.5924V13.9462C17.5924 10.2674 16.1208 8.79583 12.442 8.79583H9.18359V7.63962C9.18359 3.96079 10.6551 2.48926 14.334 2.48926H18.7486C22.4274 2.48926 23.8989 3.96079 23.8989 7.63962Z" stroke="#6E8491" stroke-width="1.57664" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </CopyToClipboard>
              : <p className=" copy common">Copied</p>}
          </div>
          </div>
        
          {/* {(cryptoData.sitetype && cryptoData?.IpfsHash && parseInt(cryptoData.sitetype) === 3) && ((parseInt(cryptoData.sitetype) === 3 && (cryptoData?.IpfsHash?.split('.')[cryptoData?.IpfsHash?.split('.')?.length - 1])?.length < 5 && (cryptoData?.IpfsHash?.split('.')[cryptoData?.IpfsHash?.split('.')?.length - 1])?.length > 0) || (dis = true && <p className='text-danger font-weight-bold'>Invalid Dns Domain Link</p>))} */}
          {/* {(cryptoData.sitetype && cryptoData?.IpfsHash && parseInt(cryptoData.sitetype) === 2) && ((parseInt(cryptoData.sitetype) === 2 && ipTest.test(cryptoData?.IpfsHash)) || (dis = true && <p className='text-danger font-weight-bold'>Invalid Ip Address</p>))} */}

          {/* <img
            src="\assets\copy.svg"
            alt="img"
            className="img-fluid copy"
          /> */}
          {suggestion && <>
            <h5 className='mt-4 sugg'>Suggestion</h5>
            <p className='py-3 suggP'>Your <span className='font-weight-bold px-1'> {suggestion?.IpfsHash}</span> must be in this form eg: <span className='common px-1'>{suggestion?.sitetype}</span></p>
          </>}
        </div>

        <div className="bottom-btn">
          <button disabled={dis} className={dis && 'bg-secondary cursorNA'} onClick={confirCryptoChanges}>Confirm</button>
          <button onClick={() => setCryptoData({ IpfsHash: '', sitetype: '' })}>Remove</button>
        </div>
      </div>
      {/* <div className="spacetop">

      </div> */}
      {/* <div className="website webisteUpload">

        <div className="top">
          <h5>Uplaod Files to IPFS</h5>
          <p>*Limit 20MB per domain</p>
        </div>
        <div className="uploadfiles-div">
          <p>Drag ‘n’ drop files here</p>
          <p>or</p>
          <div className="row w-100">
            <div className="col-xl-4 col-lg-5 col-sm-7 mx-auto">
              <button className='d-flex w-100 align-items-center justify-content-center'><img src="\DProfile\send-square.svg" className='img-fluid pr-2' alt="" /> Upload Files</button>

            </div>
          </div>

        </div>
        <div className="bottom-btn">
          <button className='d-flex align-items-center justify-content-center'><img src="\DProfile\send-square.svg" className='img-fluid pr-2' alt="" /> Upload to IPFS</button>
          <button>Cancel</button>
        </div>
      </div> */}
    </>
  )
}

export default Website