import React, { useEffect, useState } from 'react'
function SellDomain() {
    
    return (
        <section className="fifth">
            <div className="headingfifth">
                {/* <h6>SELLING OPTIONS</h6> */}
            </div>
            <div className="bottom-content">
                {/* <div className="inner-card">
                              <div className="left">
                                <h6>List for Sale</h6>
                                <h5>
                                  {" "}
                                  <span>THE EASIEST OPTION </span>- List your
                                  domain for sale in minutes{" "}
                                </h5>
                                <a className="list-btn">List for Sale</a>
                              </div>
                              <div className="right">
                                <img
                                  src="\assets\profile\selling-img.png"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div> */}
                <div className="inner-card">
                    <div className="left">
                        <h6>Sell on Opensea</h6>
                        <h5>
                            {" "}
                            List your domain for sale on the largest
                            marketplace for NFTs
                        </h5>
                        <a href='https://opensea.io/collection/tomidomainnamesystem' className="opensea" target='_blank'>
                            List for Sale on Opensea
                        </a>
                    </div>
                    <div className="right">
                        <img
                            src="\assets\profile\opensea.png"
                            alt="img"
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="last-inline-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path d="M12.6125 23.8539C18.3935 23.8539 23.1235 19.124 23.1235 13.343C23.1235 7.56196 18.3935 2.83203 12.6125 2.83203C6.83149 2.83203 2.10156 7.56196 2.10156 13.343C2.10156 19.124 6.83149 23.8539 12.6125 23.8539Z" stroke="#0E1120" stroke-width="1.57664" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.6133 9.13867V14.3941" stroke="#0E1120" stroke-width="1.57664" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.6055 17.5474H12.6149" stroke="#0E1120" stroke-width="2.10219" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    <p>
                        You need to connect to Opensea the wallet where
                        this domain is stored with address: 0x0F4Dc5 to
                        list for sale this domain.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default SellDomain