import React, { useState } from "react";
import "./dsearchResult.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import Navbar from "../landing/header/Navbar";
import "./domainSearch.scss";
import { useParams, useHistory, Link } from "react-router-dom";
import environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import DomainMinting from "../../hooks/dataSenders/mintDomain";
import DomainMintingPrice from "../../hooks/dataSenders/mintPrice";
import { toast } from "react-toastify";
import Approve from "../../hooks/dataSenders/approveTomi";
import Countdown from "react-countdown";
import { Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import Loader from "../../hooks/loader";
import namehash from "eth-ens-namehash";
import Allowance from "../../hooks/dataFetchers/allowance";
import GetTomiPrice from "../../hooks/dataFetchers/tomiPriceGet";
import { useEffect } from "react";
import Environment from "../../utils/Environment";
import BalanceOf from "../../hooks/dataFetchers/balanceOf";
import ChildOfId from "../../hooks/dataFetchers/childIdOf";
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
const ProgressBarComp = () => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const { domainMintingMethod } = DomainMinting();
  const { domainMintingPriceMethod } = DomainMintingPrice();
  const { UserApprove } = Approve();
  const { checkAllowance } = Allowance();
  const { TomiPrice } = GetTomiPrice();
  let { account } = useWeb3React();
  const web3 = useWeb3();
  const [showSuccess, setSuccess] = useState(false);
  const [allowanceValue, setAllowanceValue] = useState(0);
  const [showError, setError] = useState(false);
  const [showWarning, setWarning] = useState(false);
  const [timerTime, setTimerTime] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  const [tPrice, setTPrice] = useState(50); // dummy value
  const [step, setStep] = useState(0);
  const [stepp, setStepp] = useState(0);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  let { type, inpuVal } = useParams();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const contractAddress = environment.tomiToken;
  const [balance, setBalance] = useState(true);
  const { balanceOfHook } = BalanceOf();
  const [avail, setAvail] = useState(true);
  const [copied, setCopied] = useState(false);
  const { Mainfunct } = ChildOfId();
  console.log("asldfalsdf", mintPrice);
  const allowanceofAccount = async () => {
    let res = await balanceOfHook(account);
    console.log("ressss", res, mintPrice);
    setBalance(res);

    if (parseFloat(res) <= parseFloat(mintPrice)) {
      handleShow2();
      setShow(false);
    } else {
    }
  };
  const [show12, setShow12] = useState(false);

  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);
  const settime = () => {
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };
  // console.log('jdfasdfl', balance)
  let date = Date.now();
  // // console.log('dfjaojfo', date)
  let coloredToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const getTotalStakers = async () => {
    try {
      setLoader(true);
      let result2 = await TomiPrice();
      setTPrice(result2?.toFixed(2));
      setLoader(false);
    } catch (error) {
      setTPrice(30);
      setLoader(false);
    }
  };
  const mintdom = async () => {
    try {
      try {
        setShow(false);
        let response;
        if (true) {
          setLoader2(true);
          try {
            let allowanceRes = await checkAllowance(account, Environment.ethRegistrar);
            allowanceRes = web3.utils.fromWei(allowanceRes.toString(), "ether");
            console.log("asldfalsdf", mintPrice, allowanceRes);
            if (parseFloat(allowanceRes) >= parseFloat(mintPrice)) {
              response = true;
              setLoader2(false);
            } else {
              response = await UserApprove();
              setLoader2(false);
              toast.success("Approval successful", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          } catch (error) {
            setLoader2(false);
            toast.error(
              error?.message?.split('message":"')[1]?.split("}")[0]?.split(`"`)[0] ||
              error?.message?.split(":")[1],
              coloredToast
            );
          }
        }
        if (response) {
          setStepp(5);
          setLoader(true);
          try {
            const res = await domainMintingMethod(inpuVal, true, type);
            if (res) {
              setStepp(35);
              setStep(50);
              setTimerTime(Date.now());
              localStorage.setItem("dName", inpuVal + type);
              localStorage.setItem("aToken", account);
              localStorage.setItem("nowTime", Date.now());
              await new Promise((resolve) => {
                setTimeout(resolve, 30000);
              });
              setTimerTime(0);
              setLoader(false);
              setStep(99);
            }
            setLoader(false);
          } catch (error) {
            // console.log('asldfalsdf', error)
            toast.error(
              error?.message?.split('message":"')[1]?.split("}")[0]?.split(`"`)[0] ||
              error?.message?.split(":")[1],
              coloredToast
            );
            // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
            setLoader(false);
          }
        } else {
          return;
        }
      } catch (error) {
        toast.error("Minting Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
        setLoader2(false);
        setStepp(0);
        setLoader(false);
        setError(true);
        setStep(0);
      }
    } catch (error) {
      setStepp(0);
      setLoader(false);
      setError(true);
      setStep(0);
    }
  };
  const mintdomFinal = async () => {
    const res = await Mainfunct(inpuVal, type?.toLocaleLowerCase());
    setAvail(res);
    if (res) {
      try {
        setStepp(71);
        setStep(99);
        setLoader(true);
        const res = await domainMintingMethod(inpuVal, false, type);
        // setWarning(true)
        setLoader(false);
        setStep(100);

        let data = res;
        data = data.events.NameRegistered.returnValues.name;
        const name = namehash.hash(data[0] + "." + data[1]);
        let nameHash = web3.utils.toBN(name).toString();
        localStorage.setItem("mintedTokenId", nameHash);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "USD",
            value: "100",
            transaction_id: nameHash,
            items: {
              item_name: inpuVal,
              item_id: nameHash,
              price: "$100",
              quantity: 1,
            },
          },
        });
        window.$("#acceptOkay").modal("show");
      } catch (error) {
        setStepp(35);
        setLoader(false);
        setStep(99);
        // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
        if (error?.message?.includes("User denied transaction")) {
          toast.error("User Denied Trasaction", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.info("Please Try Again", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setLoader(false);
      }
    } else {
      toast.error("Domain Is Already Minted By Some Other User", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const allowance = async () => {
    try {
      let details = await checkAllowance(account, Environment.ethRegistrar);
      details = web3.utils.fromWei(details.toString(), "ether");
      setAllowanceValue(details);
    } catch (error) { }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setTimerTime(0);
      setLoader(false);
      setStep(99);
      return null;
    } else {
      // Render a countdown
      setStepp(35);
      setStep(50);
      return (
        <span>
          {minutes}:{seconds < 10 && 0}
          {seconds}
        </span>
      );
    }
  };
  useEffect(() => {
    if (account) {
      allowance();
      window.$("#exampleModalwallet").modal("hide");
    } else {
      window.$("#exampleModalwallet").modal(show);
    }
    let DomainName = localStorage.getItem("dName");
    let aToken = localStorage.getItem("aToken");
    if (DomainName === inpuVal + type && account === aToken) {
      let stTime = localStorage.getItem("nowTime");
      setTimerTime(parseFloat(stTime));
    }
    // getTotalStakers()
  }, [account]);
  const OKay = () => {
    let MintedToken = localStorage.getItem("mintedTokenId");
    window.$("#acceptOkay").modal("hide");
    window.location.assign(`/place/${MintedToken}:mintedDomain`);
  };
  const mintPriceFun = async () => {
    const res = await domainMintingPriceMethod(inpuVal, type);
    console.log("dlfjalsfjl", res);
    setMintPrice(res);
  };
  console.log(inpuVal, type, account, "inpuVal, type");
  useEffect(() => {
    if (account) {
      if ((inpuVal, type)) {
        mintPriceFun();
      }
    }
  }, [account]);
  useEffect(() => {
    if (mintPrice) {
      allowanceofAccount();
    }
  }, [mintPrice]);

  const [buytomi, setBuytomi] = useState(false);
  const handleClosebuytomi = () => setBuytomi(false);
  const handleShowbuytomi = () => setBuytomi(true);

  const [routes, setRoutes] = useState(false);
  useEffect(() => {
    localStorage.setItem("path", "location");
  }, []);
  var MintedToken = localStorage.getItem("mintedTokenId");
  return (
    <>
      {loader2 && <Loader />}
      <Navbar setTab={""} setTab2={"common"} setTab3={""} />
      {/* <button onClick={() => window.$('#acceptOkay').modal('show')}>sind</button> */}
      <section className="domain-search progress-sec">
        <img src="\assets\shadows.png" className="myyyelipsenew img-fluid" />
        <div className="container-fluid">
          <div className="progress_mains">
            <Link to="/domainSearhResult/0/sdax">
              <button>
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5285 11.4696L11.0588 11.9999L9.99816 13.0605L9.46783 12.5302L5.46783 8.53022L4.9375 7.99989L5.46783 7.46956L5.96367 6.97372L7.21392 8.15499L10.5285 11.4696ZM7.19787 7.86084L6.92417 6.01322L9.46783 3.46956L9.99816 2.93923L11.0588 3.99989L10.5285 4.53022L7.19787 7.86084Z" fill="#0E1120"/>
</svg>
                Back
              </button>
            </Link>

            <div className="top_tab_data">
              <div className="availbilty">
                <h5></h5>
                <p onClick={handleShow12}>Request to Register</p>
              </div>
              <div className="availbilty">
                <h5></h5>
                <p>
                  Complete Registration<span>00:00</span>{" "}
                </p>
              </div>
              <div className="availbilty">
                <h5></h5>
                <p>Checking Availibility</p>
              </div>
            </div>
          </div>
          {/* <div className="row"> */}
          {/* <div className="col-xl-9 col-12 m-auto"> */}
          {/* <div className="col-xl-8 col-12 m-auto"> */}
          {/* <div className="progresss totoParent mt-4">
                <ProgressBar className="toto" now={step} />
                <div className="progress-barr">
                  <div className="item-bar">
                    <div className="left">
                      {parseInt(step) >= 30 ? (
                        <img src="\assets\success.svg" alt="img" className="img-fluid" />
                      ) : (
                        <img
                          src={
                            parseInt(stepp) === 5
                              ? "/assets/counterColored.svg"
                              : "/assets/countergrey.svg"
                          }
                          alt="img"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-bar">
                    <div className="center">
                      {parseInt(step) >= 70 ? (
                        <img src="\assets\success.svg" alt="img" className="img-fluid" />
                      ) : (
                        <img
                          src={
                            parseInt(stepp) === 35
                              ? "/assets/counterColored.svg"
                              : "/assets/countergrey.svg"
                          }
                          alt="img"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-bar">
                    <div className="right">
                      {parseInt(step) >= 100 ? (
                        <img src="\assets\success.svg" alt="img" className="img-fluid" />
                      ) : (
                        <img
                          src={
                            parseInt(stepp) === 71
                              ? "/assets/counterColored.svg"
                              : "/assets/countergrey.svg"
                          }
                          alt="img"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
          {/* <div className="progress-barr paramsProgres">
                <div className="item-bar">
                  <div className="left">
                    <h5>
                      Request to <br />
                      register
                    </h5>
                  </div>
                </div>
                <div className="item-bar">
                  <div className="center">
                    <h5>
                      Checking <br />
                      Availability <br />
                      <span className="timor">
                        {" "}
                        {timerTime > 0 ? (
                          <Countdown date={timerTime + parseInt(30000)} renderer={renderer} />
                        ) : (
                          "00:00"
                        )}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="item-bar">
                  <div className="right">
                    <h5>
                      Complete <br />
                      Registration{" "}
                    </h5>
                  </div>
                </div>
              </div> */}
          {/* </div> */}
          <div className="top_avail_dataa">
            <div className="availbllle_data">
              <div className="availableDomain new_domainsss_cvards domaincardlarge mb-5">
              <svg className="svgtomiilogo" xmlns="http://www.w3.org/2000/svg" width="67" height="66" viewBox="0 0 67 66" fill="none">
  <path d="M33.4079 0.15625C15.39 0.15625 0.785156 14.7621 0.785156 32.7812C0.785156 40.3556 3.36614 47.3292 7.69597 52.8654C8.06087 53.3326 8.72391 53.435 9.22676 53.119L16.4669 48.5664C16.5425 48.5174 16.6093 48.464 16.6716 48.4017L27.0845 41.8776V63.8175C27.0845 64.3827 27.485 64.8767 28.0457 64.9657C29.7901 65.255 31.579 65.4062 33.4079 65.4062C35.2369 65.4062 37.1682 65.246 38.9793 64.9301C39.5355 64.8322 39.9316 64.3471 39.9316 63.7819V42.0022L50.2733 48.4818C50.3045 48.5041 50.3312 48.5308 50.3623 48.553L57.6158 53.0834C58.1186 53.3994 58.7772 53.2926 59.1421 52.8253C61.1713 50.2264 62.8134 47.3114 63.9837 44.1695C64.1751 43.6533 63.9704 43.0792 63.5031 42.7855L62.0346 41.8643C61.9901 41.8242 61.9412 41.7842 61.8878 41.7486L34.1511 24.3702C34.1021 24.3391 34.0487 24.3168 33.9998 24.2946C33.6304 24.0765 33.1587 24.0632 32.7671 24.3079L12.1059 37.2538C11.7899 35.7807 11.6208 34.2543 11.6208 32.6878C11.6208 20.6542 21.3752 10.8992 33.4079 10.8992C44.3504 10.8992 53.4061 18.9631 54.9592 29.4747C55.0126 29.8307 55.2128 30.1467 55.5154 30.338L64.2107 35.7852C64.9449 36.248 65.9106 35.7852 65.964 34.9174C66.0085 34.2098 66.0352 33.4933 66.0352 32.7768C66.0352 14.7621 51.4259 0.15625 33.4079 0.15625Z" fill="#FF0083"/>
</svg>
                {/* <div className="row"> */}
                {/* <div className="col-sm-6 my-auto"> */}
                <div className="d-flex domainMain mb-0">
                  <p>
                    <span>{inpuVal}</span>.{type}
                  </p>
                  <div className="prize_div">
                    <h2>Highest Bid</h2>
                    <div className="innner_prize">
                      <h3>2797.33 </h3>
               
                    </div>
                  </div>

                  {/* {avail ? (
                        <div className="d-flex availtick">
                          <img src="\searchResults\whitetick.svg" alt="" />
                          <p>{parseInt(step) >= 100 ? "Minted Successfully" : "Available"} </p>
                        </div>
                      ) : (
                        <div className="d-flex availtick bg-danger">
                          <img src="\searchResults\whitetick.svg" alt="" />
                          <p>Unavailable</p>
                        </div>
                      )} */}
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}

            {/* <div className="col-xl-9 col-12 m-auto"> */}
            {parseFloat(balance) < parseFloat(mintPrice) ? (
              <div className="btn-bar text-center">
                <button onClick={handleShow2}>Request To Register</button>
              </div>
            ) : (
              <div className="btn-bar text-center">
                {parseInt(step) >= 100 ? null : loader ? (
                  <h4 className="d-flex align-items-center justify-content-end pending">
                    TX PENDING{" "}
                    <img className="img-fluid rotate ml-2" src="\assets\rotate-left.svg" alt="" />
                  </h4>
                ) : parseInt(step) >= 70 ? (
                  <button onClick={mintdomFinal}>Complete Registration</button>
                ) : (
                  <>
                    {/*  */}
                    {parseFloat(allowanceValue) < 70 ? (
                      <button
                        onClick={() => {
                          account ? setShow(true) : window.$("#exampleModalwallet").modal(show);
                        }}
                      >
                        Request To Register
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          account ? mintdom() : window.$("#exampleModalwallet").modal(show);
                        }}
                      >
                        Request To Register
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {/* </div> */}
          </div>



        </div>
        {/* </div> */}
      </section>
      {/* success toast here...................... */}
      <Row>
        <ToastContainer position="top-end" className="p-3 toast-success">
          <Toast onClose={() => setSuccess(false)} show={showSuccess} delay={3000} autohide>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\check.svg" alt="img" className="img-fluid" />
                </div>
                <div className="right">
                  <h6>Successs</h6>
                  <p>Registration Successful</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      {/* error toast here...................... */}
      <Row>
        <ToastContainer position="top-end" className="p-3 toast-error">
          <Toast onClose={() => setError(false)} show={showError} delay={3000} autohide>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\cancel.svg" alt="img" className="img-fluid" />
                </div>
                <div className="right">
                  <h6>Error</h6>
                  <p>Registration Failed</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      {/* warning toast here...................... */}
      <Row>
        <ToastContainer position="top-end" className="p-3 toast-info" autohide>
          <Toast onClose={() => setWarning(false)} show={showWarning} delay={10000} autohide>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\warning.svg" alt="img" className="img-fluid" />
                </div>
                <div className="right">
                  <h6>Info</h6>
                  <p>Registered Domain will take time to show</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      <section className="modal-mint1">
        <Modal show={show} onHide={handleClose} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Minting Fee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content">
              <div className="text">
                <h6>Price</h6>
                <h5>
                  {/* <img src="\assets\eth-modal.svg" alt="img" className="img-fluid" /> */}
                  {mintPrice ? parseFloat(mintPrice)?.toFixed(2) : 0} Tomi
                </h5>
              </div>
            </div>
            <div className="">
              <a
                className="register-btn"
                style={{ width: "200px", margin: "10px auto" }}
                onClick={mintdom}
              >
                Approve
              </a>
              <a className="modal-cancel-btn" onClick={() => setShow(false)}>
                Cancel
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <section className="placebid2-modal modal-dialog-centered csghghscghcsgcsvsgcv">
        <div
          data-backdrop="static"
          data-keyboard="false"
          class="modal fade"
          id="acceptOkay"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body modal-body1  mt-3">
                <img src="\assets\success2.svg" alt="img" className="img-fluid" />
                <p className="csgvtvscttysccstysc">
                  Your Domain has been <br /> minted successfully
                </p>
                <p className=" font-size-sm scsvscscttsftysdtyd">
                  {" "}
                  And placed on the marketplace for auction until 20th June, 2024
                </p>
                <div className="bottom-links-set-new">
                  <h6>Share with friends</h6>
                  <div className="inner-links">
                    {/* {`Check out my domain ${inpuVal}.${type} on tdns.network`} */}
                    <CopyToClipboard
                      text={`https://tdns.network/place/${MintedToken}`}
                      onCopy={() => {
                        setCopied("Copied");
                        settime();
                      }}
                    >
                      <a href="#">
                        {" "}
                        <img
                          data-toggle="tooltip"
                          data-placement="top"
                          title={!copied ? "Copy" : "Copied"}
                          src="\assets\link-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </CopyToClipboard>
                    <WhatsappShareButton
                      url={`https://tdns.network/place/${MintedToken}`}
                      title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}
                    >
                      <img
                        data-toggle="tooltip"
                        data-placement="top"
                        title={""}
                        src="\assets\whatsapp-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url={`https://tdns.network/place/${MintedToken}`}
                      title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}
                    >
                      <img src="\assets\twitter-new-icon.svg" alt="img" className="img-fluid" />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={`https://tdns.network/place/${MintedToken}`}
                      title={`I just minted a new domain through tDNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @tomipioneers new internet!`}
                    >
                      <img src="\assets\telegram-new-icon.svg" alt="img" className="img-fluid" />
                    </TelegramShareButton>
                    {/* <a href="#"><img src="\assets\insta.svg" alt="img" className="img-fluid" /></a> */}
                  </div>
                </div>
                <button onClick={OKay} className="mt-0">
                  Go Checkout My Domain
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal className='oops-modal' show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton onClick={handleClose2}>
          <Modal.Title>Oops…</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-content">
            <div className="uper-img">
              <img src="\assets\newlanding-assets\oops-mint.svg" alt="img" className='img-fluid' />
            </div>
            <div className="bottom-content">
              <p className='main-para'>It looks like you don’t have TOMI tokens in your wallet. You will need TOMI tokens to mint tDNS domains.</p>
              <div className="inner-exchanges">
                <h6>Buy on major exchanges</h6>
                <div className="exchange-names">
                  <a href="https://www.huobi.com/en-us/trade/tomi_usdt" target='_blank'><img src="\assets\newlanding-assets\huobi.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.gate.io/trade/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\gateio.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.mexc.com/exchange/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\mexc.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://crypto.com/exchange/trade/TOMI_USD" target='_blank'><img src="\assets\newlanding-assets\bitmart.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.bybit.com/en-US/trade/spot/TOMI/USDT" target='_blank'><img src="\assets\newlanding-assets\bybit.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.bitget.com/spot/TOMIUSDT?type=spot" target='_blank'><img src="\assets\newlanding-assets\bitget.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://phemex.com/spot/trade/TOMIUSDT" target='_blank'><img src="\assets\newlanding-assets\heli.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.kucoin.com/trade/TOMI-USDT" target='_blank'><img src="\assets\newlanding-assets\kucoin.svg" alt="img" className='img-fluid' /></a>
                </div>
                <img src="\assets\newlanding-assets\or-img.svg" alt="img" className='img-fluid para-or' />
                <div className="twice-btns">
                  <a href="https://app.uniswap.org/" target='_blank'>    <button className='uni-swap'> <img src="\assets\newlanding-assets\uniswap.svg" alt="img" className='img-fluid mr-2' /> Buy on Uniswap</button></a>
                  <a href="https://changelly.com/" target='_blank'>    <button className='btn-chan'> <img src="\assets\newlanding-assets\changely.svg" alt="img" className='img-fluid mr-2' /> Buy on Changelly</button></a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* new modal here.............................. */}
      {/* <button onClick={handleShowbuytomi}>handleShowbuytomi</button> */}
      <Modal className="oops-modal" show={show2} onHide={handleClose2} centered>
        {/* <Modal.Header closeButton> */}
        {/* <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRoutes(!routes);
            }}
            src="\assets\newlanding-assets\back-btn.svg"
            alt="img"
            className={routes ? "img-fluid back-btn" : "d-none"}
          /> */}
        <Modal.Title>
          <h1 className="buyyytomi"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5285 17.4697L16.0588 18L14.9982 19.0607L14.4678 18.5303L8.46783 12.5303L7.9375 12L8.46783 11.4697L9.2196 10.7179L10.3104 11.7485L10.0588 12L15.5285 17.4697ZM10.7302 11.3286L10.4565 9.48101L14.4678 5.46968L14.9982 4.93935L16.0588 6.00001L15.5285 6.53034L10.7302 11.3286Z" fill="#0E1120" />
          </svg>Buy tomi <svg onClick={handleClose2} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19.281 18.2194C19.3507 18.289 19.406 18.3718 19.4437 18.4628C19.4814 18.5539 19.5008 18.6514 19.5008 18.75C19.5008 18.8485 19.4814 18.9461 19.4437 19.0372C19.406 19.1282 19.3507 19.2109 19.281 19.2806C19.2114 19.3503 19.1286 19.4056 19.0376 19.4433C18.9465 19.481 18.849 19.5004 18.7504 19.5004C18.6519 19.5004 18.5543 19.481 18.4632 19.4433C18.3722 19.4056 18.2895 19.3503 18.2198 19.2806L12.0004 13.0603L5.78104 19.2806C5.64031 19.4213 5.44944 19.5004 5.25042 19.5004C5.05139 19.5004 4.86052 19.4213 4.71979 19.2806C4.57906 19.1399 4.5 18.949 4.5 18.75C4.5 18.551 4.57906 18.3601 4.71979 18.2194L10.9401 12L4.71979 5.78061C4.57906 5.63988 4.5 5.44901 4.5 5.24999C4.5 5.05097 4.57906 4.8601 4.71979 4.71936C4.86052 4.57863 5.05139 4.49957 5.25042 4.49957C5.44944 4.49957 5.64031 4.57863 5.78104 4.71936L12.0004 10.9397L18.2198 4.71936C18.3605 4.57863 18.5514 4.49957 18.7504 4.49957C18.9494 4.49957 19.1403 4.57863 19.281 4.71936C19.4218 4.8601 19.5008 5.05097 19.5008 5.24999C19.5008 5.44901 19.4218 5.63988 19.281 5.78061L13.0607 12L19.281 18.2194Z" fill="#0E1120" />
            </svg></h1>
        </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          {!routes ? (
            <div className={!routes ? "buytomi-modaldiv slide-left" : "buytomi-modaldiv"}>
              <div className="upper-img">
                <img src="\assets\tomiwalllet.png" alt="img" className="img-fluid" />
              </div>
              <p className="para">
                It appears that you don’t have any TOMI tokens in your wallet. In order to mint tDNS
                domains, you will require TOMI tokens
              </p>
              <button onClick={() => setRoutes(!routes)} className="buytomi-btn">
                Buy TOMI
              </button>
            </div>
          ) : (
            <>
              <div className={routes ? "another-div slide-right" : "another-div"}>
                <div className="parent-items">
                  <a href="https://www.kucoin.com/trade/TOMI-USDT" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\kucoin.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>KuCoin</h6>
                    </div>
                  </a>
                  <a href="https://www.bybit.com/en-US/trade/spot/TOMI/USDT" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\bybit.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>ByBit</h6>
                    </div>
                  </a>
                  <a href="https://www.huobi.com/en-us/trade/tomi_usdt" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\huobi.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Huobi</h6>
                    </div>
                  </a>
                  <a href="https://www.gate.io/trade/TOMI_USDT" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\gateio.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Gate.io</h6>
                    </div>
                  </a>
                  <a href="https://www.mexc.com/exchange/TOMI_USDT" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\mexc.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Mexc</h6>
                    </div>
                  </a>
                  <a href="https://crypto.com/exchange/trade/TOMI_USD" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\crypto.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Crypto.com</h6>
                    </div>
                  </a>
                  <a href="https://www.bitget.com/spot/TOMIUSDT?type=spot" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\bitget.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Bitget</h6>
                    </div>
                  </a>
                  <a href="https://phemex.com/spot/trade/TOMIUSDT" target="_blank">
                    <div className="inner-item">
                      <img
                        src="\assets\newlanding-assets\exchangesicons\phemex.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Phemex</h6>
                    </div>
                  </a>
                </div>
                <img
                  src="\assets\newlanding-assets\or-img.svg"
                  alt="img"
                  className="img-fluid linesimggg"
                />
                <div className="twice-btns">
                  <a
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x4385328cc4d643ca98dfea734360c0f596c83449"
                    target="_blank"
                  >
                    {" "}
                    <button className="uni-swap">
                      {" "}
                      <img
                        src="\assets\newlanding-assets\uniswap.svg"
                        alt="img"
                        className="img-fluid mr-2"
                      />{" "}
                      Buy on Uniswap
                    </button>
                  </a>
                  <a href="https://changelly.com/" target="_blank">
                    {" "}
                    <button className="btn-chan">
                      {" "}
                      <img
                        src="\assets\newlanding-assets\changely.svg"
                        alt="img"
                        className="img-fluid mr-2"
                      />{" "}
                      Buy on Changelly
                    </button>
                  </a>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal className="oops-modal exchangemodal" show={show12} onHide={handleClose12} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Share</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_dataaa">
            <div className="inner__modals">
              <h1>Itay.com</h1>
            </div>
            <div className="share_div">
              <h3>Share with friends</h3>
              <div className="imggg_div">
                <img src="\assets\x.svg" />
                <img src="\assets\acord.svg" />
                <img src="\assets\tele.svg" />
                <img src="\assets\cir.svg" />
                <img src="\assets\video.svg" />
              </div>
            </div>
            <h6>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <g clip-path="url(#clip0_17_2971)">
                  <path
                    d="M9.76119 6.69716C9.57166 6.5065 9.36151 6.3375 9.13464 6.19325C8.53375 5.80847 7.83519 5.60401 7.12166 5.60401C6.13131 5.60217 5.1812 5.99566 4.48213 6.69716L1.09074 10.0912C0.392868 10.7904 0.000625661 11.7378 7.72806e-07 12.7257C-0.00131149 14.7872 1.66876 16.4594 3.73027 16.4607C4.71909 16.4642 5.66833 16.0725 6.36714 15.3729L9.16664 12.5734C9.2175 12.523 9.2459 12.4542 9.2455 12.3826C9.24465 12.2353 9.12458 12.1167 8.97733 12.1175H8.87069C8.28579 12.1196 7.70612 12.0072 7.16434 11.7869C7.06451 11.7458 6.94978 11.769 6.87373 11.8455L4.86075 13.8612C4.23568 14.4863 3.22223 14.4863 2.59716 13.8612C1.97208 13.2361 1.97208 12.2227 2.59716 11.5976L6.00189 8.19554C6.62647 7.57175 7.63825 7.57175 8.26283 8.19554C8.68375 8.59169 9.34032 8.59169 9.76122 8.19554C9.94231 8.0143 10.0521 7.7739 10.0705 7.51832C10.09 7.21291 9.97733 6.91381 9.76119 6.69716Z"
                    fill="#9DA4AC"
                  />
                  <path
                    d="M14.9065 1.55378C13.4487 0.0960069 11.0852 0.0960069 9.62744 1.55378L6.8306 4.34793C6.7538 4.4251 6.73165 4.54118 6.77461 4.64122C6.81707 4.74155 6.9163 4.80594 7.02522 4.80385H7.12386C7.70807 4.80279 8.28681 4.91599 8.82755 5.13713C8.92738 5.17822 9.04211 5.15504 9.11816 5.07849L11.1258 3.0735C11.7509 2.44843 12.7643 2.44843 13.3894 3.0735C14.0145 3.69858 14.0145 4.71202 13.3894 5.3371L10.8885 7.8353L10.8672 7.8593L9.99266 8.72849C9.36808 9.35228 8.35629 9.35228 7.73172 8.72849C7.3108 8.33234 6.65423 8.33234 6.23333 8.72849C6.05108 8.91111 5.9412 9.1536 5.92404 9.41102C5.90455 9.71644 6.01718 10.0155 6.23333 10.2322C6.54193 10.5421 6.90299 10.7949 7.2998 10.9787C7.35579 11.0054 7.41178 11.0267 7.46777 11.0507C7.52376 11.0747 7.5824 11.0934 7.63839 11.1147C7.69438 11.1361 7.75303 11.1547 7.80902 11.1707L7.96633 11.2134C8.07297 11.24 8.17964 11.2613 8.28893 11.28C8.4206 11.2996 8.5532 11.312 8.6862 11.3173H8.87283H8.88882L9.0488 11.2987C9.10744 11.296 9.16877 11.2827 9.23811 11.2827H9.32874L9.51271 11.256L9.59804 11.24L9.75267 11.208H9.78201C10.4369 11.0435 11.0349 10.7044 11.5124 10.2269L14.9064 6.83283C16.3643 5.37506 16.3643 3.01155 14.9065 1.55378Z"
                    fill="#9DA4AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_2971">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.460449)" />
                  </clipPath>
                </defs>
              </svg>
              https://domains.tomi.com/place/6752198...{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M12 10.1354V13.2854C12 15.9104 10.95 16.9604 8.325 16.9604H5.175C2.55 16.9604 1.5 15.9104 1.5 13.2854V10.1354C1.5 7.51045 2.55 6.46045 5.175 6.46045H8.325C10.95 6.46045 12 7.51045 12 10.1354Z"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 5.63545V8.78545C16.5 11.4104 15.45 12.4604 12.825 12.4604H12V10.1354C12 7.51045 10.95 6.46045 8.325 6.46045H6V5.63545C6 3.01045 7.05 1.96045 9.675 1.96045H12.825C15.45 1.96045 16.5 3.01045 16.5 5.63545Z"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </h6>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProgressBarComp;
